import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import useAuthentication from '../helpers/authentication';

import Failure from '../assets/payment-assets/failure.json';

import { Header } from '../components';

const Container = styled.div`
  padding: 16px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const LottieContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
`;

const FailureText = styled.h3`
  font-size: ${(props) => props.theme.devices.sm.h3};
  width: 250px;
  text-align: center;
  font-weight: 400;
  margin: 0px auto 0px auto;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h3};
    line-height: 22px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h3};
    line-height: 35px;
  }
`;

const ButtonContainer = styled.button`
  background-color: rgb(255, 107, 107);
  border: none;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  width: 100px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.h4`
  margin: 0px;
  font-size: ${(props) => props.theme.devices.sm.sm};
  font-weight: 400;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.sm};
    line-height: 1.375rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.sm};
    line-height: 2.18rem;
  }
`;

export default function PaymentFailure() {
  const { getPaymentCal } = useAuthentication();
  const navigate = useNavigate();

  // AFTER PAYMENT FAILED CALCULATION API

  function getPaymentCalculations(id, campaignId) {
    getPaymentCal(`${id}/?campaign_id=${campaignId}`).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        navigate('/subscriptions', {
          state: {
            budgetValue: data,
            videoId: id,
            campaignId: campaignId,
          },
        });
      } else {
        console.log(message);
      }
    });
  }

  return (
    <>
      <Header />
      <Container>
        <LottieContainer>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Failure,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={250}
            width={250}
          />
          <FailureText>Payment Failed!</FailureText>
          <ButtonContainer
            onClick={() => {
              getPaymentCalculations(
                parseInt(localStorage.getItem('videoId')),
                parseInt(localStorage.getItem('campaignID')),
              );
            }}
          >
            <ButtonText>Try Again</ButtonText>
          </ButtonContainer>
        </LottieContainer>
      </Container>
    </>
  );
}
