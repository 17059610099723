import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path
        fill="#e40606"
        fillRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zM6.839 3.545H9.16l-.212 6.56H7.05l-.212-6.56zm2.363 8.389c-.009.664-.567 1.2-1.2 1.2-.665 0-1.21-.536-1.2-1.2-.01-.656.535-1.187 1.2-1.187.633 0 1.191.531 1.2 1.187z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
