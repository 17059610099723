export const assetsImages = {
  Slide1: require('../images/slide-1.png'),
  Slide2: require('../images/slide-2.png'),
  Slide3: require('../images/slide-3.png'),
  Slide4: require('../images/slide-4.png'),
  Slide5: require('../images/slide-5.png'),
  Slide6: require('../images/slide-6.png'),
  Slide7: require('../images/slide-7.png'),
  MapImage: require('../images/map.png'),
  WatchImage: require('../images/watch.png'),
  WatchImageDsk: require('../images/watch-dsk.png'),
  QRImage: require('../images/qr-code.png'),
  CopyRight: require('../images/copyright.png'),
  IconCredit1: require('../images/visa.png'),
  IconCredit2: require('../images/mastercard.png'),
  IconCredit3: require('../images/american-express.png'),
  IconPayPal1: require('../images/paypal.png'),
  Logo: require('../images/logo.png'),
  Background: require('../images/dummy.png'),
  LoaderImage: require('../images/loader.png'),
  NoThumbnail: require('../images/no-thumbnail.jpg'),
};
