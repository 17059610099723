import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700">
      <path
        fill="#fff"
        d="M350 46.668a233.334 233.334 0 00-164.99 68.34 233.336 233.336 0 000 329.98 233.336 233.336 0 00329.98 0 233.336 233.336 0 0068.34-164.99 233.381 233.381 0 00-31.258-116.67 233.384 233.384 0 00-85.406-85.406 233.37 233.37 0 00-116.67-31.258zm0 420a186.647 186.647 0 01-131.99-54.676 186.654 186.654 0 010-263.98 186.654 186.654 0 01263.98 0 186.654 186.654 0 0129.664 225.322 186.625 186.625 0 01-68.324 68.324 186.625 186.625 0 01-93.332 25.012z"
      ></path>
      <path
        fill="#fff"
        d="M350 140a23.328 23.328 0 00-23.332 23.332v107.1l-63.234 63a23.34 23.34 0 00-8.14 16.82 23.343 23.343 0 006.82 17.402 23.326 23.326 0 0034.218-1.324l70-70a23.313 23.313 0 007-16.332v-116.67a23.328 23.328 0 00-6.832-16.496 23.323 23.323 0 00-16.5-6.836z"
      ></path>
    </svg>
  );
}

export default Icon;
