import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 10">
      <path
        fill="#9B26A6"
        fillRule="evenodd"
        d="M8.54.81a.544.544 0 00-.77 0L4.35 4.23.93.81a.545.545 0 00-.77.77L3.58 5 .16 8.42a.544.544 0 10.77.77l3.42-3.42 3.42 3.42a.545.545 0 00.77-.77L5.12 5l3.42-3.42a.545.545 0 000-.77z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
