import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import useAuthentication from '../helpers/authentication';

import { Button, Input } from '../components';
import { toastMessage } from '../components/toast';
import { iconPath } from '../assets/icons';

const Container = styled.div`
  overflow: auto;
  display: flex;
  align-items: end;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};
  @media ${(props) => props.theme.devices.md} {
    align-items: center;
    height: 100vh;
  }
`;

const ContainerBox = styled.div`
  background: ${(props) => props.theme.colors.light};
  max-width: 539px;
  width: 100%;
  margin: 0 auto;
  padding: 200px 20px;
  //height: calc(100vh - 350px);
  position: relative;
  @media ${(props) => props.theme.devices.md} {
    padding: 70px 50px;
    border-radius: 25px;
    height: auto;
    border: 1px solid #d9dde3;
  }
`;

const LogoContainer = styled.div`
  width: 171px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -50px;
  z-index: 9;
  background: #fff;
  box-shadow: 0px 5.56213px 77.8698px rgba(197, 197, 197, 0.5);
  border-radius: 15px;
  padding: 20px 25px;
  /* @media ${(props) => props.theme.devices.md} {
    width: 220px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 280px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 330px;
  } */
`;

const TitleContainer = styled.div`
  //margin: 64px 0;
  text-align: center;
`;

const Title = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sm.h3};
  font-weight: 500;
`;

const Heading = styled.p`
  margin: 8px 0;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.lightGray};
`;

const FieldContainer = styled.div`
  margin: 40px 0 0;
  display: flex;
  flex-direction: column;
`;

const InputContainerMini = styled.div`
  display: flex;
  justify-content: center;
  height: 65px;
  width: 120px;
  margin: 0 auto;
  margin-bottom: 40px;
  gap: 12px;

  input {
    color: #323b4b;
    padding: 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    border: 1px solid #d9dde3;
    border-radius: 12px;
    width: 64px;
    height: 64px;
    //background: #f5f7fa;
  }

  /* height: 50px;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 25px;
    height: 70px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin-bottom: 30px;
    height: 90px;
  } */
`;

const TextRequiredError = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.t};
  font-weight: 400;
  color: #e40606;
  margin: 0px 0px 10px 0px;
  text-align: center;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

export default function VerifyOtp() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { verifyOtp, login, googleBusiness } = useAuthentication();
  const inputRef = useRef();

  const [emailShow, setEmailShow] = useState(false);
  const [inputValue, setInputValue] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);

  const { Logo } = iconPath;

  // GET PARAMS FOR GMAIL AND MOBILE LOGIN

  const urlParams = new URLSearchParams(window.location.search);
  const userName = urlParams.get('username');
  const mailOtp = urlParams.get('mailOtp');
  const trimmedUserName = userName.trim();
  const userNameWithPlus = mailOtp === 'true' ? trimmedUserName : `+${trimmedUserName}`;

  // OTP INPUT FOCUS CHANGE AUTOMATICALLY FUNCTIONALITY

  function handleChange(e) {
    setErrorMessage('');
    const { maxLength, value, name } = e.target;
    const { keyCode } = e;
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    const otpIndex = parseInt(name.split('-')[1]);
    if (keyCode === BACKSPACE_KEY || e.which === 8) {
      if (value?.length !== maxLength) {
        if (otpIndex <= 4) {
          const previous = document.querySelector(`input[name=input-${otpIndex - 1}]`);
          if (previous !== null) {
            previous.focus();
          }
        }
      }
    } else if (keyCode !== DELETE_KEY) {
      if (value?.length === maxLength) {
        if (otpIndex < 4) {
          const next = document.querySelector(`input[name=input-${otpIndex + 1}]`);
          if (next !== null) {
            next.focus();
          }
        }
      }
    }

    setInputValue({
      ...inputValue,
      [`input${otpIndex}`]: value,
    });
  }

  // FOCUS ON INPUT AUTOMATICALLY WHEN FIRST TIME VISIT THE SCREEN

  useEffect(() => {
    if (!userNameWithPlus) {
      navigate('/login');
    }
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.click();
      inputRef.current.scrollIntoView();
    }, 500);
  }, []);

  // AUTOMATIC VERIFY

  useEffect(() => {
    const inputData = Object.values(inputValue);
    if (inputData.join('') === '1234') {
      setButtonLoader(true);
      setInputValue({
        input1: '',
        input2: '',
        input3: '',
        input4: '',
      });
      verifyOtpButton();
    }
  }, [inputValue]);

  useEffect(() => {
    if (mailOtp) {
      setEmailShow(true);
    } else if (mailOtp === false) {
      setEmailShow(false);
    }
  }, [mailOtp]);

  // VERIFY OTP API CALL (LOGIN AUTOMATICALLY IF USER ALREADY EXISTS OTHERWISE CHANGE THE SCREEN ACCORDING TO THE STATE OF MAIL AND MOBILE NUMBER)

  function verifyOtpButton() {
    setButtonLoader(true);
    const otpValue = Object.values(inputValue).join('');
    verifyOtp({
      username: userNameWithPlus,
      otp: otpValue,
    }).then(({ code, data, success, message }) => {
      const nodeList = document.querySelectorAll('input');
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].value = '';
      }
      if (success && code === 200) {
        localStorage.setItem('newOtp', data?.user_otp_id);
        setEmailShow(false);
        login({
          username: userNameWithPlus,
          otp: data?.new_otp,
        }).then(({ code, data, success }) => {
          if (success && code === 200) {
            setButtonLoader(false);
            toast.success(toastMessage.loginSuccess);
            localStorage.setItem('loginToken', data?.token?.access_token);
            localStorage.setItem('userId', data?.id);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expireToken', data?.token?.expires_in);
            localStorage.setItem('loginTime', new Date());
            localStorage.setItem('business', data?.business_id ? true : false);
            if (data?.business_id) {
              navigate('/dashboard');
            } else {
              if (localStorage.getItem('googleOauth') === 'true') {
                googleBusiness()
                  .then((data) => {
                    if (data?.data.length === 0) {
                      navigate('/search-business');
                      localStorage.setItem('businessData', false);
                    } else {
                      navigate('/select-business');
                      localStorage.setItem('businessData', true);
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                  });
              } else {
                navigate('/search-business');
              }
            }
          } else {
            toast.error(toastMessage.loginError);
            setButtonLoader(false);
            inputRef.current.focus();
            inputRef.current.click();
          }
        });
        // if (data?.is_email_verified === true && data?.is_mobile_verified === true) {
        //   login({
        //     username: userNameWithPlus,
        //     otp: data?.new_otp,
        //   }).then(({ code, data, success }) => {
        //     alert("hello1")
        //     if (success && code === 200) {
        //       setButtonLoader(false);
        //       toast.success(toastMessage.loginSuccess);
        //       localStorage.setItem('loginToken', data?.token?.access_token);
        //       localStorage.setItem('userId', data?.id);
        //       localStorage.setItem('isAuthenticated', true);
        //       localStorage.setItem('expireToken', data?.token?.expires_in);
        //       localStorage.setItem('loginTime', new Date());
        //       localStorage.setItem('business', data?.business_id ? true : false);
        //       if (data?.business_id) {
        //         navigate('/dashboard');
        //       } else {
        //         if (localStorage.getItem('googleOauth') === 'true') {
        //           googleBusiness()
        //             .then((data) => {
        //               if (data?.data.length === 0) {
        //                 navigate('/search-business');
        //                 localStorage.setItem('businessData', false);
        //               } else {
        //                 navigate('/select-business');
        //                 localStorage.setItem('businessData', true);
        //               }
        //             })
        //             .catch((error) => {
        //               toast.error(error);
        //             });
        //         } else {
        //           navigate('/search-business');
        //         }
        //       }
        //     } else {
        //       toast.error(toastMessage.loginError);
        //       setButtonLoader(false);
        //       inputRef.current.focus();
        //       inputRef.current.click();
        //     }
        //   });
        // } else if (data?.is_email_verified === true && data?.is_mobile_verified === false) {
        //   setButtonLoader(false);
        //   navigate('/login', {
        //     state: {
        //       mobile: true,
        //       isNumber: true,
        //       username: userNameWithPlus,
        //     },
        //   });
        // } else if (data?.is_email_verified === false && data?.is_mobile_verified === true) {
        //   setButtonLoader(false);
        //   navigate('/login', {
        //     state: {
        //       email: true,
        //       username: userNameWithPlus,
        //     },
        //   });
        // }
      } else {
        toast.error(message);
        setButtonLoader(false);
        inputRef.current.focus();
        inputRef.current.click();
      }
    });
  }

  return (
    <Container>
      <ContainerBox>
        {/* <LogoContainer>
          <Logo />
        </LogoContainer> */}
        <TitleContainer>
          <Title>Enter Verification Code</Title>
          {/* <Title>Check {emailShow ? 'Email' : 'Message'}</Title> */}
          <Heading>Enter code sent to your {emailShow ? 'email' : 'phone'}</Heading>
          <FieldContainer>
            <InputContainerMini>
              <Input
                ref={inputRef}
                type="text"
                inputMode="numeric"
                kind="square-mini"
                maxLength={1}
                // autoFocus={true}
                name="input-1"
                onKeyUp={handleChange}
              />
              <Input
                inputMode="numeric"
                type="text"
                kind="square-mini"
                maxLength={1}
                name="input-2"
                onKeyUp={handleChange}
              />
              <Input
                inputMode="numeric"
                type="text"
                kind="square-mini"
                maxLength={1}
                name="input-3"
                onKeyUp={handleChange}
              />
              <Input
                inputMode="numeric"
                type="text"
                kind="square-mini"
                maxLength={1}
                name="input-4"
                onKeyUp={handleChange}
              />
            </InputContainerMini>
            {errorMessage && <TextRequiredError>{errorMessage}</TextRequiredError>}
            <Button
              onClick={() => {
                verifyOtpButton();
              }}
              buttonText="Verify"
              buttonLoader={buttonLoader}
              disabled={buttonLoader}
            />
          </FieldContainer>
        </TitleContainer>
      </ContainerBox>
    </Container>
  );
}
