import styled from 'styled-components';
import { iconPath } from '../assets/icons';

const LabelContainer = styled.div`
  display: inline-block;
  border-radius: 2.5rem;
  padding: 5px 10px;
  margin: 0px 4px 8px;
  ${(props) =>
    props.kind === 'border'
      ? `
  background: ${props.select === true ? props.theme.colors.primary : '#EFF1F3'};`
      : `
  background: ${props.theme.colors.tertiary};
  `}
`;
const LabelInnerContainer = styled.div``;

const LabelText = styled.span`
  color: #731780;
  font-size: 14px;
  font-weight: 500;
  ${(props) =>
    props.kind === 'border'
      ? `
    color: ${props.select === true ? props.theme.colors.light : props.theme.colors.dark}; 
    margin: 0px;`
      : `margin: 0px 8px 0px 0px;`}
`;

const CloseIconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const CloseIconContainer = styled.div`
  width: 10px;
`;

export default function Label(props) {
  const { CloseIcon } = iconPath;

  const { labelText, kind, onClick, selectData, key, pageScreen } = props;
  return (
    <>
      <LabelContainer
        kind={kind}
        select={selectData}
        key={key}
        id={key}
        onClick={pageScreen === 'home' && onClick}
      >
        <LabelInnerContainer kind={kind}>
          <LabelText kind={kind} select={selectData}>
            {labelText}
          </LabelText>
          {kind !== 'border' && (
            <CloseIconButton {...props} onClick={onClick}>
              <CloseIconContainer>
                <CloseIcon />
              </CloseIconContainer>
            </CloseIconButton>
          )}
        </LabelInnerContainer>
      </LabelContainer>
    </>
  );
}
