import styled from 'styled-components';
import { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../helpers/authentication';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper';

import { Input, Button, Header, ReactRange, Video, Budget, Label } from '../components';
import rangeDisplay from '../components/rangeDisplay';
import { assetsImages } from '../assets/images';
import { iconPath } from '../assets/icons';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as LogoFooter } from '../assets/images/Logo-footer.svg';
import AccordionTheme from '../components/accordion';

import { AiFillInstagram } from 'react-icons/ai';
import { ImFacebook } from 'react-icons/im';
import { FaLinkedinIn } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Herobanner from '../components/herobanner';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);

// const HeaderContainer = styled.div`
//   padding: 16px 16px 0px 16px;
//   @media ${(props) => props.theme.devices.md} {
//     padding: 25px 25px 0px 25px;
//   }
//   @media ${(props) => props.theme.devices.lg} {
//     padding: 35px 35px 0px 35px;
//   }
// `;

const PromoteOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  @media ${(props) => props.theme.devices.md} {
    flex-direction: row;
    padding-top: 125px;
    padding-bottom: 95px;
  }
`;

const PromoteInnerContainer = styled.div`
  margin-bottom: 24px;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 45px;
  }
  @media ${(props) => props.theme.devices.xl} {
    margin-bottom: 65px;
  }
`;

const PromoteInnerContainerBanner = styled.div`
  margin-bottom: 24px;

  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 45px;
    text-align: left;
  }
  @media ${(props) => props.theme.devices.xl} {
    margin-bottom: 65px;
  }
`;

const PromoteInnerContainerBackground = styled(PromoteInnerContainer)`
  @media ${(props) => props.theme.devices.xl} {
    //background: #f5f5f5;
    margin-bottom: 0px;
    padding: 0px 0px 43px 0px;
  }
`;
const PromoteImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.h1`
  margin: 0px;
  color: ${(props) => props.theme.colors.tt1};
  font-size: ${(props) => props.theme.fonts.sm.h1};
  text-align: center;
  margin: auto;
  max-width: 342px;
  margin-bottom: 16px;
  font-weight: 400;
  font-family: 'Paytone One';
  line-height: 42px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
    max-width: 430px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 3.75rem;
    max-width: 500px;
    line-height: 100%;
  }
`;

const PrimaryTextBanner = styled.h1`
  margin: 0px;
  color: ${(props) => props.theme.colors.tt1};
  font-size: ${(props) => props.theme.fonts.sm.h1};
  text-align: center;
  margin: auto;
  max-width: 342px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'Paytone One';
  line-height: 42px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
    max-width: 430px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 3.75rem;
    max-width: 500px;
    line-height: 100%;
    text-align: left;
  }
`;

const SecondaryTextBanner = styled.h3`
  font-size: 1rem;
  margin-bottom: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.tt2};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h4};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.5rem;
    text-align: left;
  }
`;

const SecoundPrimaryText = styled.h3`
  margin: 0px;
  color: ${(props) => props.theme.colors.tt1};
  font-size: 1.625rem;
  text-align: center;
  margin: auto;
  max-width: 342px;
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Paytone One';
  line-height: 112%;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
    max-width: 430px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 3rem;
    max-width: 500px;
  }
`;

const SecondaryText = styled.h3`
  font-size: 1rem;
  margin: 0px auto;
  text-align: center;
  color: ${(props) => props.theme.colors.tt2};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h4};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.5rem;
  }
`;

const SecondaryTextReachOut = styled.h3`
  font-size: 1rem;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.tt2};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h4};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.75rem;
    text-align: left;
  }
`;

const SecoundPrimaryTextReachOut = styled.h3`
  margin: 0px;
  color: ${(props) => props.theme.colors.tt1};
  font-size: 1.625rem;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  font-weight: 400;
  font-family: 'Paytone One';
  line-height: 112%;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 3rem;
    text-align: left;
  }
`;

const ReachOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 104px;
  padding-bottom: 0px;
  @media ${(props) => props.theme.devices.md} {
    flex-direction: row-reverse;
    gap: 80px;
    padding-top: 104px;
    padding-bottom: 104px;
  }
`;

const ReachOutInnerContainer = styled.div`
  max-width: 570px;
  margin-top: -70px;
`;

const ReachOutImageMobile = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
    display: none;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 35px;
  }
`;

const ReachOutImageDesktop = styled.div`
  margin-bottom: 16px;
  display: none;
  max-width: 475px;
  border: 16px solid #ffffff;
  box-shadow: 0px 48.596px 53.4556px -12.149px rgba(0, 0, 0, 0.08);
  border-radius: 14.5788px;

  @media ${(props) => props.theme.devices.md} {
    display: block;
  }
  @media ${(props) => props.theme.devices.lg} {
  }
`;

const CampaignContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 30px 0px;

  @media ${(props) => props.theme.devices.lg} {
    padding: 120px 0px;
  }
`;

const StepOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px;
  //background: #f5f5f5;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 35px;
    flex-direction: row;
    gap: 63px;
  }
`;

const StepContainerFirst = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 20px 84px -10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 60px;
  flex: 1;

  &:last-child {
    margin-bottom: 0px;
  }
  @media ${(props) => props.theme.devices.sm} {
    width: auto;
  }
  @media ${(props) => props.theme.devices.xl} {
  }
`;
const StepContainer = styled.div`
  @media ${(props) => props.theme.devices.xl} {
    //width: 50%;
  }
`;
const StepPrimaryText = styled.p`
  text-align: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: #226f54;
  border-radius: 50%;
  color: #fff;
  font-size: 26px;
  margin-top: -50px;
  border: 8px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
const StepSecondaryText = styled(SecondaryText)`
  text-align: center;
  font-size: 1rem;
  line-height: 17px;
  margin: 0px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
    line-height: 22px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.4rem;
    line-height: 35px;
  }
`;

const VideoContainer = styled.div`
  margin-bottom: 32px;
`;

const StepThirdText = styled.h4`
  margin: 15px 0px 0px 0px;
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  @media ${(props) => props.theme.devices.md} {
    margin: 12px 0px 0px 0px;
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.875rem;
  }
`;

const InputContainer = styled.div`
  margin-top: 20px;
`;

const LabelText = styled.h3`
  font-size: ${(props) => props.theme.fonts.sm.t};
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 400;
  margin: 0px 0px 12px 0px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.t};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.43rem;
  }
`;

const RangeContainer = styled.div`
  padding: 16px;
`;
const RangeOuterContainer = styled.div`
  margin-top: 16px;
`;

const BudgetContainer = styled.div`
  margin: 8px 0px;
  text-align: center;
  background: ${(props) => props.theme.colors.light};
  //padding: 32px;
  @media ${(props) => props.theme.devices.md} {
    margin: 12px 0px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin: 20px 0px;
  }
`;

const LabelContainer = styled.div`
  text-align: center;
  margin: 20px 0px 0px 0px;
  @media ${(props) => props.theme.devices.lg} {
    margin: 60px 0px 0px 0px;
  }
  /* @media ${(props) => props.theme.devices.xl} {
    width: 50%;
  } */
`;

const ConversionOuterContainer = styled.div``;

const ConversionContainer = styled.div`
  margin: 32px auto;
`;

const ConversionImage = styled.div``;

const GetStartedContainer = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${(props) => props.theme.devices.md} {
    padding-bottom: 112px;
    padding-top: 112px;
  }
`;

const GetStartedContainerInner = styled.div`
  padding: 44px 0px;
  text-align: center;
  background: #e0f5ee;
  border-radius: 10px;
  max-width: 315px;
  margin: 0 auto;
  @media ${(props) => props.theme.devices.xl} {
    max-width: 1140px;
  }
`;

const GetStartedText = styled.h3`
  font-size: 2.625rem;
  font-weight: 700;
  font-family: 'Paytone One';
  line-height: 42px;
  margin: 0px 0px 24px 0px;
`;

const GetstartedSubText = styled.p`
  font-size: 0.875rem;
  color: #4e5d78;
  max-width: 220px;
  margin: 0 auto;
  margin-bottom: 24px;
  @media ${(props) => props.theme.devices.lg} {
    max-width: 500px;
    font-size: 28px;
    line-height: 127%;
  }
`;

const ButtonContainer = styled.div`
  margin: 0px auto;
  max-width: 136px;
  @media ${(props) => props.theme.devices.md} {
    max-width: 200px;
  }
  @media ${(props) => props.theme.devices.lg} {
    max-width: 250px;
  }
`;

const BannerButtonContainer = styled(ButtonContainer)`
  max-width: 210px;
  @media ${(props) => props.theme.devices.lg} {
    margin: inherit;
  }
`;

const LogoOuterContainer = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  @media ${(props) => props.theme.devices.md} {
    width: 170px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 250px;
  }
`;

const BottomText = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  max-width: 370px;
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 40px 0px;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 20px;
  }
`;

const FooterLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;

  a {
    color: #fff;
    font-size: 0.875rem;
  }
`;

const BottomBorder = styled.div`
  border-bottom: 1px solid #ffffff;
  width: 100%;
  margin-bottom: 40px;
  opacity: 0.2;
  @media ${(props) => props.theme.devices.md} {
    border: none;
  }
`;

const CopyRightText = styled.h3`
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2e3032;
  padding: 60px 32px 40px;
  border-radius: 20px 20px 0 0;
  @media ${(props) => props.theme.devices.lg} {
    border-radius: 0;
  }
`;
const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow-x: hidden;
  height: 100%;
  height: 480px;
  padding: 17px;
  position: relative;
  @media ${(props) => props.theme.devices.lg} {
    margin: 0 auto;
    margin-bottom: 96px;
    padding: 0px;
  }
`;

const SlideInnerContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 20px 80px -10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

const SlideTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const HeaderText = styled(CopyRightText)`
  font-weight: bold;
  margin-bottom: 8px;
  text-align: initial;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 15px;
  }
`;
const DescriptionContainer = styled.div`
  text-align: center;
`;

const DescriptionTitle = styled.h4``;

const DescriptionContent = styled.p``;

const Icon = styled.div`
  margin-top: 30px;
`;
const Description = styled(CopyRightText)`
  text-align: initial;
`;
const ImageContainer = styled.div``;

const AccordianOuterContainer = styled.div`
  padding: 0px 17px;
  z-index: 0;
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  @media ${(props) => props.theme.devices.md} {
    margin: 50px 0px;
    padding: 0px 0px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin: 0 auto;
    padding-top: 95px;
    padding-bottom: 146px;
  }
`;

const StyledTrigger = styled(Accordion.Trigger)`
  background-color: ${(props) => props.theme.colors.light};
  cursor: pointer;
  border: none;
  width: 100%;
  position: relative;
  color: ${(props) => props.theme.colors.dark};
  z-index: 10;
  padding: 20px;
  border: 1px solid #d9dde3;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #a2a2a2;
`;

const TitleContainerTrigger = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleTrigger = styled(HeaderText)`
  margin-bottom: 0px;
  font-size: 1rem;
  color: #12141d;
`;

const StyledDownIcon = styled.div`
  width: 10px;
  @media ${(props) => props.theme.devices.md} {
    width: 13px;
  }
  @media ${(props) => props.theme.devices.md} {
    width: 17px;
  }
`;

const ContentContainer = styled.div`
  padding: 16px;
`;

const ContentText = styled.h5`
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

const AccordianText = styled(HeaderText)`
  text-align: center;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 30px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin-bottom: 50px;
  }
`;

const PaymentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* @media ${(props) => props.theme.devices.xl} {
    width: 50%;
  } */
`;

const ButtonOuterContainer = styled.button`
  display: flex;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: ${(props) =>
    props.bold ? `1px solid ${props.theme.colors.primary}` : '1px solid #a2a2a2'};
  @media ${(props) => props.theme.devices.md} {
    border: ${(props) =>
      props.bold ? `2px solid ${props.theme.colors.primary}` : '1px solid #a2a2a2'};
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: 20px;
  }
`;
const ButtonIcon = styled.div`
  margin-right: 10px;
  width: 20px;
  @media ${(props) => props.theme.devices.md} {
    width: 23px;
    margin-right: 15px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 30px;
    margin-right: 15px;
  }
`;
const ButtonText = styled(StepSecondaryText)`
  font-weight: 400;
  font-weight: ${(props) => (props.bold ? '600' : '')};
`;

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightSideContainer = styled(LeftSideContainer)``;
const RightInnerContainer = styled.div`
  margin-left: 16px;
  @media ${(props) => props.theme.devices.md} {
    height: 15px;
  }
  @media ${(props) => props.theme.devices.lg} {
    height: 20px;
  }
  @media ${(props) => props.theme.devices.xl} {
    height: 21px;
  }
`;

const TextRequired = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.t};
  font-weight: 400;
  color: #e40606;
  margin: 0px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

export default function Home() {
  const navigate = useNavigate();
  const { zipCodes } = useAuthentication();

  const [zipCode, setZipCode] = useState('');
  const [labelNewArray, setLabelNewArray] = useState([]);

  // AUTHENTICATION CHECK

  useEffect(() => {
    const now = new Date(localStorage.getItem('loginTime'));
    const expiryDate = new Date(now.getTime() + localStorage.getItem('expireToken') * 1000);
    if (
      localStorage.getItem('loginToken') &&
      localStorage.getItem('business') === 'true' &&
      expiryDate > new Date()
    ) {
      navigate('/dashboard');
    } else if (localStorage.getItem('business') === 'false') {
      navigate('/search-business');
    }
  }, []);

  // DUMMY LABELS DATA

  const labelData = [
    { label: 'Sports', id: 1, select: false },
    { label: 'Entertainment', id: 2, select: false },
    { label: 'Business', id: 3, select: false },
    { label: 'News', id: 4, select: false },
    { label: 'Kids/Family', id: 5, select: false },
    { label: 'Music', id: 6, select: false },
    { label: 'Multicultural', id: 7, select: false },
    { label: 'International', id: 8, select: false },
    { label: 'Cooking', id: 9, select: false },
    { label: 'Screensavers', id: 10, select: false },
    { label: 'Games', id: 11, select: false },
    { label: 'Hobbies', id: 12, select: false },
  ];

  const [rangeValue, setRangeValue] = useState([0, 24]);
  const [showRangeValue, setShowRangeValue] = useState([null, null]);
  const [budgetValue, setBudgetValue] = useState(0);
  const [zipError, setZipError] = useState('');
  const [focusPayment, setFocusPayment] = useState();
  const [householdNew, setHouseholdNew] = useState([]);
  const [labelDisplayData, setLabelDisplayData] = useState(labelData);
  const [zipValue, setZipValue] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const {
    PlayIcon,
    SearchIcon,
    AccordianDownIcon,
    SlideIcon1,
    SlideIcon2,
    SlideIcon3,
    SlideIcon4,
    SlideIcon5,
    SlideIcon6,
    SlideIcon7,
    PayPalIcon,
    PayPalIconFocus,
    CreditCardIcon,
    CreditCardIconFocus,
  } = iconPath;

  const labelArray = [];
  const zipArray = [];
  const householdCount = [];

  // REMOVE ZIP CODE

  function removeLabel(index) {
    setHouseholdNew([...householdNew.slice(0, index), ...householdNew.slice(index + 1)]);
    setLabelNewArray([...labelNewArray.slice(0, index), ...labelNewArray.slice(index + 1)]);
    setZipValue([...zipValue.slice(0, index), ...zipValue.slice(index + 1)]);
  }

  // ADD ZIP CODE

  function addLabel() {
    setButtonLoader(true);
    zipCodes(`?zip_code=${zipCode}`).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setButtonLoader(false);
        if (zipValue.find((data) => data === parseInt(zipCode))) {
          setZipError('You already added this ZipCode');
        } else {
          labelArray.push(`${data?.household} Household in ZIP ${zipCode}`);
          householdCount.push(data?.household);
          zipArray.push(parseInt(zipCode));
          setLabelNewArray([...labelArray, ...labelNewArray]);
          setHouseholdNew([...householdCount, ...householdNew]);
          setZipValue([...zipArray, ...zipValue]);
          setZipCode('');
        }
      } else {
        setButtonLoader(false);
        setZipError(message);
      }
    });
  }

  // SCROLL TO TOP FUNCTIONALITY

  function scrollToTopFunction() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  // ON CALCULATION OF HOUSEHOLD SET THE DAILY BUDGET

  useEffect(() => {
    const householdCount = [...householdNew];
    if (householdCount?.length > 0) {
      const count = householdCount.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );
      if (count > 0) {
        const moreCount = parseInt(count / 250) + 1;
        const budgetValueOriginal = moreCount * 5;
        setBudgetValue(budgetValueOriginal);
      } else {
        setBudgetValue(0);
      }
    }
    if (householdCount?.length === 0) {
      setBudgetValue(0);
    }
  }, [householdNew]);

  // SET RANGE VALUE

  useEffect(() => {
    const value = rangeDisplay(rangeValue);
    setShowRangeValue(value);
  }, [rangeValue]);

  // VERTICAL SLIDE DATA

  const slideData = [
    {
      heading: 'Get a Call',
      image: assetsImages.Slide1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon1 />,
    },
    {
      heading: 'Chat on Whatsapp',
      image: assetsImages.Slide2,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon2 />,
    },
    {
      heading: 'Social Pages',
      image: assetsImages.Slide3,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon3 />,
    },
    {
      heading: 'Get an Email',
      image: assetsImages.Slide4,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon4 />,
    },
    {
      heading: 'Navigate to your business',
      image: assetsImages.Slide5,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon5 />,
    },
    {
      heading: 'Visit website',
      image: assetsImages.Slide6,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon6 />,
    },
    {
      heading: 'Purchase product',
      image: assetsImages.Slide7,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam nibh mauris, in congue leo ultricies sed.',
      icon: <SlideIcon7 />,
    },
  ];

  // ACCORDIAN DATA

  const accordianData = [
    'What is Infy ?',
    'How can I sign up?',
    'How can I create my on video?',
    'What payment are available?',
  ];

  // DIFFERENT PAYMENT OPTION DATA

  const paymentButtonData = [
    {
      id: 1,
      icon: focusPayment === 1 ? <CreditCardIconFocus /> : <CreditCardIcon />,
      buttonText: 'Credit Card',
      rightIcons: [assetsImages.IconCredit1, assetsImages.IconCredit2, assetsImages.IconCredit3],
      bold: focusPayment === 1 ? true : false,
    },
    {
      id: 2,
      icon: focusPayment === 2 ? <PayPalIconFocus /> : <PayPalIcon />,
      buttonText: 'Paypal',
      rightIcons: [assetsImages.IconPayPal1],
      bold: focusPayment === 2 ? true : false,
    },
  ];

  function PaymentButtonComponent({ icon, buttonText, rightIcons, id, bold }) {
    return (
      <>
        <ButtonOuterContainer onClick={() => setFocusPayment(id)} bold={bold}>
          <LeftSideContainer>
            <ButtonIcon>{icon}</ButtonIcon>
            <ButtonText bold={bold}>{buttonText}</ButtonText>
          </LeftSideContainer>
          <RightSideContainer>
            {rightIcons?.length > 0 &&
              rightIcons.map((data, index) => {
                return (
                  <>
                    <RightInnerContainer key={index}>
                      <img src={data} alt="" width="100%" height="100%" />
                    </RightInnerContainer>
                  </>
                );
              })}
          </RightSideContainer>
        </ButtonOuterContainer>
      </>
    );
  }

  // CHANGE LABEL DISPLAY ACCORDING TO THE CLICK

  function clickLabelData(id) {
    const displayDataTemp = [...labelDisplayData];
    displayDataTemp.forEach((data) => {
      if (data.id === id) {
        if (data.select === false) {
          data.select = true;
        } else {
          data.select = false;
        }
      }
    });

    setLabelDisplayData(displayDataTemp);
  }

  // PAYMENT BUTTON CLICK

  function onPayClick() {
    alert('This process is so easy like you had filled the form');
    navigate('/login');
    setLabelNewArray([]);
    setRangeValue([0, 24]);
    const displayDataTemp = [...labelDisplayData];
    displayDataTemp.map((data) => {
      return (data.select = false);
    });
    setLabelDisplayData(displayDataTemp);
    setBudgetValue(0);
  }

  function zipBudgetMinus() {
    if (labelNewArray?.length > 0) {
      return labelNewArray?.length * 5;
    }
  }

  // GET STARTED BUTTON CLICK

  function getStartedClick() {
    if (localStorage.getItem('isAuthenticated') && localStorage.getItem('loginToken')) {
      if (localStorage.getItem('business')) {
        navigate('/dashboard');
      } else {
        navigate('/search-business');
      }
    } else {
      navigate('/login');
    }
  }

  return (
    <>
      <Header kind="landing" />
      <PromoteOuterContainer>
        <PromoteInnerContainerBanner>
          <PrimaryTextBanner>Local TV Ads for small business</PrimaryTextBanner>
          <SecondaryTextBanner>Easy to setup and run.</SecondaryTextBanner>
          <BannerButtonContainer>
            <Button
              kind="round"
              buttonText="It’t easy, Get Started!"
              onClick={() => getStartedClick()}
            />
          </BannerButtonContainer>
        </PromoteInnerContainerBanner>
        <Herobanner />
      </PromoteOuterContainer>
      <ReachOutContainer>
        <ReachOutInnerContainer>
          <SecondaryTextReachOut>Reach Out Your Customers</SecondaryTextReachOut>
          <SecoundPrimaryTextReachOut>
            On the most engaging platform (TV)
          </SecoundPrimaryTextReachOut>
        </ReachOutInnerContainer>
        <ReachOutImageMobile>
          <img alt="" src={assetsImages.WatchImage} width="100%" height="100%" />
        </ReachOutImageMobile>
        <ReachOutImageDesktop>
          <img alt="" src={assetsImages.WatchImageDsk} width="100%" height="100%" />
        </ReachOutImageDesktop>
      </ReachOutContainer>
      <CampaignContainer>
        <PromoteInnerContainerBackground>
          <SecondaryText>How To Create A Campaign</SecondaryText>
          <SecoundPrimaryText>In Just 3 Easy Steps</SecoundPrimaryText>
        </PromoteInnerContainerBackground>
        <StepOuterContainer>
          <StepContainerFirst>
            <StepContainer>
              <StepPrimaryText>1</StepPrimaryText>
              <StepSecondaryText>Create Video</StepSecondaryText>
              <StepThirdText>
                Select any video from your phone, YouTube or create one with our{' '}
                <strong>AI video tool</strong> in seconds!
              </StepThirdText>

              <InputContainer>
                <Input placeholder="Search your business" icon={<SearchIcon />} kind="background" />
              </InputContainer>
            </StepContainer>
            <VideoContainer>
              <Video playIcon={<PlayIcon />} videoImage={assetsImages.Background} kind="big" />
            </VideoContainer>
          </StepContainerFirst>
          <StepContainerFirst>
            <StepContainer>
              <StepPrimaryText>2</StepPrimaryText>
              <StepSecondaryText>Set Campaign Parameters</StepSecondaryText>
              <StepThirdText>Set Zip codes, TIme Slots, Holidays, Conversion methods</StepThirdText>
              <InputContainer>
                <Input
                  placeholder="Enter Zip codes"
                  kind="background"
                  value={zipCode}
                  type="text"
                  maxLength="5"
                  pattern="[0-9]*"
                  buttonLoaderZip={buttonLoader}
                  onInput={(e) => {
                    if (e.target.validity.valid) {
                      setZipCode(e.target.value);
                    } else {
                      setZipCode(zipCode);
                    }
                    setZipError('');
                  }}
                  postFix={
                    <Button
                      buttonText="+"
                      kind="mini-round"
                      disabled={(zipCode?.length === 0 || buttonLoader) && true}
                      onClick={addLabel}
                    />
                  }
                />
              </InputContainer>
              {zipError && <TextRequired>{zipError}</TextRequired>}
              {labelNewArray?.length > 0 &&
                labelNewArray.map((data, index) => {
                  return <Label labelText={data} key={index} onClick={() => removeLabel(index)} />;
                })}
              <RangeOuterContainer>
                <LabelText>Select time slots ({rangeValue[1] - rangeValue[0]} hours)</LabelText>
                <RangeContainer>
                  <ReactRange
                    rangeValue={rangeValue}
                    showRangeValue={showRangeValue}
                    onChange={(values) => setRangeValue(values)}
                    secondaryColor={'#ffffff'}
                  />
                </RangeContainer>
              </RangeOuterContainer>
            </StepContainer>
            <LabelContainer>
              {labelDisplayData?.length > 0 &&
                labelDisplayData.map((data) => {
                  return (
                    <Label
                      kind="border"
                      labelText={data?.label}
                      pageScreen="home"
                      key={data?.id}
                      selectData={data?.select}
                      onClick={() => clickLabelData(data?.id)}
                    />
                  );
                })}
            </LabelContainer>
          </StepContainerFirst>
          <StepContainerFirst>
            <StepContainer>
              <StepPrimaryText>3</StepPrimaryText>
              <StepSecondaryText>Set your daily Budget</StepSecondaryText>
              <BudgetContainer>
                <Budget
                  zipBudget={zipBudgetMinus()}
                  budgetText="Starting from $5 per 250 Household"
                  budgetValue={budgetValue}
                  clickPlus={() => setBudgetValue(budgetValue + 5)}
                  clickMinus={() => setBudgetValue(budgetValue - 5)}
                />
              </BudgetContainer>
            </StepContainer>
            <PaymentButtonContainer>
              {paymentButtonData?.length > 0 &&
                paymentButtonData.map((data, index) => {
                  return (
                    <PaymentButtonComponent
                      key={index}
                      id={data?.id}
                      icon={data?.icon}
                      buttonText={data?.buttonText}
                      rightIcons={data?.rightIcons}
                      bold={data?.bold}
                    />
                  );
                })}
              <Button buttonText="Pay" onClick={() => onPayClick()} />
            </PaymentButtonContainer>
          </StepContainerFirst>
        </StepOuterContainer>
      </CampaignContainer>

      <ConversionOuterContainer>
        <ConversionContainer>
          <PromoteInnerContainer>
            <SecondaryText>Smart-QR</SecondaryText>
            <SecoundPrimaryText>Conversion</SecoundPrimaryText>
          </PromoteInnerContainer>
          {/* <ConversionImage>
            <img alt="" src={assetsImages.QRImage} width="100%" height="100%" />
          </ConversionImage> */}
        </ConversionContainer>
      </ConversionOuterContainer>
      <SliderContainer className="swiper-wrap">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={'auto'}
          navigation={{ nextEl: '.conversion__slider__left', prevEl: '.conversion__slider__right' }}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1200: {
              slidesPerView: 3,
              spaceBetween: 48,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 48,
            },
            320: {
              slidesPerView: 1,
              slidesPerColumn: 1,
              spaceBetween: 0,
            },
          }}
          className="swiper-wrap-inner"
        >
          {slideData?.length > 0 &&
            slideData.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <SlideInnerContainer key={index}>
                    <ImageContainer>
                      <img src={data?.image} width="100%" height="100%" alt="" />
                    </ImageContainer>
                    <SlideTopContainer>
                      <Icon>{data?.icon}</Icon>
                      <DescriptionContainer>
                        <DescriptionTitle>{data?.heading}</DescriptionTitle>
                        <DescriptionContent>{data?.description}</DescriptionContent>
                      </DescriptionContainer>
                    </SlideTopContainer>
                  </SlideInnerContainer>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <button className="conversion__slider__right swiper-action swiper-button-prev"></button>
        <button className="conversion__slider__left swiper-action swiper-button-next"></button>
      </SliderContainer>
      <GetStartedContainer>
        <GetStartedContainerInner>
          <GetStartedText>Get Started with TV ads.</GetStartedText>
          <GetstartedSubText>
            Let’s started with Infy to promote your bussiness on TV.
          </GetstartedSubText>
          <ButtonContainer>
            <Button kind="round" buttonText="Get Started!" onClick={() => getStartedClick()} />
          </ButtonContainer>
        </GetStartedContainerInner>
      </GetStartedContainer>
      <AccordianOuterContainer>
        <PromoteInnerContainer>
          <SecondaryText>In case we missed anything</SecondaryText>
          <SecoundPrimaryText>Frequently Asked Question</SecoundPrimaryText>
        </PromoteInnerContainer>
        <AccordionTheme
          items={[
            {
              title: 'What is Infy?',
              content:
                'Your partner in CTV monetization driven by improved consumer engagement and true programmatic integration',
            },
            {
              title: 'How can i sign up?',
              content: 'Content for Accordion Item 2',
            },
            {
              title: 'How can I create my on video?',
              content: 'Content for Accordion Item 3',
            },
            {
              title: 'What payment are available?',
              content: 'Content for Accordion Item 3',
            },
          ]}
        />
        {/* <Accordion.Root type="single" collapsible>
          {accordianData?.length > 0 &&
            accordianData.map((data, index) => {
              return (
                <Accordion.Item value={`item-${index}`} key={index}>
                  <StyledTrigger>
                    <TitleContainerTrigger>
                      <TitleTrigger>{data}</TitleTrigger>
                      <StyledDownIcon>
                        <AccordianDownIcon />
                      </StyledDownIcon>
                    </TitleContainerTrigger>
                  </StyledTrigger>
                  <Accordion.Content>
                    <ContentContainer>
                      <ContentText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam
                        nibh mauris, in congue leo ultricies sed.
                      </ContentText>
                    </ContentContainer>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
        </Accordion.Root> */}
      </AccordianOuterContainer>
      <BottomContainer>
        <LogoOuterContainer
          onClick={() => {
            scrollToTopFunction();
          }}
        >
          <LogoFooter />
        </LogoOuterContainer>
        <BottomText>
          Your partner in CTV monetization driven by improved consumer engagement and true
          programmatic integration
        </BottomText>
        <SocialMedia>
          <a href="https://www.instagram.com/infytv" target="_blank" rel="noreferrer">
            <AiFillInstagram fontSize={30} color={'#ffffff'} />
          </a>
          <a href="https://www.facebook.com/infytvllc/" target="_blank" rel="noreferrer">
            <ImFacebook fontSize={25} color={'#ffffff'} />
          </a>
          <a href="https://www.linkedin.com/company/infytv" target="_blank" rel="noreferrer">
            <FaLinkedinIn fontSize={30} color={'#ffffff'} />
          </a>
          <a href="mailto:hello@infy.tv" title="hello@infy.tv">
            <MdEmail fontSize={30} color={'#ffffff'} />
          </a>
        </SocialMedia>
        <BottomBorder />
        <FooterLink>
          <a href="/terms-conditions">Terms & Condition</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </FooterLink>
        <BottomText>Copyright © 2023 Infy.TV LLC All Rights Reserved.</BottomText>
        {/* <CopyRightText>
          Copyright
          <img
            src={assetsImages.CopyRight}
            alt=""
            height="14px"
            width="14px"
            style={{ textAlign: 'center' }}
          />{' '}
          2023 Infy Tv LLC.
        </CopyRightText>
        <CopyRightText>All rights reserved.</CopyRightText> */}
      </BottomContainer>
    </>
  );
}
