import { Col } from 'react-grid-system';
import styled from 'styled-components';

const RupeeText = styled.h5`
  margin: 0px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 8px;
  @media ${(props) => props.theme.devices.md} {
    font-size: 1.4rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: ${(props) => props.theme.fonts.xl.p};
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: ${(props) => props.theme.fonts.xxl.h5};
  }
`;

const BudgetText = styled.h5`
  font-size: 0.75rem;
  margin: 0px 4px 0px 0px;
  @media ${(props) => props.theme.devices.md} {
    font-size: 0.75rem;
    margin: 0px 16px 0px 0px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.3rem;
    margin: 0px 30px 0px 0px;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.87rem;
    margin: 0px 25px 0px 0px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 1.125rem;
    margin: 0px 40px 0px 0px;
  }
`;

const RupeeSpanText = styled.span`
  font-size: ${(props) => props.theme.fonts.sm.h5};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.t};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: ${(props) => props.theme.fonts.lg.t};
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

export default function BudgetCampaign({ budgetAmount, sm, lg }) {
  return (
    <>
      <Col
        xs={sm}
        sm={sm}
        md={sm}
        lg={lg}
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <BudgetText className="label">Current Daily Budget</BudgetText>

        <RupeeText className="price">
          ${budgetAmount}
          <RupeeSpanText>.00</RupeeSpanText>
        </RupeeText>
      </Col>
    </>
  );
}
