import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import useAuthentication from '../helpers/authentication';

import {
  Button,
  Video,
  ReactSelect,
  Input,
  Header,
  Label,
  Budget,
  ReactRange,
} from '../components';
import { toastMessage } from '../components/toast';
import rangeDisplay from '../components/rangeDisplay';

import { iconPath } from '../assets/icons';

const Container = styled.div`
  padding: 0px 16px 16px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const PromoteOuterContainer = styled.div`
  @media ${(props) => props.theme.devices.xl} {
    padding: 0px 200px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 0px 400px;
  }
`;

const VideoOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 15px;
  @media ${(props) => props.theme.devices.md} {
    margin-top: 30px;
  } */
`;

const VideoDescriptionOuterContainer = styled.div``;

const VideoDescriptionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const DateText = styled.span`
  margin: 0px;
  font-size: 0.875rem;
  font-weight: bold;
`;

const TitleText = styled(DateText)`
  font-weight: 400;
`;

const FormOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  @media ${(props) => props.theme.devices.lg} {
    margin-top: 35px;
  }
`;

const FormInnerContainer = styled.div`
  margin-bottom: 15px;
  /* @media ${(props) => props.theme.devices.xl} {
    margin-bottom: 50px;
  } */
`;

const LabelText = styled.h3`
  color: ${(props) => props.theme.colors.tt1};
  margin: 0px 0px 8px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: 1.06rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.375rem;
  } */
`;

const TooltipContainer = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  margin-bottom: 15px;
`;

const ToolTipText = styled(LabelText)`
  font-size: ${(props) => props.theme.fonts.sm.p};
  margin: 0px 0px 0px 8px;
  color: ${(props) => props.theme.colors.lightGray};
`;

const ToolTipIconContainer = styled.div`
  width: 12px;
  @media ${(props) => props.theme.devices.md} {
    width: 16px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 20px;
  }
`;

const InputSuffixContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const RangeContainer = styled.div`
  padding: 16px;
  margin-bottom: 25px;
  @media ${(props) => props.theme.devices.md} {
    margin-bottom: 32px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin-bottom: 40px;
  }
`;

const BudgetContainer = styled.div`
  margin: 25px auto 140px auto;
  text-align: center;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  margin: 0px auto;
  width: 100%;
  background: ${(props) => props.theme.colors.light};
  box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.25);
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  max-width: 870px;
  margin: 0 auto;
  /* @media ${(props) => props.theme.devices.xl} {
    padding: 15px 240px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 15px 440px;
  } */
`;

const PromoteIconContainer = styled.div`
  width: 32px;
`;

const TextRequired = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  color: #e40606;
  margin: 0px;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  } */
`;

const BoldText = styled.h4`
  font-weight: 700;
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.p};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

const TextRequiredError = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.t};
  font-weight: 400;
  color: #e40606;
  margin: 10px 0px 0px 0px;
  text-align: center;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

const TextRequiredErrorCondition = styled(TextRequiredError)`
  margin-top: 10px;
  text-align: initial;
`;

const SurroundingContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 5px;
`;

const SurroundingText = styled.h3`
  margin: 0px 20px 0px 0px;
  color: ${(props) => (props.loader ? '#cbcbcb' : props.theme.colors.primary)};
  font-size: 12px;
  cursor: pointer;
  /* text-decoration: underline; */
  /* @media ${(props) => props.theme.devices.md} {
    font-size: 1rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.3rem;
  } */
`;

const MultiSelect = styled(Select)`
  margin-bottom: 15px;
`;

export default function Promote() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const inputPromote = useRef();

  const {
    zipCodes,
    categoriesChannel,
    postCampaignVideo,
    retrieveCampaignVideo,
    editCampaignVideo,
    getPaymentCal,
    getConversionMode,
    postStripeSubscription,
    zipCodeNearBy,
  } = useAuthentication();

  const [rangeValue, setRangeValue] = useState([0, 24]);
  const [showRangeValue, setShowRangeValue] = useState([null, null]);
  const [budgetValue, setBudgetValue] = useState(0);
  const [zipCode, setZipCode] = useState('');
  const [labelNewArray, setLabelNewArray] = useState([]);
  const [zipValue, setZipValue] = useState([]);
  const [selectChannelOption, setSelectChannelOption] = useState([]);
  const [selectChannelOptionParticular, setSelectChannelOptionParticular] = useState([]);
  const [channelDisplayData, setChannelDisplayData] = useState();
  const [selectChannel, setSelectChannel] = useState(null);
  const [selectConversions, setSelectConversions] = useState([]);
  const [zipError, setZipError] = useState('');
  const [clickPlus, setClickPlus] = useState(0);
  const [holidaySelectOption, setHolidaySelectOption] = useState([]);
  const [holidayDisplayData, setHolidayDisplayData] = useState();
  const [conversionMode, setConversionMode] = useState('');
  const [conversionModeDisplay, setConversionModeDisplay] = useState([]);
  const [conversionModeID, setConversionModeID] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonLoaderZip, setButtonLoaderZip] = useState(false);
  const [household, setHousehold] = useState([]);
  const [editCampaignId, setEditCampaignId] = useState();
  const [householdNew, setHouseholdNew] = useState([]);
  const [householdEditCount, setHouseholdEditCount] = useState([]);
  const [editBudget, setEditBudget] = useState(false);
  const [conversionError, setConversionError] = useState();
  const [holidayError, setHolidayError] = useState();
  const [timeSlotError, setTimeSlotError] = useState();
  const [surrounding, setSurrounding] = useState(true);
  const [surroundingLoader, setSurroundingLoader] = useState(false);
  const [surroundingArray, setSurroundingArray] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [campaignError, setCampaignError] = useState();

  const { date, videoImage, videoTime, type, videoUrl, thumbnailUrl, callId, callValue } = state;

  const selectHolidayOption = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
  ];

  const {
    PromoteIcon,
    ClosePromoteIcon,
    TooltipIcon,
    UploadDeviceIcon,
    FacebookMiniIcon,
    YoutubeMiniIcon,
  } = iconPath;

  const labelArray = [];
  const zipArray = [];
  const householdCount = [];
  const householdNewCount = [];
  let surroundingZip = [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      'borderColor': '#D9DDE3',
      'borderColor': state.isFocused ? '#D9DDE3' : provided.borderColor,
      'borderWidth': state.isFocused ? '1px' : provided.borderWidth,
      'boxShadow': state.isFocused ? 'none' : provided.boxShadow,
      ':hover': {
        borderColor: '#D9DDE3',
      },
      '@media (min-width: 768px)': {
        minHeight: '40px',
        fontSize: '14px',
      },
      '@media (min-width: 1024px)': {
        minHeight: '40px',
        fontSize: '14px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      'backgroundColor': state.isFocused ? '#E6D8E4' : provided.backgroundColor,
      ':hover': {
        backgroundColor: '#E6D8E4',
      },
      '@media (min-width: 768px)': {
        fontSize: '14px',
      },
      '@media (min-width: 1024px)': {
        fontSize: '14px',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '12px',
      backgroundColor: '#E6D8E4', // Set your desired background color
    }),
  };

  // SURROUNDING ZIP CODES GET API

  function surroundingZipCodes() {
    setSurroundingLoader(true);
    zipCodeNearBy(JSON.stringify(surroundingArray)).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setSurrounding(false);

        const zipCodes = [...data];
        setSurroundingLoader(false);
        zipCodes.forEach((data) => {
          if (zipValue.find((zipValue) => zipValue === data?.zipcode)) {
          } else {
            labelArray.push(
              `${data?.household === null ? 0 : data?.household} Household in ZIP ${data?.zipcode}`,
            );
            householdCount.push(data?.household === null ? 0 : data?.household);
            zipArray.push(data?.zipcode);
            setLabelNewArray([...labelArray, ...labelNewArray]);
            setHouseholdNew([...householdCount, ...householdNew]);
            setZipValue([...zipArray, ...zipValue]);
          }
        });
        setSurroundingArray([]);
        surroundingZip = [];
      } else {
        setSurroundingLoader(false);
        console.log(message);
      }
    });
  }

  // ADD ZIP CODE

  function addLabel() {
    setButtonLoaderZip(true);
    setSurrounding(true);
    inputPromote.current.focus();
    zipCodes(`?zip_code=${zipCode}`).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setButtonLoaderZip(false);
        if (zipValue.find((data) => data === parseInt(zipCode))) {
          setZipError('You already added this ZipCode');
        } else {
          if (editBudget && state?.edit) {
            setEditBudget(false);
          }
          labelArray.push(`${data?.household} Household in ZIP ${zipCode}`);
          householdCount.push(data?.household);
          zipArray.push(parseInt(zipCode));
          surroundingZip.push(parseInt(zipCode));
          setLabelNewArray([...labelArray, ...labelNewArray]);
          setHouseholdNew([...householdCount, ...householdNew]);
          setZipValue([...zipArray, ...zipValue]);
          setSurroundingArray([...surroundingZip, ...surroundingArray]);
          setZipCode('');
        }
      } else {
        setButtonLoaderZip(false);
        setZipError(message);
      }
    });
  }

  // REMOVE ZIP CODE

  function removeLabel(index) {
    if (editBudget && state?.edit) {
      setEditBudget(false);
    }
    setLabelNewArray([...labelNewArray.slice(0, index), ...labelNewArray.slice(index + 1)]);
    setHouseholdNew([...householdNew.slice(0, index), ...householdNew.slice(index + 1)]);
    setZipValue([...zipValue.slice(0, index), ...zipValue.slice(index + 1)]);
    setSurroundingArray([
      ...surroundingArray.slice(0, index),
      ...surroundingArray.slice(index + 1),
    ]);
  }

  // PAYMENT CALCULATION GET API

  function getPaymentCalculations(id, campaignId) {
    getPaymentCal(`${id}/?campaign_id=${campaignId}`).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        navigate('/subscriptions', {
          state: {
            budgetValue: data,
            videoId: id,
            campaignId: campaignId,
          },
        });
      } else {
        console.log(message);
      }
    });
  }

  useEffect(() => {
    const value = rangeDisplay(rangeValue);
    setShowRangeValue(value);
  }, [rangeValue]);

  // GET TV/CHANNEL CATEGORIES

  const selectAll = [
    {
      id: 0,
      value: 'Select All',
      label: 'Select All',
    },
  ];

  let channelData;

  function getCategories() {
    categoriesChannel('?type=all').then(({ code, data, success, message }) => {
      if (success && code === 200) {
        if (data?.length > 0) {
          setSelectChannelOption([...selectAll, ...data]);
          setSelectChannelOptionParticular(data);
          channelData = data;
        }
      } else {
        console.log(message);
      }
    });
  }

  // GET CONVERSION OPTIONS

  function getConversions() {
    getConversionMode().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        const tempConversionsArray = [];
        const urlArray = [];
        const nonUrlArray = [];
        const addressArray = [];

        for (const [key, value] of Object.entries(data)) {
          if (key !== 'url_business' && key !== 'address_data') {
            const array = {
              label:
                key === 'email' && data['email'] !== '' ? (
                  <BoldText>
                    Email: <span style={{ fontWeight: 400 }}>{value}</span>
                  </BoldText>
                ) : key === 'mobile' && data['mobile'] !== '' ? (
                  <BoldText>
                    Call Us: <span style={{ fontWeight: 400 }}>{value}</span>
                  </BoldText>
                ) : (
                  key === 'save_mobile' &&
                  data['save_mobile'] !== '' && (
                    <BoldText>
                      Save: <span style={{ fontWeight: 400 }}>{value}</span>
                    </BoldText>
                  )
                ),
              callID:
                key === 'email' && data['email'] !== ''
                  ? 3
                  : key === 'mobile' && data['mobile'] !== ''
                  ? 1
                  : 2,
              value: key,
              tempValue: value,
            };
            nonUrlArray.push(array);
          } else if (key === 'url_business') {
            const temp = [...value];
            temp.map((data) => {
              const tempUrl = {
                label: (
                  <BoldText>
                    Website: <span style={{ fontWeight: 400 }}>{data?.url_value}</span>
                  </BoldText>
                ),
                callID: 5,
                value: data?.url_key,
                tempValue: data?.url_value,
                call_to_action: data?.is_call_to_action,
              };
              return urlArray.push(tempUrl);
            });
            tempConversionsArray.push(...nonUrlArray, ...urlArray);
          } else if (key === 'address_data') {
            const temp = [...value];
            temp.map((data) => {
              const tempUrl = {
                label: (
                  <BoldText>
                    Visit Us: <span style={{ fontWeight: 400 }}>{data?.address_value}</span>
                  </BoldText>
                ),
                callID: 4,
                value: data?.address_key,
                tempValue: data?.address_value,
                call_to_action: data?.is_call_to_action,
              };
              return addressArray.push(tempUrl);
            });
          }
          tempConversionsArray.push(...nonUrlArray, ...urlArray, ...addressArray);
        }

        const removeBlankValue = tempConversionsArray.filter(
          (data) => data?.tempValue !== '' && data.tempValue !== null,
        );
        if (type === 'synthesia') {
          const array = [];
          array.push({
            label: (
              <BoldText>
                {callId === 1
                  ? 'Call Us'
                  : callId === 2
                  ? 'Save'
                  : callId === 3
                  ? 'Email'
                  : callId === 4
                  ? 'Visit Us'
                  : callId === 5
                  ? 'Website'
                  : ''}
                : <span style={{ fontWeight: 400 }}>{callValue}</span>
              </BoldText>
            ),
            callID: callId,
            value:
              callId === 1
                ? 'mobile'
                : callId === 2
                ? 'save_mobile'
                : callId === 3
                ? 'email'
                : callId === 4
                ? 'address'
                : callId === 5
                ? 'websites'
                : '',
            tempValue: callValue,
            call_to_action: true,
          });
          setSelectConversions(array);
          setConversionModeDisplay(array);
          conversionModeChange(array[0]);
        } else {
          const uniqueChars = [...new Set(removeBlankValue)];
          setSelectConversions(uniqueChars);
        }
      } else {
        console.log(message);
      }
    });
  }

  useEffect(() => {
    if (household?.length > 0) {
      setLabelNewArray([household, ...labelNewArray]);
    }
  }, [household]);

  useEffect(() => {
    if (household?.length > 0) {
      setHouseholdNew([householdEditCount, ...householdNew]);
    }
  }, [householdEditCount]);

  // GET THE CAMPAIGN ALREADY CREATED

  function retrieveVideoCampaign() {
    retrieveCampaignVideo(`${state?.campaignId}/`).then(({ code, data, message, success }) => {
      if (success && code === 200) {
        setBudgetValue(data?.budget_amount);
        setEditBudget(true);
        setEditCampaignId(data?.campaign_video_id);
        if (data?.zip_value !== null) {
          const tempHoldData = [...data?.zip_value];
          tempHoldData.map((zipCode) =>
            zipCodes(`?zip_code=${zipCode}`).then(({ code, data, success, message }) => {
              if (code === 200 && success) {
                setHousehold(`${data?.household} Household in ZIP ${zipCode}`);
                setHouseholdEditCount(data?.household);
                householdNewCount.push(data?.household);
              }
            }),
          );
          setZipValue(data?.zip_value);
        }
        let splitSecondDataShow;
        let splitFirstDataShow;
        if (data?.campaign_time !== null) {
          if (data?.campaign_time[1].includes('PM')) {
            const splitSecondData = parseInt(data?.campaign_time[1]?.split('PM')[0]);
            if (splitSecondData === 12) {
              splitSecondDataShow = 12;
            } else {
              splitSecondDataShow = splitSecondData + 12;
            }
          }
          if (data?.campaign_time[1].includes('AM')) {
            const splitSecondData = parseInt(data?.campaign_time[1]?.split('AM')[0]);
            splitSecondDataShow = splitSecondData;
            if (splitSecondDataShow === 12) {
              splitSecondDataShow = 24;
            }
          }
          if (data?.campaign_time[0].includes('PM')) {
            const splitFirstData = parseInt(data?.campaign_time[0]?.split('PM')[0]);
            if (splitFirstData === 12) {
              splitFirstDataShow = 12;
            } else {
              splitFirstDataShow = splitFirstData + 12;
            }
          }
          if (data?.campaign_time[0].includes('AM')) {
            const splitFirstData = parseInt(data?.campaign_time[0]?.split('PM')[0]);
            splitFirstDataShow = splitFirstData;
            if (splitFirstDataShow === 12) {
              splitFirstDataShow = 0;
            }
          }
          setRangeValue([splitFirstDataShow, splitSecondDataShow]);
        }

        setConversionMode(data?.call_to_action_value);
        setConversionModeID(data?.call_to_action);
        setHolidaySelectOption(data?.holiday);
        setSelectChannel(data?.campaign_channels);

        if (data?.campaign_channels?.length === channelData?.length) {
          setChannelDisplayData(selectAll);
          setSelectChannelOption([]);
        } else if (data?.campaign_channels?.length > 0) {
          setChannelDisplayData(data?.campaign_channels);
        } else {
          setChannelDisplayData();
        }
        if (data?.holiday?.length > 0) {
          const filterHoliday = data?.holiday.map((data) => {
            return {
              value: data,
              label: data,
            };
          });
          setHolidayDisplayData(filterHoliday);
        } else {
          setHolidayDisplayData();
        }
      } else {
        console.log(message);
      }
    });
  }

  // ON CALCULATION OF HOUSEHOLD SET THE DAILY BUDGET

  useEffect(() => {
    const householdCount = [...householdNew];
    if (householdCount?.length > 0) {
      const count = householdCount.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

      if (count > 0) {
        const moreCount = parseInt(count / 250) + 1;

        const budgetValueOriginal = moreCount * 5;
        setBudgetValue(budgetValueOriginal);
      } else {
        setBudgetValue(0);
      }
    }
    if (householdCount?.length === 0 && state?.edit && clickPlus > 0) {
      setBudgetValue(0);
    }
    if (householdCount?.length === 0 && clickPlus === 0) {
      setBudgetValue(0);
    }
  }, [householdNew]);

  // FILTER CONDITION IF TYPE IS SYNTHESIA

  useEffect(() => {
    if (type !== 'synthesia') {
      const filterOptions = selectConversions.find((item) => item?.tempValue === conversionMode);
      setConversionModeDisplay(filterOptions);
    }
  }, [selectConversions]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    getCategories();
    getConversions();
    if (state?.edit) {
      retrieveVideoCampaign();
    }
  }, []);

  // CREATE CAMPAIGN FOR THE FIRST TIME

  function createCampaign() {
    // GET SUBDOMAIN
    const subdomain = window.location.origin.split('.')[0].split('//')[1];
    // Get Base URL
    const baseUrl = window.location.origin;

    const todayDate = new Date().toISOString().slice(0, 10);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 3);

    const zipArray = [...zipValue];

    let valid = true;

    if (campaignName?.length === 0) {
      valid = false;
      setCampaignError('Campaign Name is required');
    } else if (rangeValue[1] - rangeValue[0] === 0) {
      valid = false;
      setTimeSlotError('Time Slot should be at-least for 1 hour');
    } else if (holidaySelectOption?.length === selectHolidayOption?.length) {
      valid = false;
      setHolidayError('At-least One Active day should be in the list');
    } else if (typeof conversionModeID !== 'number') {
      valid = false;
      setConversionError('Please Select Conversion Mode');
    }

    const payload = {
      name: campaignName,
      start_date: todayDate,
      end_date: endDate.toISOString().slice(0, 10),
      budget_amount: budgetValue,
      duration: parseInt(state?.videoTime?.split('Sec')[0]),
      daily_budget: budgetValue,
      campaign_video: state?.videoId,
      zip_value: zipArray.map(String),
      call_to_action_value: conversionMode,
      call_to_action: conversionModeID,
      campaign_time: showRangeValue,
      holiday: holidaySelectOption,
      categories_data: selectChannel,
      domain: subdomain,
    };

    if (valid) {
      localStorage.setItem('isReload', false);
      setButtonLoader(true);

      postCampaignVideo(payload).then(({ data, success, code, message }) => {
        if (success && code === 201) {
          setButtonLoader(false);
          const redirectUrl = `${baseUrl}/dashboard`;
          window.location.href = redirectUrl;
          // navigate('/dashboard', {
          //   state: {
          //     apiCall: true,
          //   },
          // });
          // getPaymentCalculations(data?.campaign_video, data?.id);
        } else {
          setButtonLoader(false);
          toast.error(toastMessage.campaignCreateError);
          console.log(message);
        }
      });
    } else {
      return false;
    }
  }

  // STRIPE UPDATE

  function updateSubscription() {
    const payload = {
      currency: 'usd',
      video_id: state?.promotedVideoId,
      campaign_id: state?.campaignId,
    };

    postStripeSubscription(payload).then(({ code, success, message }) => {
      if (code === 201 && success) {
        toast.success(toastMessage.subscriptionUpdate);
        retrieveCampaignVideo(`${state?.campaignId}/`).then(({ code, data, success, message }) => {
          if (success && code === 200) {
            toast.success(toastMessage.campaignEditSuccess);
            navigate('/dashboard');
          } else {
            console.log(message);
          }
        });
      } else {
        toast.error(toastMessage.subscriptionUpdateError);
      }
    });
  }

  // EDIT CAMPAIGN

  function editCampaign() {
    const todayDate = new Date().toISOString().slice(0, 10);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 3);
    let valid = true;

    if (campaignName?.length === 0) {
      valid = false;
      setCampaignError('Campaign Name is required');
    } else if (rangeValue[1] - rangeValue[0] === 0) {
      valid = false;
      setTimeSlotError('Time Slot should be at-least for 1 hour');
    }
    if (holidaySelectOption?.length === selectHolidayOption?.length) {
      valid = false;
      setHolidayError('At-least One Active day should be in the list');
    } else if (typeof conversionModeID !== 'number') {
      valid = false;
      setConversionError('Please Select Conversion Mode');
    }

    const payload = {
      name: campaignName,
      start_date: todayDate,
      end_date: endDate.toISOString().slice(0, 10),
      budget_amount: budgetValue,
      duration: parseInt(state?.videoTime?.split('Sec')[0]),
      daily_budget: budgetValue,
      campaign_video: editCampaignId,
      zip_value: zipValue,
      call_to_action_value: conversionMode,
      call_to_action: conversionModeID,
      status: state?.edit ? 1 : 2,
      campaign_time: showRangeValue,
      holiday: holidaySelectOption,
      categories_data: selectChannel,
    };

    if (valid) {
      setButtonLoader(true);

      editCampaignVideo(`${state?.campaignId}/`, payload).then(
        ({ code, data, success, message }) => {
          if (success && code === 200) {
            setButtonLoader(false);
            if (state?.hasStripeSubscription) {
              updateSubscription();
            } else {
              retrieveCampaignVideo(`${state?.campaignId}/`).then(
                ({ code, data, success, message }) => {
                  if (success && code === 200) {
                    navigate('/dashboard');
                  } else {
                    console.log(message);
                  }
                },
              );
            }
          } else {
            toast.error(toastMessage.campaignEditError);
            setButtonLoader(false);
            console.log(message);
          }
        },
      );
    } else {
      return false;
    }
  }

  // CHANGE THE HOLIDAY OPTION

  function changeHolidayOption(e) {
    setHolidayError();
    const data = [...e];
    setHolidayDisplayData(e);
    const filterData = data.map((data) => data?.value);
    setHolidaySelectOption(filterData);
  }

  // SELECT ALL CHANNEL CATEGORY CONDITION

  function channelCategoryChange(e) {
    const data = [...e];
    const dataParticular = [...selectChannelOptionParticular];
    const optionData = [...selectChannelOption];
    if (data.filter((data) => data?.label === 'Select All')?.length > 0) {
      const filterDataSelect = optionData
        .filter((data) => data?.label === 'Select All')
        .map((data) => data);
      setChannelDisplayData(filterDataSelect);
      setSelectChannel(null);
      setSelectChannelOption([]);
    } else {
      if (data?.length === 0) {
        setSelectChannel(null);
        setSelectChannelOption([...selectAll, ...dataParticular]);
        setChannelDisplayData(e);
      } else {
        setChannelDisplayData(e);
        const filterData = data.map((data) => data);
        setSelectChannel(filterData);
      }
    }
  }

  // CHANGE THE CONVERSION MODE

  function conversionModeChange(e) {
    setConversionModeDisplay(e);
    setConversionError();
    if (e?.value.includes('address')) {
      setConversionModeID(4);
    } else if (e?.value === 'email') {
      setConversionModeID(3);
    } else if (e?.value === 'mobile') {
      setConversionModeID(1);
    } else if (e?.value.includes('websites')) {
      setConversionModeID(5);
    } else if (e?.value === 'save_mobile') {
      setConversionModeID(2);
    }
    setConversionMode(e?.tempValue);
  }

  return (
    <>
      <Header />
      <Container>
        <PromoteOuterContainer>
          <VideoOuterContainer>
            <Video
              icon={
                type === 'synthesia' ? (
                  ''
                ) : type === 'Device' ? (
                  <UploadDeviceIcon />
                ) : type === 'Facebook' ? (
                  <FacebookMiniIcon />
                ) : (
                  <YoutubeMiniIcon />
                )
              }
              date={date}
              videoImage={videoImage}
              videoTime={videoTime}
              kind="description"
              thumbnailUrl={thumbnailUrl}
              videoUrl={videoUrl}
              pageScreen="promote"
            />
            <VideoDescriptionOuterContainer>
              <VideoDescriptionInnerContainer>
                <DateText>{date}</DateText>
                <TitleText>
                  Source: <DateText>{type === 'synthesia' ? 'Created' : type}</DateText>
                </TitleText>
              </VideoDescriptionInnerContainer>
            </VideoDescriptionOuterContainer>
          </VideoOuterContainer>
          <FormOuterContainer>
            <FormInnerContainer>
              <LabelText>Enter Campaign Name</LabelText>
              <Input
                type="text"
                kind="border-full-grey"
                onChange={(e) => {
                  setCampaignError('');
                  setCampaignName(e.target.value);
                }}
              />
              {campaignError?.length > 0 && (
                <TextRequiredErrorCondition>{campaignError}</TextRequiredErrorCondition>
              )}
            </FormInnerContainer>
            <FormInnerContainer>
              <LabelText
                style={{
                  marginBottom: '0px',
                }}
              >
                Enter zip codes to target
              </LabelText>
              <InputSuffixContainer>
                <Input
                  maxLength="5"
                  placeholder="XXXXX"
                  type="text"
                  ref={inputPromote}
                  pattern="[0-9]*"
                  kind="border-mini"
                  buttonLoaderZip={buttonLoaderZip}
                  value={zipCode}
                  onInput={(e) => {
                    if (e.target.validity.valid) {
                      setZipCode(e.target.value);
                    } else {
                      setZipCode(zipCode);
                    }
                    setZipError('');
                  }}
                />
                <Button
                  buttonText="+"
                  kind="mini"
                  disabled={(zipCode?.length === 0 || buttonLoaderZip) && true}
                  onClick={addLabel}
                />
              </InputSuffixContainer>
              {zipError && (
                <TextRequired
                  style={{
                    marginBottom: surrounding && zipValue?.length > 0 ? '10px' : '',
                  }}
                >
                  {zipError}
                </TextRequired>
              )}

              {labelNewArray?.length > 0 &&
                labelNewArray.map((data, index) => {
                  return <Label labelText={data} key={index} onClick={() => removeLabel(index)} />;
                })}
              {zipValue?.length > 0 && surrounding && (
                <>
                  <SurroundingContainer>
                    <SurroundingText
                      onClick={() => surroundingZipCodes()}
                      loader={surroundingLoader}
                    >
                      {'+ Add Surounding Zips'}
                    </SurroundingText>
                    {surroundingLoader && <div className="loaderSurrounding"></div>}
                  </SurroundingContainer>
                </>
              )}
            </FormInnerContainer>
            <FormInnerContainer>
              <LabelText>Select time slots ({rangeValue[1] - rangeValue[0]} hours)</LabelText>
              <RangeContainer>
                <ReactRange
                  rangeValue={rangeValue}
                  showRangeValue={showRangeValue}
                  onChange={(values) => {
                    setTimeSlotError();
                    setRangeValue(values);
                  }}
                />
              </RangeContainer>
              {timeSlotError?.length > 0 && (
                <TextRequiredErrorCondition>{timeSlotError}</TextRequiredErrorCondition>
              )}
            </FormInnerContainer>
            <FormInnerContainer>
              <LabelText>Business Holidays</LabelText>
              <MultiSelect
                options={selectHolidayOption}
                value={holidayDisplayData}
                isOptionDisabled={(option) => option.disabled}
                isMulti={true}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                onChange={(e) => changeHolidayOption(e)}
                styles={customStyles}
                isOpen={true}
              />
              <TooltipContainer>
                <ToolTipIconContainer>
                  <TooltipIcon />
                </ToolTipIconContainer>
                <ToolTipText>Your campaign will not run on these selected days</ToolTipText>
              </TooltipContainer>
              {holidayError?.length > 0 && (
                <TextRequiredErrorCondition>{holidayError}</TextRequiredErrorCondition>
              )}
            </FormInnerContainer>
            <FormInnerContainer>
              <LabelText>Select TV Channel Category</LabelText>
              <MultiSelect
                options={selectChannelOption}
                isMulti={true}
                value={channelDisplayData}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                onChange={(e) => {
                  channelCategoryChange(e);
                }}
                styles={customStyles}
              />
            </FormInnerContainer>
            <FormInnerContainer>
              {type === 'synthesia' ? (
                <LabelText>Your Conversion Mode</LabelText>
              ) : (
                <LabelText>Select Conversion Mode</LabelText>
              )}
              {type === 'synthesia' ? (
                <>
                  <Input
                    type="text"
                    readOnly
                    kind="border-full-grey"
                    value={`${
                      conversionModeDisplay?.callID === 1
                        ? 'Call Us'
                        : conversionModeDisplay?.callID === 2
                        ? 'Save'
                        : conversionModeDisplay?.callID === 3
                        ? 'Email'
                        : conversionModeDisplay?.callID === 4
                        ? 'Visit Us'
                        : conversionModeDisplay?.callID === 5
                        ? 'Website'
                        : ''
                    }: ${conversionModeDisplay?.tempValue}`}
                  />
                </>
              ) : (
                <ReactSelect
                  defaultValue={conversionModeDisplay}
                  value={conversionModeDisplay}
                  onChange={(e) => conversionModeChange(e)}
                  options={selectConversions}
                />
              )}
              {conversionError?.length > 0 && (
                <TextRequiredErrorCondition>{conversionError}</TextRequiredErrorCondition>
              )}
            </FormInnerContainer>
            <BudgetContainer>
              <Budget
                budgetValue={budgetValue}
                clickPlus={() => {
                  setBudgetValue(budgetValue + 5);
                  setClickPlus(clickPlus + 5);
                }}
                clickMinus={() => {
                  setBudgetValue(budgetValue - 5);
                  setClickPlus(clickPlus - 5);
                }}
                zipBudget={budgetValue - clickPlus}
              />
            </BudgetContainer>
          </FormOuterContainer>
        </PromoteOuterContainer>
      </Container>
      <ButtonContainer>
        <ButtonInnerContainer>
          <Button
            buttonText={<ClosePromoteIcon />}
            kind="mini-close"
            onClick={() => navigate('/dashboard?tab=videos')}
          />
          <Button
            buttonText={state?.edit ? 'Update Campaign' : 'Start Campaign'}
            onClick={() => {
              if (state?.edit) {
                editCampaign();
              } else {
                createCampaign();
              }
            }}
            buttonLoader={buttonLoader}
            disabled={(budgetValue < 5 && true) || buttonLoader}
            icon={
              <PromoteIconContainer>
                <PromoteIcon disabled={(budgetValue < 5 && true) || buttonLoader} />
              </PromoteIconContainer>
            }
          />
        </ButtonInnerContainer>
      </ButtonContainer>
    </>
  );
}
