import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" fill="none" viewBox="0 0 26 28">
      <path
        fill="#9B26A6"
        d="M24.574 16.01a4.958 4.958 0 00.738-2.637c0-2.32-2.003-4.482-4.535-4.482h-1.95a6.687 6.687 0 00.474-2.479c0-3.85-2.004-5.537-5.01-5.537-3.27 0-3.059 5.01-3.797 5.748C9.281 7.836 7.857 10.156 6.855 11H1.687A1.7 1.7 0 000 12.688v12.656c0 .949.738 1.687 1.688 1.687h3.375c.738 0 1.423-.527 1.582-1.213 2.373.053 4.007 2.057 9.386 2.057h1.213c4.06 0 5.854-2.057 5.906-5.01.739-.949 1.108-2.267.95-3.533.527-.95.685-2.11.474-3.322zm-3.27 2.847c.686 1.108.053 2.584-.738 3.059.422 2.531-.949 3.428-2.794 3.428h-2.004c-3.745 0-6.223-1.951-9.018-1.951V13.53h.527c1.53 0 3.586-3.691 5.01-5.115 1.477-1.477 1.002-4.008 2.004-5.01 2.479 0 2.479 1.74 2.479 3.006 0 2.057-1.477 3.006-1.477 5.01h5.484c1.108 0 1.951 1.002 2.004 2.004 0 .949-.685 1.951-1.213 1.951.739.791.897 2.426-.263 3.48zm-16.663 4.8a1.25 1.25 0 01-1.266 1.265c-.738 0-1.266-.527-1.266-1.266a1.25 1.25 0 011.266-1.265c.686 0 1.266.58 1.266 1.265z"
      ></path>
    </svg>
  );
}

export default Icon;
