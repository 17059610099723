import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';

import { BusinessCommon } from '../components';

import useAuthentication from '../helpers/authentication';

export default function GenerateScript() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { fileUploadBusiness, getBusiness, editBusiness } = useAuthentication();

  const businessName = state?.businessName;

  const [businessData] = useState(state?.businessData);

  const [businessDataGet, setBusinessDataGet] = useState({});
  const [buttonLoaderSave, setButtonLoaderSave] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [saveBusinessData, setSaveBusinessData] = useState({
    name: '',
    email: '',
    mobile: '',
    save: '',
    business_type: '',
    services: [],
    products: [],
    usps: [],
    websites: [],
    address_data: [],
    partner: '',
  });

  const [generateScriptError, setGenerateScriptError] = useState({
    products: '',
    usps: '',
    services: '',
    file: '',
    name: '',
    websites: '',
    address: '',
  });

  let radioButtonOptional;

  const radioButton = [
    { id: 1, label: 'Products', name: 'products' },
    { id: 2, label: 'Services', name: 'services' },
    { id: 3, label: 'Both', name: 'products-services' },
  ];
  const [radiosOptional] = useState(radioButton);
  const [radios, setRadios] = useState([]);
  const [clearBit, setClearBit] = useState(false);
  const [edit, setEdit] = useState(false);

  const [editIndex, setEditIndex] = useState();
  const [editAddressLabel, setEditAddressLabel] = useState();
  const [editAddressName, setEditAddressName] = useState();
  const [businessGetLoader, setBusinessGetLoader] = useState(false);

  // GET EXISTING BUSINESS DATA

  function getBusinessData() {
    setGenerateScriptError({
      products: '',
      usps: '',
      services: '',
      file: '',
      name: '',
      websites: '',
      address: '',
    });
    setBusinessGetLoader(true);
    getBusiness().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setBusinessGetLoader(false);
        setGenerateScriptError({
          products: '',
          usps: '',
          services: '',
          file: '',
          name: '',
          websites: '',
          address: '',
        });
        if (Object.keys(data).length > 0) {
          setEdit(true);
          setBusinessDataGet(data);
          setSelectedFile(
            data?.logo_name && data?.logo_name !== null ? data?.logo_name : data?.logo_url,
          );
          if (data?.logo_name && data?.logo_name !== null) {
            setClearBit(false);
            setFileId(data?.logo_id);
          } else {
            setClearBit(true);
          }
          const usps =
            data?.usp_business && data?.usp_business?.length > 0
              ? data?.usp_business.map((data) => data?.name)
              : [];
          const services =
            data?.service_business && data?.service_business?.length > 0
              ? data?.service_business.map((data) => data?.name)
              : [];
          const products =
            data?.product_business && data?.product_business?.length > 0
              ? data?.product_business.map((data) => data?.name)
              : [];

          let idAddress = 4;
          let indexAddress = 1;

          let address = [];
          if (
            (data?.address_data && data?.address_data?.length === 0) ||
            (data?.address_data &&
              data?.address_data?.length === 1 &&
              data?.address_data[0]?.address_value === '')
          ) {
            address.push({
              id: idAddress++,
              name: 'address',
              label: 'Visit Us',
              kind: 'primary',
              type: 'website',
              value: '',
              disableRadio: true,
            });
          } else {
            address =
              data?.address_data && data?.address_data?.length > 0
                ? data?.address_data.map((data) => {
                    return {
                      id: idAddress++,
                      // id: data?.address_id,
                      name: data?.address_key,
                      label:
                        data?.address_key === 'address' ? 'Visit Us' : `Address${indexAddress++}`,
                      kind: data?.address_key === 'address' ? 'primary' : 'secondary',
                      type: 'website',
                      value: data?.address_value,
                    };
                  })
                : [];

            setEditIndex(address[address.length - 1]?.id);
            if (address[address.length - 1]?.kind === 'secondary') {
              setEditAddressLabel(address[address.length - 1].label.split('Address')[1]);
              setEditAddressName(address[address.length - 1].name.split('address-')[1]);
            } else {
              setEditAddressLabel(0);
              setEditAddressName(0);
            }
          }

          let index = 1;
          let url = [];

          if (
            (data?.url_business && data?.url_business?.length === 0) ||
            (data?.url_business &&
              data?.url_business?.length === 1 &&
              data?.url_business[0]?.url_value === '')
          ) {
            url.push({
              id: idAddress++,
              name: 'websites',
              label: 'Website',
              kind: 'primary',
              type: 'website',
              value: '',
              disableRadio: true,
            });
          } else {
            url =
              data?.url_business && data?.url_business?.length > 0
                ? data?.url_business.map((data) => {
                    return {
                      id: idAddress++,
                      name: data?.url_key,
                      label: data?.url_key === 'websites' ? 'Website' : `URL${index++}`,
                      kind: data?.url_key === 'websites' ? 'primary' : 'secondary',
                      type: 'website',
                      value: data?.url_value,
                    };
                  })
                : [];
          }

          setSaveBusinessData({
            ...saveBusinessData,
            name: businessDataGet.name,
            partner: businessDataGet.partner,
            usps: usps,
            services: [...services],
            products: [...products],
            address_data: [...address],
            websites: [...url],
          });
          setRadioValue(
            data?.business_type === 1
              ? 'products'
              : data?.business_type === 2
              ? 'services'
              : 'products-services',
          );

          const tempCall =
            data?.url_business?.length > 0 &&
            data?.url_business
              .filter((data) => data?.is_call_to_action === true)
              .map((data) => data?.url_key);
          if (tempCall[0] === undefined) {
            setRadioValueCallOptional(
              data?.call_to_action_id === 1
                ? 'mobile'
                : data?.call_to_action_id === 2
                ? 'save'
                : data?.call_to_action_id === 3
                ? 'email'
                : data?.call_to_action_id === 4
                ? 'address'
                : 'websites',
            );
          } else {
            setRadioValueCallOptional(tempCall[0]);
          }

          setRadios([
            {
              id: 1,
              name: 'mobile',
              label: 'Call Us',
              value: data?.mobile || '',
              pattern: `[+\\s+0-9]*`,
              inputMode: 'tel',
              disableRadio: !data?.mobile || data?.mobile === null ? true : false,
            },
            {
              id: 2,
              name: 'save',
              label: 'Save',
              value: data?.save_mobile || '',
              pattern: `[+\\s+0-9]*`,
              inputMode: 'tel',
              disableRadio: !data?.save_mobile || data?.save_mobile === null ? true : false,
            },
            {
              id: 3,
              name: 'email',
              label: 'Email',
              value: data?.email || '',
              inputMode: 'email',
              disableRadio: !data?.email || data?.email === null ? true : false,
            },
            ...address,
            ...url,
          ]);
        }
      } else {
        if (message === 'Business not found' || code === 404) {
          setBusinessGetLoader(false);
          setBusinessDataGet({});
          setEdit(false);
          setSaveBusinessData({
            ...saveBusinessData,
            name: businessName,
            partner: businessData.partner,
          });

          const image = new Image();
          image.onload = function () {
            if (this.width > 0 && businessData?.logo_url && businessData?.logo_url !== null) {
              setSelectedFile(
                businessData?.logo_url && businessData?.logo_url !== null
                  ? businessData?.logo_url
                  : null,
              );
              setClearBit(true);
            }
          };
          image.onerror = function () {
            setSelectedFile(null);
            setClearBit(false);
          };
          image.src = businessData?.logo_url;

          setRadioValueCallOptional(
            businessData?.phone_number && businessData?.phone_number !== null
              ? 'mobile'
              : businessData?.email && businessData?.email !== null
              ? 'email'
              : businessData?.address && businessData?.address !== null
              ? 'address'
              : businessData?.website && businessData?.website !== null
              ? 'website'
              : null,
          );
          setRadios([
            {
              id: 1,
              name: 'mobile',
              label: 'Call Us',
              value: businessData?.phone_number || '',
              inputMode: 'tel',
              pattern: `[+\\s+0-9]*`,
              disableRadio:
                !businessData?.phone_number || businessData?.phone_number === null ? true : false,
            },
            {
              id: 2,
              name: 'save',
              label: 'Save',
              value: businessData?.phone_number || '',
              inputMode: 'tel',
              pattern: `[+\\s+0-9]*`,
              disableRadio:
                !businessData?.phone_number || businessData?.phone_number === null ? true : false,
            },
            {
              id: 3,
              name: 'email',
              label: 'Email',
              inputMode: 'email',
              value: businessData?.email || '',
              disableRadio: !businessData?.email || businessData?.email === null ? true : false,
            },
            {
              id: 4,
              name: 'address',
              label: 'Visit Us',
              kind: 'primary',
              type: 'address',
              value: businessData?.address || '',
              disableRadio: !businessData?.address || businessData?.address === null ? true : false,
            },
            {
              id: 5,
              name: 'websites',
              label: 'Website',
              kind: 'primary',
              type: 'website',
              value: businessData?.website,
              disableRadio: !businessData?.website || businessData?.website === null ? true : false,
            },
          ]);
        } else {
          console.log(message);
        }
      }
    });
  }

  useEffect(() => {
    getBusinessData();
  }, []);

  const [radioValue, setRadioValue] = useState('products');
  const [radioValueCallOptional, setRadioValueCallOptional] = useState('mobile');
  const [servicesArrayList, setServicesArrayList] = useState('');
  const [productsArrayList, setProductsArrayList] = useState('');
  const [uspsArrayList, setUspsArrayList] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileId, setFileId] = useState();

  const [addIndex, setAddIndex] = useState(4);
  const [increaseId, setIncreaseId] = useState(0.1);

  const [errorText, setErrorText] = useState('');

  // CHANGE BUSINESS DATA

  function handleSelectChange(e) {
    setRadioValue(e.target.value);
    if (!edit) {
      if (radioValue === 'products') {
        setSaveBusinessData({ ...saveBusinessData, services: [] });
      } else if (radioValue === 'services') {
        setSaveBusinessData({ ...saveBusinessData, products: [] });
      }
    }
  }

  function handleSelectOptionalChange(e) {
    setRadioValueCallOptional(e.target.value);
  }

  // UPDATE BUSINESS VALUE

  function updateBusinessValue(key, value) {
    const newValue = { ...saveBusinessData };
    newValue[key] = value;
    setSaveBusinessData(newValue);
  }

  const labelArray = [];
  function addServices(name, value, dynamic, inputRef) {
    inputRef.current.focus();
    if (saveBusinessData[name].find((data) => data.toLowerCase() === value.toLowerCase())) {
      setGenerateScriptError({ ...generateScriptError, [name]: `This value is Already Added` });
    } else {
      labelArray.push(value);
      setSaveBusinessData({ ...saveBusinessData, [name]: [...labelArray, ...dynamic] });
      if (name === 'services') {
        setServicesArrayList('');
      } else if (name === 'products') {
        setProductsArrayList('');
      } else if (name === 'usps') {
        setUspsArrayList('');
      }
    }
  }

  // REMOVE ZIP CODE

  function removeLabel(name, index, dynamic) {
    setSaveBusinessData({
      ...saveBusinessData,
      [name]: [...dynamic.slice(0, index), ...dynamic.slice(index + 1)],
    });
  }

  // EDIT BUSINESS

  const BASE_URL = process.env.REACT_APP_API_URL
    ? 'https://infy-ads.s3.us-east-2.amazonaws.com/'
    : 'https://infy-ads.s3.us-east-2.amazonaws.com/';

  function businessEdit(e) {
    e.preventDefault();
    let valid = true;
    const newErrors = { ...generateScriptError };

    const addressData = saveBusinessData.address_data.filter((data) =>
      data?.business_call_to_action.includes('address-'),
    );
    const nullWebsites = saveBusinessData.websites.filter((data) =>
      data?.business_call_to_action.includes('websites-'),
    );

    const nullWebsitesData = nullWebsites
      .filter((data) => data?.url === '' || data?.url === undefined)
      .map((data) => data);
    const nullAddressData = addressData
      .filter((data) => data?.address === '' || data?.address === undefined)
      .map((data) => data);

    if (radioValue !== 'services') {
      if (saveBusinessData?.products?.length < 3) {
        valid = false;
        newErrors['products'] = 'Please Enter Minimum 3 Products';
      }
    }
    if (radioValue !== 'products') {
      if (saveBusinessData?.services?.length < 2) {
        valid = false;
        newErrors['services'] = 'Please Enter Minimum 2 Services';
      }
    }
    if (saveBusinessData?.usps?.length < 2) {
      valid = false;
      newErrors['usps'] = 'Please Enter Minimum 2 Usps';
    }
    if (selectedFile === null) {
      valid = false;
      newErrors['file'] = 'Please Upload the File';
    }
    if (saveBusinessData?.name?.length < 1) {
      valid = false;
      newErrors['name'] = 'Please Enter Business Name';
    }
    if (saveBusinessData?.email?.length > 0 && !validator.isEmail(saveBusinessData.email)) {
      valid = false;
      newErrors['email'] = 'Please Enter Valid Email';
    }
    if (radioValueCallOptional === null || radioValueCallOptional === undefined) {
      valid = false;
      newErrors['radio'] = 'Please Select at-least One Call To Action Field';
    } else if (nullAddressData?.length > 0) {
      valid = false;
      newErrors['address'] = 'Address Value should not be blank. Please Enter Address Value';
    } else if (nullWebsitesData?.length > 0) {
      valid = false;
      newErrors['websites'] = 'Websites Value should not be blank. Please Enter Websites Value';
    }

    if (valid) {
      const subdomain = window.location.origin.split('.')[0].split('//')[1];
      setButtonLoaderSave(true);

      editBusiness({
        name: saveBusinessData.name,
        mobile: saveBusinessData.mobile.replace(/\s+/g, ''),
        save_mobile: saveBusinessData.save.replace(/\s+/g, ''),
        email: saveBusinessData.email,
        address_data: saveBusinessData?.address_data,
        websites: saveBusinessData?.websites,
        products: saveBusinessData.products,
        services: saveBusinessData.services,
        usps: saveBusinessData.usps,
        business_type: radioValue === 'products' ? 1 : radioValue === 'services' ? 2 : 3,
        logo: !clearBit ? fileId : null,
        logo_url: clearBit ? selectedFile : null,
        call_to_action:
          radioValueCallOptional === 'mobile'
            ? 1
            : radioValueCallOptional === 'save'
            ? 2
            : radioValueCallOptional === 'email'
            ? 3
            : radioValueCallOptional === 'address'
            ? 4
            : 5,
        partner: subdomain,
      }).then(({ code, success, message }) => {
        if (success && code === 200) {
          getBusiness().then(({ code, data, message, success }) => {
            setButtonLoaderSave(false);
            if (code === 200 && success) {
              {
                saveBusinessData.usps.map((value, index) => <p key={index}>{value}</p>);
              }
              navigate('/script', {
                state: {
                  business_usps: saveBusinessData.usps.join(', '),
                  business_logo: clearBit
                    ? selectedFile
                    : selectedFile.includes('https')
                    ? selectedFile
                    : `${BASE_URL}${selectedFile}`,
                  cta:
                    radioValueCallOptional === 'mobile' || radioValueCallOptional === 'save'
                      ? 'Call Us'
                      : radioValueCallOptional === 'email'
                      ? 'Mail Us'
                      : radioValueCallOptional === 'address'
                      ? 'Visit Us'
                      : 'Visit Our Website',
                  business_name: businessDataGet?.name || businessName,
                },
              });
            } else {
              console.log(message);
            }
          });
        } else {
          setButtonLoaderSave(false);
          setErrorText(message);
        }
      });
    } else {
      setGenerateScriptError(newErrors);
      return false;
    }
  }

  // UPLOAD FILE POST API

  function uploadFile(e) {
    setButtonLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append('file_name', e.target.files[0]);

    fileUploadBusiness(formData, 'multipart').then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setClearBit(false);
        setButtonLoader(false);
        setGenerateScriptError({ ...generateScriptError, file: '' });
        setSelectedFile(data?.key);
        setFileId(data?.id);
      } else {
        setButtonLoader(false);
        console.log(message);
      }
    });
  }

  function radioDataPush() {
    if (radios) {
      const addressData = [...radios];
      const dataArray = addressData
        .filter(
          (item) =>
            (item.label !== 'Website' && item.label !== 'Visit Us') || item.label.includes('URL'),
        )
        .map((data) => {
          return {
            [data.name]: data.value,
          };
        });

      const addressDataArray = addressData
        .filter(
          (item) =>
            (item.label !== 'Visit Us' && item.label !== 'Website') ||
            item.label.includes('Address'),
        )
        .map((data) => {
          return {
            [data.name]: data.value,
          };
        });

      const data = {};
      const temp = Object.assign(...dataArray, data);
      const tempAddress = Object.assign(...addressDataArray, data);
      const websites = [];
      const address = [];
      radios
        .filter((item) => item.label === 'Website' || item.label.includes('URL'))
        .map((data) => {
          return websites.push({
            url: data.value,
            business_call_to_action: data?.name,
            is_call_to_action: radioValueCallOptional === data?.name,
          });
        });

      addressData
        .filter((item) => item.label === 'Visit Us' || item.label.includes('Address'))
        .map((data) => {
          return address.push({
            address: data.value,
            business_call_to_action: data?.name,
            is_call_to_action: radioValueCallOptional === data?.name,
          });
        });
      setSaveBusinessData({
        ...saveBusinessData,
        websites: [...websites],
        address_data: [...address],
        ...temp,
        ...tempAddress,
      });
    }
  }

  useEffect(() => {
    radioDataPush();
  }, [radios, radioValueCallOptional]);

  // UPDATE THE WEBSITE VALUE

  const updateWebsites = (e, v) => {
    const newSites = [...radios];
    newSites.find((x) => x.name === e).value = v;
    if (v?.length > 0) {
      newSites.find((x) => x.name === e).disableRadio = false;
      if (e.includes('address')) {
        setGenerateScriptError({ ...generateScriptError, address: '' });
      } else if (e.includes('websites')) {
        setGenerateScriptError({ ...generateScriptError, websites: '' });
      }
    } else {
      if (v?.length === 0 && e === radioValueCallOptional) {
        setRadioValueCallOptional(null);
        const tempData = newSites.filter((data) => data.value !== '').map((data) => data);
        setRadioValueCallOptional(tempData[0]?.name);
        newSites.find((x) => x.name === e).disableRadio = true;
      } else {
        newSites.find((x) => x.name === e).disableRadio = true;
      }
    }
    setRadios(newSites);
  };

  const minusCall = (id, name) => {
    const tempRadio = [...radios];
    const filterData = tempRadio.filter((x) => x?.id !== id);
    const addressArray = filterData
      .filter((data) => data?.name.includes('address-'))
      .map((data) => data);
    const websitesArray = filterData
      .filter((data) => data?.name.includes('websites-'))
      .map((data) => data);

    if (addressArray?.length === 0 && name.includes('address-')) {
      setGenerateScriptError({ ...generateScriptError, address: '' });
    } else if (websitesArray?.length === 0 && name.includes('websites-')) {
      setGenerateScriptError({ ...generateScriptError, websites: '' });
    }

    if (addressArray?.length > 0) {
      if (addressArray.find((data) => data?.value === '')) {
      } else {
        setGenerateScriptError({ ...generateScriptError, address: '' });
      }
    } else if (websitesArray?.length > 0) {
      if (websitesArray.find((data) => data?.value === '')) {
      } else {
        setGenerateScriptError({ ...generateScriptError, websites: '' });
      }
    }
    setRadios(radios.filter((x) => x.id !== id));
    if (name.includes('address')) {
      setAddIndex(addIndex - 1);
      setIncreaseId(increaseId - 0.1);

      const tempRadios = [...radios];
      const tempData = tempRadios
        .filter((x) => x.id !== id)
        .find((name) => name?.name.includes('address-'));
      if (edit) {
        setEditIndex(editIndex - 1);
        if (tempData === undefined) {
          setEditAddressLabel(0);
        }
      }
    }
  };

  // ADD MULTIPLE WEBSITES

  const plusCall = (name) => {
    if (name.includes('address')) {
      const tempRadios = [...radios];
      tempRadios.splice(editIndex ? editIndex : addIndex, 0, {
        id: Math.random(),
        name: edit
          ? `address-${parseInt(editAddressName) + 1}`
          : `address-${
              tempRadios[addIndex - 1].label.includes('Address')
                ? parseInt(tempRadios[addIndex - 1].label.split('Address')[1]) + 1
                : 1
            }`,
        label: `${
          edit
            ? `Address${parseInt(editAddressLabel) + 1}`
            : tempRadios[addIndex - 1].label.includes('Address')
            ? `Address${parseInt(tempRadios[addIndex - 1].label.split('Address')[1]) + 1}`
            : 'Address1'
        }`,
        kind: 'secondary',
        type: 'website',
        disableRadio: true,
        value: '',
      });
      if (edit) {
        setEditIndex(editIndex + 1);
        setEditAddressLabel(parseInt(editAddressLabel) + 1);
        setEditAddressName(parseInt(editAddressName) + 1);
      }
      setRadios([...tempRadios]);
      setAddIndex(addIndex + 1);
      setIncreaseId(increaseId + 0.1);
    } else {
      setRadios([
        ...radios,
        {
          id: radios[radios.length - 1].id + 1,
          name: `websites-${radios[radios.length - 1].id + 1}`,
          label: `${
            radios[radios.length - 1].label.includes('URL')
              ? `URL${parseInt(radios[radios.length - 1].label.split('URL')[1]) + 1}`
              : 'URL1'
          }`,
          kind: 'secondary',
          type: 'website',
          disableRadio: true,
          value: '',
        },
      ]);
    }
  };

  // MINIMUM REQUIREMENT OF PRODUCTS, SERVICES AND USPS

  return (
    <>
      <BusinessCommon
        businessDataGet={businessDataGet}
        businessData={businessData}
        businessName={businessName}
        onChangeFile={(e) => uploadFile(e)}
        onChangeBusiness={(e) => {
          setGenerateScriptError({ ...generateScriptError, name: '' });
          updateBusinessValue(e.target.name, e.target.value);
        }}
        onChangeServices={(e) => {
          setServicesArrayList(e.target.value);
          setGenerateScriptError({ ...generateScriptError, services: '' });
        }}
        onChangeProducts={(e) => {
          setGenerateScriptError({ ...generateScriptError, products: '' });
          setProductsArrayList(e.target.value);
        }}
        onChangeUsps={(e) => {
          setUspsArrayList(e.target.value);
          setGenerateScriptError({ ...generateScriptError, usps: '' });
        }}
        onClickRemoveLabel={(serviceName, index, data) => {
          removeLabel(serviceName, index, data);
        }}
        addServices={(serviceName, index, data, ref) => {
          addServices(serviceName, index, data, ref);
        }}
        onChangeMarketing={(e) => {
          updateBusinessValue(e.target.name, e.target.value);
        }}
        handleSelectOptionalChange={(e) => handleSelectOptionalChange(e)}
        updateWebsites={(e, v) => updateWebsites(e, v)}
        minusCall={(id, name) => minusCall(id, name)}
        plusCall={(name) => plusCall(name)}
        businessEdit={(e) => businessEdit(e)}
        onRadioChange={(e) => handleSelectChange(e)}
        radios={radios}
        generateScriptError={generateScriptError}
        selectedFile={selectedFile}
        clearBit={clearBit}
        buttonLoader={buttonLoader}
        radioButton={radioButton}
        radioValue={radioValue}
        radiosOptional={radiosOptional}
        saveBusinessData={saveBusinessData}
        servicesArrayList={servicesArrayList}
        productsArrayList={productsArrayList}
        uspsArrayList={uspsArrayList}
        businessGetLoader={businessGetLoader}
        radioButtonOptional={radioButtonOptional}
        radioValueCallOptional={radioValueCallOptional}
        errorText={errorText}
        buttonLoaderSave={buttonLoaderSave}
        edit={edit}
        businessPage="business-script"
      />
    </>
  );
}
