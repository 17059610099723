import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
      <path
        stroke="#A2A2A2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 11A5 5 0 106 1a5 5 0 000 10zM6 3.781v2.222M6 8.219h.01"
      ></path>
    </svg>
  );
}

export default Icon;
