import React from 'react';

function Icon({ buttonLoader }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      style={{
        verticalAlign: 'middle',
      }}
    >
      <path
        stroke={buttonLoader ? '#787878' : '#000'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.51 2.784a4.667 4.667 0 105.239-1.263m2.917-.291H7.749v2.916"
      ></path>
    </svg>
  );
}

export default Icon;
