import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="none" viewBox="0 0 26 24">
      <path
        fill="#9B26A6"
        d="M8.908 14.902c.02.332.038.569.048.81.034.984.386 1.423 1.524 1.389 3.569-.092 7.143-.024 10.716-.039 1.018-.005 1.877.097 1.857 1.438-.005.236.241.482.376.718.71 1.206.555 2.542-.41 3.526-.877.897-2.305 1.085-3.443.463-1.138-.627-1.66-1.833-1.37-3.169.049-.232.102-.463.16-.752h-6.314c.053.27.097.506.145.737.27 1.288-.222 2.513-1.25 3.12-1.137.671-2.473.55-3.462-.313-.926-.81-1.273-2.102-.637-3.274.415-.762.353-1.404.203-2.17-.916-4.602-1.813-9.203-2.672-13.818-.14-.724-.366-1.056-1.148-.97-.675.073-1.365-.01-2.045.025C.41 2.656 0 2.415 0 1.538 0 .674.376.414 1.172.433c1.162.034 2.33.048 3.492-.005C5.686.385 6.22.915 6.24 1.827c.029 1.331.641 1.558 1.861 1.538 5.378-.077 10.755-.033 16.138-.033 1.64 0 1.987.443 1.635 2.059-.58 2.662-1.197 5.32-1.717 7.991-.217 1.11-.733 1.544-1.872 1.534-4.056-.048-8.107-.02-12.163-.02-.395.006-.79.006-1.215.006zm14.714-9.395c-5.41 0-10.774.005-16.137-.005-.627 0-.482.38-.415.723.386 1.925.806 3.84 1.158 5.768.11.598.366.762.955.758 4.012-.024 8.02-.024 12.033 0 .622.005.902-.14 1.032-.81.4-2.123.892-4.225 1.374-6.434zM9.496 19.628c-.728.01-1.114.362-1.124 1.023-.014.655.328 1.065 1.013 1.075.661.005 1.061-.323 1.076-1.013.02-.685-.362-1.017-.965-1.085zm12.54 1.09c-.015-.685-.362-1.056-1.033-1.08-.68-.02-1.056.357-1.07 1.017-.015.656.333 1.066 1.018 1.071.67.005 1.046-.338 1.085-1.008z"
      ></path>
    </svg>
  );
}

export default Icon;
