import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 17">
      <path
        stroke="#9B26A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.969 4.412C13.35 5.225 14 6.688 14 8.313c0 2.032-2.031 3.657-4.063 3.657H7.825l-.487 2.925a.813.813 0 01-.813.65H4.331a.405.405 0 01-.406-.488l.163-1.137m1.787-4.794h2.031c2.032 0 4.063-2.031 4.063-4.063C11.969 2.626 10.425 1 7.906 1H3.438c-.407 0-.813.406-.813.813L1 13.187c0 .407.406.813.813.813h2.275l.974-4.063c.082-.487.325-.812.813-.812z"
      ></path>
    </svg>
  );
}

export default Icon;
