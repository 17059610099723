import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import useAuthentication from '../helpers/authentication';

import Success from '../assets/payment-assets/success.json';

import { Header } from '../components';

const Container = styled.div`
  padding: 16px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const LottieAnimation = styled(Lottie)``;

const LottieContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SuccessText = styled.h3`
  font-size: ${(props) => props.theme.devices.sm.h3};
  text-align: center;
  font-weight: 400;
  margin: 0px 25px 0px 25px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
    line-height: 1rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 2rem;
  }
`;

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  const { checkoutSession } = useAuthentication();

  // STRIPE SUBSCRIPTION

  function stripeSubscription() {
    const payload = {
      amount: parseInt(localStorage.getItem('pay-amount')),
      currency: 'usd',
      video_id: parseInt(location?.search.split('?')[1].split('&')[0].split('=')[1]),
      interval: 'month',
      campaign_id: parseInt(location?.search.split('?')[1].split('&')[1].split('=')[1]),
    };

    checkoutSession(payload).then(({ code, success, message }) => {
      if (code === 202) {
        setTimeout(() => {
          stripeSubscription();
        }, 2000);
      } else if (code === 200 && success) {
        setTimeout(() => {
          navigate('/dashboard');
        }, 5000);
      } else if (code === 500) {
        navigate('/payment-failure');
      } else {
        console.log(message);
      }
    });
  }

  useEffect(() => {
    if (location?.search) {
      stripeSubscription();
    } else {
      navigate('/dashboard');
    }
  }, []);

  return (
    <>
      <Header />
      <Container>
        <LottieContainer>
          <LottieAnimation
            options={{
              loop: true,
              autoplay: true,
              animationData: Success,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={250}
            width={250}
          />
          <SuccessText>You will be redirected to the Dashboard page Soon!</SuccessText>
        </LottieContainer>
      </Container>
    </>
  );
}
