import { Col, Row, setConfiguration } from 'react-grid-system';
import styled from 'styled-components';
import { iconPath } from '../assets/icons';
import BudgetCampaign from './budget-campaign';

const ControlButtonInnerContainer = styled.button`
  display: flex;
  border-radius: 4px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.border ? '6px 0px' : '7px 0px')};
  width: ${(props) => (props.kind === 'rePromote' ? '110px' : ' 100%')};
  cursor: pointer;
  background: ${(props) => (props.disabled ? '#e7e7e7' : props.backgroundColor)};
  border: ${(props) => (props.border ? `1px solid #D9DDE3` : 'none')};
  outline: none;
  @media ${(props) => props.theme.devices.md} {
    padding: 6px 0px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 10px 0px;
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: 8px 0px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 12px 0px;
  }
`;

const ControlIconContainer = styled.div`
  width: 12px;
  height: 15px;
  font-size: 22px;
  line-height: 11px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.primary};

  /* @media ${(props) => props.theme.devices.lg} {
    width: 19px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 10px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    width: 14px;
  } */
`;

const ButtonText = styled.h4`
  margin: 0px 0px 0px 4px;
  color: ${(props) => (props.buttonLoader ? '#787878' : props.colorChange)};
  font-size: 0.75rem;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.t};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.4rem;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.875rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 1.125rem;
  }
`;

const TopPromotedContainer = styled.div`
  display: flex;
  column-gap: 6px;
`;

const ControlButtonOuter = styled.div`
  width: 100%;
`;

export default function ControlButtons({
  data,
  rePromoteLoader,
  stopPromoteLoader,
  rePromotedVideo,
  stopPromoteVideos,
  pickerShowIncrease,
  pickerKind,
  pickerShowStop,
}) {
  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  const { RePromoteIcon, StopPromoteIcon, IncreasePromoteIcon } = iconPath;

  // COMMON CONTROL BUTTON COMPONENT

  function ControlButton({
    keyId,
    text,
    icon,
    color,
    backgroundColor,
    disableButton,
    border,
    kind,
    onClick,
    buttonLoader,
    name,
  }) {
    return (
      <>
        <ControlButtonOuter>
          <ControlButtonInnerContainer
            key={keyId}
            name={name}
            id={keyId}
            backgroundColor={backgroundColor}
            border={border}
            onClick={onClick}
            kind={kind}
            disabled={buttonLoader || disableButton}
          >
            {buttonLoader && <div className="loaderStopPromote" key={keyId} name={name}></div>}
            <ControlIconContainer>{icon}</ControlIconContainer>
            <ButtonText buttonLoader={buttonLoader || disableButton} colorChange={color}>
              {text}
            </ButtonText>
          </ControlButtonInnerContainer>
        </ControlButtonOuter>
      </>
    );
  }

  return (
    <>
      <Row
        style={{
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Col xs={12} sm={12} md={12}>
          <TopPromotedContainer>
            {!rePromoteLoader && (
              <BudgetCampaign xs={4} sm={4} md={4} budgetAmount={data?.budget_amount} />
            )}
            {data?.campaign_status_id === 2 &&
            data?.campaign_status_name === 'offline' &&
            data?.is_campaign_verified === true ? (
              <>
                <ControlButton
                  kind="rePromote"
                  keyId={data?.id}
                  name={data?.id}
                  text="Re-Promote"
                  icon={
                    <RePromoteIcon
                      buttonLoader={
                        data?.campaign_id === rePromoteLoader?.videoId && rePromoteLoader?.flag
                      }
                    />
                  }
                  buttonLoader={
                    data?.campaign_id === rePromoteLoader?.videoId && rePromoteLoader?.flag
                  }
                  color={(props) => props.theme.colors.dark}
                  backgroundColor="#F4F4F4"
                  onClick={() => rePromotedVideo(data)}
                />
              </>
            ) : (
              <>
                <ControlButton
                  keyId={data?.id}
                  name={data?.id}
                  text="Increase"
                  icon={'+'}
                  color={(props) => props.theme.colors.primary}
                  backgroundColor={(props) => props.theme.colors.light}
                  border="#9B26A6"
                  onClick={() => {
                    pickerShowIncrease(data?.budget_amount);
                    pickerKind('Increase');
                  }}
                />
                <ControlButton
                  text="Stop"
                  keyId={data?.id}
                  name={data?.id}
                  disableButton={
                    data?.campaign_status_id === 2 &&
                    data?.campaign_status_name === 'offline' &&
                    data?.is_campaign_verified === false
                  }
                  icon={
                    <StopPromoteIcon
                      buttonLoader={
                        (stopPromoteLoader?.videoId === data?.campaign_id &&
                          stopPromoteLoader?.flag) ||
                        (data?.campaign_status_id === 2 &&
                          data?.campaign_status_name === 'offline' &&
                          data?.is_campaign_verified === false)
                      }
                    />
                  }
                  color="#9B26A6"
                  buttonLoader={
                    stopPromoteLoader?.videoId === data?.campaign_id && stopPromoteLoader?.flag
                  }
                  backgroundColor="#E6D8E4"
                  onClick={() => {
                    pickerShowStop(data);
                    pickerKind('Stop');
                  }}
                />
                {/* <ControlButton
                  text="Stop"
                  keyId={data?.id}
                  name={data?.id}
                  disableButton={
                    data?.campaign_status_id === 2 &&
                    data?.campaign_status_name === 'offline' &&
                    data?.is_campaign_verified === false
                  }
                  icon={
                    <StopPromoteIcon
                      buttonLoader={
                        (stopPromoteLoader?.videoId === data?.campaign_id &&
                          stopPromoteLoader?.flag) ||
                        (data?.campaign_status_id === 2 &&
                          data?.campaign_status_name === 'offline' &&
                          data?.is_campaign_verified === false)
                      }
                    />
                  }
                  color="#9B26A6"
                  buttonLoader={
                    stopPromoteLoader?.videoId === data?.campaign_id && stopPromoteLoader?.flag
                  }
                  backgroundColor="#E6D8E4"
                  onClick={(e) => {
                    stopPromoteVideos(data);
                  }}
                /> */}
              </>
            )}
          </TopPromotedContainer>
        </Col>
      </Row>
    </>
  );
}
