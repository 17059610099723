import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Button, Header } from '../components';
import { toast } from 'react-toastify';
import RadioButton from '../components/radio-with-image';
import LogoPlaceholde from '../assets/images/logo-placeholder-image.png';
import useAuthentication from '../helpers/authentication';

const Container = styled.div`
  padding: 24px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 60px 40px;
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: 60px 340px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 60px 500px;
  }
`;

const TitleContainer = styled.div`
  //margin: 64px 0;
`;

const Title = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sm.h1};
  font-weight: 500;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h1};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h1};
  }
`;

const SelectedBusinessRow = styled.div`
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 30px;
  display: grid;
  gap: 10px;
  @media ${(props) => props.theme.devices.md} {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
`;

const StepTitle = styled.h3`
  font-size: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const StepTitleCount = styled.span`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.light};
  width: 21px;
  height: 21px;
  font-size: 16px;
  padding: 3px;
  border-radius: 100%;
`;

const TitleSubLine = styled.p`
  color: #8a94a6;
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 22px;
`;

// Define the loading animation
const skeletonAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

// Styled component for the rectangle skeleton
const SkeletonRectangle = styled.div`
  height: 90px;
  width: 100%;
  background-color: #f5f5f5;
  background-image: linear-gradient(90deg, #f5f5f5, #e8e8e8, #f5f5f5);
  background-size: 200px 100%;
  animation: ${skeletonAnimation} 1.5s infinite;
`;

const SelectBusiness = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { googleBusiness } = useAuthentication();

  const [selectedOption, setSelectedOption] = useState('');
  const [businessData, setBusinessData] = useState();
  const [filterData, setFilterData] = useState({});
  const [skeleton, setSkeleton] = useState(true);

  const handleOptionChange = (event, index) => {
    setSelectedOption(event.target.value);
    businessData.map((el, i) => {
      if (index === i) {
        setFilterData(el);
      }
    });
  };

  const businessSelect = () => {
    navigate('/business', {
      state: { businessData: filterData, businessName: filterData?.name },
    });
  };

  function businessSelectApi() {
    setSkeleton(true);
    googleBusiness()
      .then((data) => {
        setBusinessData(data?.data);
        setSkeleton(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  useEffect(() => {
    businessSelectApi();
  }, []);

  return (
    <>
      <Header kind="select-business" />
      <Container>
        <TitleContainer>
          <StepTitle>
            <StepTitleCount>1</StepTitleCount>
            Select Business
          </StepTitle>
          <TitleSubLine>We are using Google Business API to find your business name</TitleSubLine>
          {skeleton ? (
            <SelectedBusinessRow>
              <SkeletonRectangle />
              <SkeletonRectangle />
            </SelectedBusinessRow>
          ) : (
            <SelectedBusinessRow>
              {businessData?.map((item, index) => (
                <RadioButton
                  key={index}
                  name={item?.name}
                  image={item?.logo_url ?? LogoPlaceholde}
                  value={`option${index}`}
                  checked={selectedOption === `option${index}`}
                  onChange={(e) => handleOptionChange(e, index)}
                />
              ))}
            </SelectedBusinessRow>
          )}
          <Button
            onClick={() => businessSelect()}
            buttonText="Next"
            // buttonLoader={buttonLoader}
            disabled={!selectedOption}
          />
        </TitleContainer>
      </Container>
    </>
  );
};

export default SelectBusiness;
