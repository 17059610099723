import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import './styles/global.scss';
import { Provider } from './helpers/authentication';
import App from './app';

const theme = {
  fonts: {
    sm: {
      h1: '2.25rem',
      h2: '1.75rem',
      h3: '1.5rem',
      h4: '1.25rem',
      h5: '1rem',
      p: '0.875rem',
      t: '0.75rem',
    },
    md: {
      h1: '3.75rem',
      h2: '3.125rem',
      h3: '2.5rem',
      h4: '2.18rem',
      h5: '1.56rem',
      p: '1.25rem',
      t: '0.93rem',
    },
    lg: {
      h1: '5rem',
      h2: '3.75rem',
      h3: '3.125rem',
      h4: '2.5rem',
      h5: '1.87rem',
      p: '1.56rem',
      t: '1.25rem',
    },
    xl: {
      h1: '6.25rem',
      h2: '4.68rem',
      h3: '3.125rem',
      h4: '2.5rem',
      h5: '1.87rem',
      p: '1.56rem',
      t: '1.25rem',
    },
    xxl: {
      h1: '9.06rem',
      h2: '7.81rem',
      h3: '4.68rem',
      h4: '3.125rem',
      h5: '2.18rem',
      p: '1.56rem',
      t: '1.25rem',
    },
  },
  colors: {
    primary: '#9B26A6',
    secondary: '#666666',
    tertiary: '#E6D8E4',
    quaternary: '#a2a2a2',
    gray: '#F5F7FA',
    lightGray: '#8A94A6',
    dark: '#000000',
    light: '#ffffff',
    tt1: '#12141D',
    tt2: '#323B4B',
  },
  devices: {
    sm: `(min-width: 320px)`,
    md: `(min-width: 768px)`,
    lg: `(min-width: 1024px)`,
    xl: `(min-width: 1280px)`,
    xxl: `(min-width: 1536px)`,
    xxxl: `(min-width: 2048px)`,
  },
};

render(
  <React.StrictMode>
    <Provider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('infy-ads'),
);
