import { getTrackBackground, Range } from 'react-range';
import styled from 'styled-components';

const TrackContainer = styled.div`
  height: 6px;
  display: flex;
  width: 100%;
`;

const TrackLine = styled.div`
  height: 6px;
  width: 100%;
  border-radius: 1.87rem;
  background-color: ${(props) => props.theme.colors.primary};
  align-self: center;
  background: ${(props) =>
    getTrackBackground({
      values: props.rangeValue,
      colors: [
        `${props.secondaryColor ? props.secondaryColor : props.theme.colors.tertiary}`,
        `${props.theme.colors.primary}`,
        `${props.secondaryColor ? props.secondaryColor : props.theme.colors.tertiary}`,
      ],
      min: 0,
      max: 24,
    })};
  /* @media ${(props) => props.theme.devices.md} {
    height: 7px;
  }
  @media ${(props) => props.theme.devices.lg} {
    height: 9px;
  } */
`;

const ThumbContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e7e7;
  /* @media ${(props) => props.theme.devices.md} {
    height: 40px;
    width: 40px;
  }
  @media ${(props) => props.theme.devices.lg} {
    height: 48px;
    width: 48px;
  } */
`;

const SmallThumb = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  /* @media ${(props) => props.theme.devices.md} {
    height: 16px;
    width: 16px;
  }
  @media ${(props) => props.theme.devices.lg} {
    height: 18px;
    width: 18px;
  } */
`;

const RangeText = styled.div`
  position: absolute;
  bottom: -20px;
  width: max-content;
  color: #787878;
  font-weight: bold;
  font-size: 0.87rem;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: 1rem;
    bottom: -22px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.125rem;
    bottom: -26px;
  } */
`;

export default function ReactRange(props) {
  const { rangeValue, secondaryColor, showRangeValue } = props;
  return (
    <>
      <Range
        values={rangeValue}
        step={1}
        min={0}
        max={24}
        {...props}
        renderTrack={({ props, children }) => (
          <TrackContainer
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <TrackLine ref={props.ref} rangeValue={rangeValue} secondaryColor={secondaryColor}>
              {children}
            </TrackLine>
          </TrackContainer>
        )}
        renderThumb={({ props, index }) => (
          <ThumbContainer
            {...props}
            style={{
              ...props.style,
            }}
          >
            <SmallThumb />
            <RangeText>{showRangeValue[index]}</RangeText>
          </ThumbContainer>
        )}
      />
    </>
  );
}
