import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
      <rect width="21.677" height="21.677" x="0.902" y="0.506" fill="#F40B3D" rx="10.839"></rect>
      <path
        fill="#fff"
        d="M15.82 9.297a1.063 1.063 0 00-.75-.766c-.672-.187-3.328-.187-3.328-.187s-2.672 0-3.344.187c-.359.094-.656.39-.75.766-.187.656-.187 2.062-.187 2.062s0 1.391.187 2.063c.094.375.391.656.75.75.672.172 3.344.172 3.344.172s2.656 0 3.328-.172c.36-.094.657-.375.75-.75.188-.672.188-2.063.188-2.063s0-1.406-.188-2.062zm-4.953 3.328v-2.531l2.219 1.265-2.219 1.266z"
      ></path>
    </svg>
  );
}

export default Icon;
