import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../assets/images/arrow.svg';

const AccordionContainer = styled.div`
  margin-top: 40px;
`;

const AccordionItem = styled.div`
  margin-bottom: 15px;
  border: 1px solid #d9dde3;
  border-radius: 8px;
`;

const AccordionHeader = styled.div`
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled.span`
  margin-left: auto;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

const AccordionContent = styled.div`
  padding: 0px 20px 20px;
  color: #4e5d78;
  font-size: 0.875rem;
  line-height: 22px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <AccordionContainer>
        {items.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => handleClick(index)}>
              {item.title}
              <ArrowIcon isOpen={activeIndex === index}>
                <Arrow />
              </ArrowIcon>
            </AccordionHeader>
            <AccordionContent isOpen={activeIndex === index}>{item.content}</AccordionContent>
          </AccordionItem>
        ))}
      </AccordionContainer>
    </>
  );
};

export default Accordion;
