import React from 'react';
import ContentLoader from 'react-content-loader';

function RenderSkeleton({ kind }) {
  return (
    <>
      <ContentLoader
        width="100%"
        height="100%"
        viewBox="0 0 450 270"
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
      {kind !== 'description' && (
        <ContentLoader
          width="100%"
          height="100%"
          viewBox="0 0 450 40"
          backgroundColor="#f0f0f0"
          foregroundColor="#dedede"
        >
          <rect x="0" y="10" rx="4" ry="4" width="150" height="20" />
          <rect x="347" y="10" rx="3" ry="3" width="100px" height="40" />
        </ContentLoader>
      )}
    </>
  );
}

export const Skeleton = React.memo(RenderSkeleton);
