import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useAuthentication from '../helpers/authentication';

import { Button, Header, Search } from '../components';

const Container = styled.div`
  padding: 24px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 60px 40px;
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: 60px 340px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 60px 500px;
  }
`;

const TitleContainer = styled.div`
  //margin: 64px 0;
`;

const Title = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sm.h1};
  font-weight: 500;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h1};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h1};
  }
`;

const FieldContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
`;

const TextRequiredError = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.t};
  font-weight: 400;
  color: #e40606;
  margin: 0px 0px 10px 0px;
  text-align: center;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;

const StepTitle = styled.h3`
  font-size: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const StepTitleCount = styled.span`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.light};
  width: 21px;
  height: 21px;
  font-size: 16px;
  padding: 3px;
  border-radius: 100%;
`;

const TitleSubLine = styled.p`
  color: #8a94a6;
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 22px;
`;

export default function SearchBusiness() {
  const navigate = useNavigate();

  const { detailBusiness } = useAuthentication();

  const [errorBusiness, setErrorBusiness] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [keyword, setKeyWord] = useState('');
  const [clickHandle, setClickHandle] = useState();
  const [searchIndex, setSearchIndex] = useState();

  useEffect(() => {
    if (localStorage.getItem('business') === 'true') {
      navigate('/dashboard');
    }
  }, []);

  // BUSINESS SEARCH DATA PASS TO BUSINESS PAGE

  function businessSearch() {
    setButtonLoader(true);
    const tempSearchData = [...searchData];
    detailBusiness(`?place_id=${tempSearchData[searchIndex]?.place_id}`).then(
      ({ code, data, success, message }) => {
        if (code === 200 && success) {
          setButtonLoader(false);
          navigate('/business', {
            state: { businessData: data[0], businessName: tempSearchData[searchIndex]?.name },
          });
        } else {
          setErrorBusiness('No Business Found');
          setButtonLoader(false);
        }
      },
    );
  }

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'https://sbadsapp.infy.tv/api/v1';

  return (
    <>
      <Header kind="search-business" />
      <Container>
        <TitleContainer>
          <StepTitle>
            <StepTitleCount>1</StepTitleCount>
            Find your Business
          </StepTitle>
          <TitleSubLine>We are using Google Business API to find your business name</TitleSubLine>
          <FieldContainer>
            <Search
              apiUrl={`${url}/business-search/?keyword=`}
              searchDataKey={(keyword) => setKeyWord(keyword)}
              clickHandler={(click) => setClickHandle(click)}
              searchDataArray={(searchData) => setSearchData(searchData)}
              searchIndex={(index) => setSearchIndex(index)}
            />
            {errorBusiness && <TextRequiredError>{errorBusiness}</TextRequiredError>}
            <Button
              onClick={() => businessSearch()}
              buttonText="Next"
              buttonLoader={buttonLoader}
              disabled={buttonLoader || !clickHandle}
            />
          </FieldContainer>
        </TitleContainer>
      </Container>
    </>
  );
}
