import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: #9b26a6;
`;

const ContainerBox = styled.div`
  padding: 50px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
`;

export default function NotMatch() {
  return (
    <>
      <Container>
        <ContainerBox>
          <h1>Invalid URL</h1>
        </ContainerBox>
      </Container>
    </>
  );
}
