import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" fill="none" viewBox="0 0 26 27">
      <path
        fill="#9B26A6"
        d="M25.086 8.629c-.254-.203-5.688-4.57-6.855-5.535C17.113 2.23 15.183.25 13 .25c-2.234 0-4.113 1.93-5.281 2.844C6.5 4.109 1.066 8.477.863 8.629.305 9.086 0 9.746 0 10.508v13.305a2.406 2.406 0 002.438 2.437h21.125A2.438 2.438 0 0026 23.812V10.508c0-.762-.355-1.422-.914-1.88zm-1.523 14.879c0 .203-.153.305-.305.305H2.742c-.203 0-.304-.102-.304-.305V10.66c0-.101 0-.203.101-.254.813-.66 5.535-4.418 6.703-5.383.914-.761 2.54-2.335 3.758-2.335 1.168 0 2.793 1.624 3.707 2.335 1.168.965 5.89 4.723 6.703 5.383a.28.28 0 01.152.254v12.848zm-1.625-9.496l-.813-.965c-.203-.254-.559-.305-.863-.05-1.422 1.167-2.946 2.386-3.555 2.843-.914.762-2.539 2.285-3.707 2.285-1.219 0-2.844-1.574-3.758-2.285-.61-.457-2.133-1.676-3.555-2.844-.304-.254-.66-.203-.863.05l-.812.966a.634.634 0 00.101.863A386.272 386.272 0 017.72 17.77c1.117.863 3.047 2.793 5.281 2.793 2.184 0 4.063-1.88 5.23-2.793.56-.508 2.133-1.727 3.606-2.895a.634.634 0 00.102-.863z"
      ></path>
    </svg>
  );
}

export default Icon;
