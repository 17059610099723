import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';

import useAuthentication from '../helpers/authentication';
import consumerData from '../helpers/authentication';

import { Button } from '.';
import { toastMessage } from './toast';
import { iconPath } from '../assets/icons';

const HeaderWrap = styled.div`
  border-bottom: 1px solid #d9dde3;
  padding: 24px 17px;
  @media ${(props) => props.theme.devices.md} {
    padding: 24px 0px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
`;

const LogoContainer = styled.a`
  background: none;
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;
  max-width: 150px;
  /* max-width: 117px; 
  max-height: 50px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  /* overflow: hidden; */
  object-fit: contain;
  /* @media ${(props) => props.theme.devices.md} {
    width: 170px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 117px;
  } */
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoutContainer = styled.button`
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 8px;
  cursor: pointer;
  background: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #d9dde3;
`;

const SettingIconContainer = styled.button`
  background: none;
  border-radius: 5px;
  border: 1px solid #d9dde3;
  outline: none;
  padding: 8px;
  cursor: pointer;
  width: 36px;
  height: 36px;
`;

// Animation keyframes for the loading effect
const loadingAnimation = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
`;

// Styled component for the logo skeleton
const LogoSkeleton = styled.div`
  width: 150px;
  height: 150px;
  background-color: #f2f2f2;
  border-radius: 50%;
  animation: ${loadingAnimation} 1.5s ease-in-out infinite;
`;

export default function Header({ kind }) {
  const HeaderWrap = styled.div`
    border-bottom: ${kind === 'landing' ? `1px solid #d9dde3` : 'none'};
    padding: 24px 17px;
    @media ${(props) => props.theme.devices.md} {
      padding: 24px 0px;
    }
  `;

  const navigate = useNavigate();

  const { logout, companyDetailsAPI } = useAuthentication();

  const { setVideoFetch, setPageState, setTotalPageState, setAllVideoFetch, setPromotedPageState } =
    consumerData();

  const [buttonDisable, setButtonDisable] = useState(false);
  const [newCompanyLogo, setNewCompanyLogo] = useState('');
  const [logoLoader, setLogoLoader] = useState(true);

  const { Logo, SettingIcon, LogoutIcon } = iconPath;

  // Detect sundomain name
  // const hostname = window.location.hostname;
  // const subdomain = hostname.match(/^(.*?)\./)[1];

  const subdomain = window.location.origin.split('.')[0].split('//')[1];

  // LOGOUT BUTTON
  const BASE_URL = process.env.REACT_APP_API_URL ? 'http://ads.infy.tv' : 'http://sbads.infy.tv';

  function logOutCheck() {
    setButtonDisable(true);
    logout().then(({ code, success, message }) => {
      if (success && code === 200) {
        if (
          localStorage.getItem('isAuthenticated') &&
          localStorage.getItem('loginToken') &&
          localStorage.getItem('googleOauth') &&
          localStorage.getItem('scope') &&
          localStorage.getItem('scopeCode')
        ) {
          const redirectUrl = `${BASE_URL}/login?ap=${subdomain}`;
          // const redirectUrl = `http://localhost:3000/login?ap=${subdomain}`;
          window.location.href = redirectUrl;
        } else {
          navigate('/login');
        }
        setButtonDisable(false);
        localStorage.clear();
        toast.success(toastMessage.logoutSuccess);
        setVideoFetch([]);
        setAllVideoFetch({
          allVideos: [],
          promotedVideos: [],
          loaderSynthesia: [],
        });
        setPageState(1);
        setPromotedPageState(1);
        setTotalPageState();
      } else {
        setButtonDisable(false);
        toast.error(toastMessage.logoutError);
        console.log(message);
      }
    });
  }

  // GET STARTED BUTTON
  function getStartedClick() {
    const now = new Date(localStorage.getItem('loginTime'));
    const expiryDate = new Date(now.getTime() + localStorage.getItem('expireToken') * 1000);
    if (expiryDate < new Date()) {
      navigate('/login');
      localStorage.clear();
    } else if (localStorage.getItem('isAuthenticated') && localStorage.getItem('loginToken')) {
      if (localStorage.getItem('business')) {
        navigate('/dashboard');
      } else {
        navigate('/search-business');
      }
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    setLogoLoader(true);
    companyDetailsAPI(subdomain).then((data) => {
      setNewCompanyLogo(data?.data?.thumb);
      if (subdomain) {
        localStorage.setItem('authParams', true);
      }
      setLogoLoader(false);
    });
  }, [subdomain]);

  return (
    <>
      <HeaderWrap>
        <TopContainer>
          <LogoContainer
            onClick={() => {
              const now = new Date(localStorage.getItem('loginTime'));
              const expiryDate = new Date(
                now.getTime() + localStorage.getItem('expireToken') * 1000,
              );
              if (kind !== 'search-business' && kind !== 'business') {
                if (expiryDate < new Date()) {
                  navigate('/login');
                  localStorage.clear();
                } else if (
                  localStorage.getItem('loginToken') &&
                  localStorage.getItem('isAuthenticated')
                ) {
                  navigate('/dashboard');
                  setPageState(1);
                }
              }
            }}
          >
            {logoLoader ? (
              <LogoSkeleton />
            ) : (
              <>{newCompanyLogo ? <img src={newCompanyLogo} style={{width:'100%'}}/> : <Logo />}</>
            )}
          </LogoContainer>
          <RightContainer>
            {kind === 'landing' ? (
              <Button buttonText="Get Started" kind="landing" onClick={() => getStartedClick()} />
            ) : (
              <>
                <SettingIconContainer onClick={() => navigate('/business')}>
                  <SettingIcon />
                </SettingIconContainer>
                <LogoutContainer onClick={logOutCheck} disabled={buttonDisable}>
                  <LogoutIcon />
                </LogoutContainer>
              </>
            )}
          </RightContainer>
        </TopContainer>
      </HeaderWrap>
    </>
  );
}
