import { useEffect, useState } from 'react';

function ChangingProgressProvider(props) {
  const [valueIndex, setValueIndex] = useState(
    localStorage.getItem(props.videoID) ? parseInt(localStorage.getItem(props.videoID)) : 0,
  );

  useEffect(() => {
    if (props.nameProgress === 'synthesia') {
      setTimeout(() => {
        if (props.values.length - 1 !== valueIndex) {
          localStorage.setItem(props.videoID, (valueIndex + 1) % props.values.length);
          setValueIndex((valueIndex + 1) % props.values.length);
        }
      }, 2000);
    }
  }, [valueIndex]);
  if (props.nameProgress === 'synthesia') {
    return props.children(props.values[valueIndex]);
  } else {
    return props.children(props.value);
  }
}

export default ChangingProgressProvider;
