import React from 'react';

function Icon({ buttonLoader }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 7 8"
      style={{
        verticalAlign: 'middle',
      }}
    >
      <path
        stroke={buttonLoader ? '#787878' : '#FFFFFF'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.582.951H1.415a.833.833 0 00-.833.834V5.95c0 .46.373.834.833.834h4.167c.46 0 .833-.374.833-.834V1.785A.833.833 0 005.582.95z"
      ></path>
    </svg>
  );
}

export default Icon;
