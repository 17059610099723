import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 359.35 359.25">
      <path fill="#fff" d="M74.68 63.62H275.68V276.62H74.68z"></path>
      <path
        fill="#bed6ea"
        d="M442.65 261.7c-2.27 98.48-82.72 177.55-182.65 175.9a179.63 179.63 0 117.31-359.18c98.34 2.25 177.12 83.69 175.34 183.28zM275 191.33c1.45 1.34 2.36 2.12 3.21 3 6.36 6.34 12.68 12.73 19.08 19 5.35 5.27 12.43 5.51 17.38.68s4.9-12.18-.53-17.63q-21-21.09-42.09-42.08c-5.65-5.62-12.47-5.56-18.16.11q-21 20.86-41.83 41.82c-5.5 5.52-5.79 12.61-.83 17.63s12.13 4.81 17.64-.64c6.48-6.4 12.89-12.87 19.35-19.29.73-.73 1.57-1.36 2.82-2.44v59.36c0 15-.05 29.94 0 44.91 0 7.85 6 13.28 13.55 12.42 6.13-.71 10.38-5.95 10.38-13v-99.19zm-75 126.39c-.2-.4-.39-.62-.39-.83-.05-5.86 0-11.72-.12-17.58a25.84 25.84 0 00-.49-6.3 11.94 11.94 0 00-23.41 3.55c-.13 10.6-.06 21.2 0 31.8 0 8.63 4.85 13.49 13.42 13.5h148.07c8.61 0 13.37-4.82 13.4-13.51v-29.93c0-8.47-4.92-14.2-12.12-14.08-5.93.1-12.39 4.79-11.91 13.86.35 6.43.07 12.9.07 19.52z"
        transform="translate(-83.32 -78.38)"
      ></path>
    </svg>
  );
}

export default Icon;
