import styled from 'styled-components';
import PageNotFound from '../assets/images/page-not-found.jpg';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export default function NotFound() {
  return (
    <>
      <ImageContainer>
        <img
          alt=""
          src={PageNotFound}
          height="100%"
          width="100%"
          style={{
            objectFit: 'contain',
          }}
        />
      </ImageContainer>
    </>
  );
}
