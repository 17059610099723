import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
      <path
        stroke="#9B26A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.625 9h14.75M9 1.625v14.75"
      ></path>
    </svg>
  );
}

export default Icon;
