import React from 'react';

function Icon({ buttonLoader }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill={buttonLoader ? '#787878' : '#ffffff'}
        fillRule="evenodd"
        d="M17.042.957a1.045 1.045 0 00-1.478 0L8.998 7.522 2.433.957A1.045 1.045 0 00.955 2.435L7.52 9 .955 15.566a1.045 1.045 0 001.478 1.478l6.565-6.566 6.566 6.566a1.045 1.045 0 001.478-1.478L10.477 9l6.565-6.565a1.045 1.045 0 000-1.478z"
        clipRule="evenodd"
      ></path>
      <path
        fill={buttonLoader ? '#787878' : '#ffffff'}
        d="M15.564.957l.354.353-.354-.353zm1.478 0l.354-.354-.354.354zM8.998 7.522l-.353.354.353.353.354-.353-.354-.354zM2.433.957l-.354.353.354-.353zm-1.478 0l.353.353L.955.957zm0 1.478L.6 2.788l.354-.353zM7.52 9l.354.354L8.227 9l-.353-.353L7.52 9zM.955 15.566l.353.354-.353-.354zm0 1.478l.353-.353-.353.353zm1.478 0l-.354-.353.354.353zm6.565-6.566l.354-.353-.354-.354-.353.354.353.354zm6.566 6.566l.354-.353-.354.353zm1.478 0l.354.354-.354-.354zm0-1.478l.354-.353-.354.353zM10.477 9l-.354-.353L9.769 9l.354.354.354-.354zm6.565-6.565l.354.353-.354-.353zM15.918 1.31a.545.545 0 01.77 0l.708-.707a1.545 1.545 0 00-2.185 0l.707.707zM9.352 7.876l6.566-6.566-.707-.707-6.566 6.566.707.707zM2.079 1.31l6.566 6.566.707-.707L2.786.603l-.707.707zm-.77 0a.545.545 0 01.77 0l.707-.707a1.545 1.545 0 00-2.185 0l.707.707zm0 .771a.545.545 0 010-.77L.6.602a1.545 1.545 0 000 2.185l.707-.707zm6.565 6.566L1.308 2.08l-.707.707 6.566 6.566.707-.707zM1.308 15.92l6.566-6.566-.707-.707L.6 15.213l.707.707zm0 .77a.545.545 0 010-.77l-.707-.707a1.545 1.545 0 000 2.185l.707-.707zm.771 0a.545.545 0 01-.77 0l-.708.708a1.545 1.545 0 002.185 0l-.707-.707zm6.566-6.565l-6.566 6.566.707.707 6.566-6.566-.707-.707zm7.273 6.566l-6.566-6.566-.707.707 6.566 6.566.707-.707zm.77 0a.545.545 0 01-.77 0l-.707.707a1.545 1.545 0 002.185 0l-.707-.707zm0-.771a.545.545 0 010 .77l.708.708a1.545 1.545 0 000-2.185l-.707.707zm-6.565-6.566l6.566 6.566.707-.707-6.566-6.566-.707.707zm6.566-7.273l-6.566 6.566.707.707 6.566-6.566-.707-.707zm0-.77a.545.545 0 010 .77l.707.707a1.545 1.545 0 000-2.185l-.707.707z"
      ></path>
    </svg>
  );
}

export default Icon;
