import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { iconPath } from '../assets/icons';

const VideoOuterContainer = styled.div`
  height: 100vh;
  background: black;
  position: relative;
  display: flex;
`;

const VideoContainer = styled.video`
  width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  outline: none;
  border: none;
`;

export default function VideoPlayer() {
  const {
    state: { videoUrl, activeTab },
  } = useLocation();

  const navigate = useNavigate();

  const { CloseVideoIcon } = iconPath;

  return (
    <>
      <VideoOuterContainer>
        <VideoContainer controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
        </VideoContainer>
        <CloseButton
          onClick={() => {
            if (activeTab === 'tab2') {
              navigate('/dashboard?tab=videos');
            } else {
              navigate('/dashboard');
            }
          }}
        >
          <CloseVideoIcon />
        </CloseButton>
      </VideoOuterContainer>
    </>
  );
}
