import React, { useState, useEffect, useRef } from 'react';
import { IoClose, IoSearch } from 'react-icons/io5';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickOutside } from 'react-click-outside-hook';
import MoonLoader from 'react-spinners/MoonLoader';
import styled from 'styled-components';

import axios from 'axios';

import SearchShow from './search-list';

const SearchBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 14px;
  color: #12112e;
  font-weight: 500;
  padding: 0px 15px 0px 0px;
  background-color: transparent;
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: #bebebe;
    transition: all 250ms ease-in-out;
  }
`;

const SearchIcon = styled.span`
  color: #a2a2a2;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 6px;
  vertical-align: middle;
`;

const CloseIcon = styled(motion.span)`
  color: #bebebe;
  font-size: 23px;
  position: absolute;
  right: 23px;
  top: 13px;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: #dfdfdf;
    cursor: pointer;
  }
`;

const LineSeperator = styled.span`
  display: flex;
  min-width: 100%;
  min-height: 2px;
  background-color: #d8d8d878;
`;

const SearchContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow-y: auto;
  overflow-x: hidden;
`;

const LoadingWrapper = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningMessage = styled.span`
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
  justify-self: center;
`;

const containerVariants = {
  expanded: {
    height: '15em',
  },
  collapsed: {
    height: '3em',
  },
};

const containerTransition = { type: 'spring', damping: 22, stiffness: 150 };

function Search({ apiUrl, searchDataKey, clickHandler, searchDataArray, searchIndex }) {
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [noBusinessData, setNoBusinessData] = useState(false);
  const [innerText, setInnerText] = useState('');
  const [clickHandle, setClickHandle] = useState(false);
  const [indexWord, setIndexWord] = useState();

  const isEmpty = !searchData || searchData?.length === 0;

  const changeHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === '') setNoBusinessData(false);

    setSearchQuery(e.target.value);
    setInnerText(e.target.value);
    setClickHandle(false);
    setExpanded(false);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery('');
    inputRef.current.value = '';
    setLoading(false);
    setNoBusinessData(false);
  };

  function useDebounce(value, timeout, callback) {
    const [timer, setTimer] = useState(null);

    const clearTimer = () => {
      if (timer) clearTimeout(timer);
    };

    useEffect(() => {
      clearTimer();

      if (value && callback) {
        const newTimer = setTimeout(callback, timeout);
        setTimer(newTimer);
      }
    }, [value]);
  }

  const prepareSearchQuery = (query) => {
    const url = `${apiUrl}${query}`;

    return encodeURI(url);
  };

  const searchBusinessDetail = async () => {
    if (!searchQuery || searchQuery.trim() === '') return;

    setLoading(true);
    setNoBusinessData(false);

    const URL = prepareSearchQuery(searchQuery);

    const response = await axios
      .get(URL, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('loginToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
    if (response?.data?.success && response?.data?.code === 200) {
      if (response?.data?.data && response?.data?.data?.length === 0) setNoBusinessData(true);

      setSearchData(response?.data?.data);
      setExpanded(true);
    } else {
      console.log(response?.error);
    }
    setLoading(false);
  };

  useDebounce(searchQuery, 1000, searchBusinessDetail);

  return (
    <SearchBarContainer
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={containerVariants}
      transition={containerTransition}
      ref={parentRef}
      searchDataKey={searchDataKey(innerText)}
      clickHandler={clickHandler(clickHandle)}
      searchDataArray={searchDataArray(searchData)}
      searchIndex={searchIndex(indexWord)}
    >
      <SearchInputContainer>
        <SearchIcon>
          <IoSearch />
        </SearchIcon>
        <SearchInput
          placeholder="Search Business"
          onFocus={() => {
            if (searchData?.data?.length > 0) {
              expandContainer();
            }
          }}
          ref={inputRef}
          onChange={changeHandler}
        />
        <AnimatePresence>
          {isExpanded && (
            <CloseIcon
              key="close-icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={collapseContainer}
              transition={{ duration: 0.2 }}
            >
              <IoClose />
            </CloseIcon>
          )}
          {isLoading && (
            <LoadingWrapper>
              <MoonLoader loading color="#9B26A6" size={20} />
            </LoadingWrapper>
          )}
        </AnimatePresence>
      </SearchInputContainer>
      {isExpanded && <LineSeperator />}
      {isExpanded && (
        <SearchContent>
          {!isLoading && isEmpty && !noBusinessData && (
            <LoadingWrapper>
              <WarningMessage>Start typing to Search</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && noBusinessData && (
            <LoadingWrapper>
              <WarningMessage>No Business Found!</WarningMessage>
            </LoadingWrapper>
          )}
          {!isLoading && !isEmpty && (
            <>
              {searchData.map(({ name, logo_url: logoUrl }, index) => {
                return (
                  <SearchShow
                    onCloseExpand={(e) => {
                      setExpanded(false);
                      setClickHandle(true);
                    }}
                    onSetIndex={(index) => setIndexWord(index)}
                    onSetInnerText={(value) => {
                      inputRef.current.value = value;
                      setInnerText(value);
                    }}
                    key={index}
                    reference={inputRef}
                    keyIndex={index}
                    name={name}
                    logo={logoUrl}
                  />
                );
              })}
            </>
          )}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
}

export default Search;
