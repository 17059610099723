import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" viewBox="0 0 10 6">
      <path
        fill="#A2A2A2"
        d="M.724.5a.3.3 0 00-.212.512l4.276 4.276a.3.3 0 00.424 0l4.276-4.276A.3.3 0 009.276.5H.724z"
      ></path>
    </svg>
  );
}

export default Icon;
