import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import useAuthentication from '../helpers/authentication';

import { Button, Header } from '../components';
import { toastMessage } from '../components/toast';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 30px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const SubscriptionOuterContainer = styled.div`
  @media ${(props) => props.theme.devices.xl} {
    padding: 0px 200px;
    margin-top: 40px;
  }
`;

const SubscriptionContainer = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: ${(props) => props.theme.colors.light};
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 32px;
`;

const SubscriptionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const RecommendedContainer = styled.div`
  padding: 10px 16px;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 7.5rem;
  display: flex;
  align-items: center;
  @media ${(props) => props.theme.devices.lg} {
    padding: 15px 20px;
  }
`;

const RecommendedText = styled.h4`
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.h5};
  color: ${(props) => props.theme.colors.light};
  font-weight: 500;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
`;

const SubscriptionTitle = styled.h3`
  margin: 0px;
  font-size: 2.5rem;
  font-weight: 500;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h1};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h1};
  }
`;
const SubscriptionDescription = styled.h5`
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  max-width: 212px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
    max-width: 400px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
    max-width: 445px;
  }
`;

const ValueContainer = styled.div``;
const OriginalValue = styled.div`
  font-size: ${(props) => props.theme.fonts.sm.h3};
  font-weight: 600;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h3};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h3};
  }
`;

const OriginalValueSpanText = styled.span`
  font-size: ${(props) => props.theme.fonts.sm.p};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
`;
const OriginalValueSecondSpanText = styled(OriginalValueSpanText)`
  font-weight: 500;
`;
const CancelValue = styled.div`
  margin-top: 7px;
  text-decoration: line-through;
`;

const CancelText = styled(OriginalValue)`
  font-weight: 400;
  font-size: ${(props) => props.theme.fonts.sm.h5};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.h5};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
`;

export default function Subscriptions() {
  const { state } = useLocation();

  const { checkoutSession } = useAuthentication();
  const [budgetValue, setBudgetValue] = useState();
  const [buttonLoader, setButtonLoader] = useState({ buttonId: null, flag: false });

  useEffect(() => {
    setBudgetValue(state?.budgetValue);
  }, [state?.budgetValue]);

  function checkoutSessionCall(event, budgetValue, buttonId) {
    setButtonLoader({ buttonId: buttonId, flag: true });
    const payload = {
      amount: budgetValue ? parseInt(budgetValue) : parseInt(localStorage.getItem('pay-amount')),
      currency: 'usd',
      video_id: state?.videoId ? state?.videoId : parseInt(localStorage.getItem('videoId')),
      campaign_id: state?.campaignId
        ? state?.campaignId
        : parseInt(localStorage.getItem('campaignID')),
      interval: event.target.innerText === 'Pay Weekly' ? 'week' : 'month',
    };

    checkoutSession(payload).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setButtonLoader({ buttonId: null, flag: true });
        window.location.href = data?.url;
      } else {
        toast.error(toastMessage.paymentError);
        setButtonLoader({ buttonId: null, flag: true });
        console.log(message);
      }
    });
  }

  // SUBSCRIPTION DATA

  const subscriptionData = [
    {
      buttonId: 1,
      title: 'Weekly',
      buttonText: 'Pay Weekly',
      originalValue: ' / week',
      budget: parseInt(budgetValue?.weekly_budget?.weekly_budget),
    },
    {
      buttonId: 2,
      recommended: 'Recommended',
      title: 'Monthly',
      description: 'Pay Monthly to get instant 10% off on your budget',
      buttonText: 'Pay Monthly',
      originalValue: ' / month',
      budget: parseInt(budgetValue?.monthly_budget?.monthly_budget),
      cancelValue: `$${parseInt(
        budgetValue?.monthly_budget?.monthly_budget + budgetValue?.monthly_budget?.total_discount,
      )}.00 / month`,
    },
  ];

  return (
    <>
      <Header />
      <Container>
        <SubscriptionOuterContainer>
          {subscriptionData?.length > 0 &&
            subscriptionData.map((data, index) => {
              return (
                <SubscriptionContainer key={index}>
                  <SubscriptionTitleContainer>
                    <SubscriptionTitle>{data?.title}</SubscriptionTitle>
                    {data?.recommended && (
                      <RecommendedContainer>
                        <RecommendedText>{data?.recommended}</RecommendedText>
                      </RecommendedContainer>
                    )}
                  </SubscriptionTitleContainer>
                  <SubscriptionDescription>{data?.description}</SubscriptionDescription>
                  <ValueContainer>
                    <OriginalValue>
                      ${data?.budget}
                      <OriginalValueSpanText>.00</OriginalValueSpanText>
                      <OriginalValueSecondSpanText>
                        {data?.originalValue}
                      </OriginalValueSecondSpanText>
                    </OriginalValue>
                    {data?.cancelValue && (
                      <CancelValue>
                        <CancelText>{data?.cancelValue}</CancelText>
                      </CancelValue>
                    )}
                  </ValueContainer>
                  <Button
                    buttonText={data?.buttonText}
                    kind="border"
                    buttonLoader={data?.buttonId === buttonLoader.buttonId && buttonLoader.flag}
                    disabled={data?.buttonId === buttonLoader.buttonId && buttonLoader.flag}
                    onClick={(e) => {
                      localStorage.setItem('pay-amount', parseInt(data?.budget));
                      localStorage.setItem('videoId', parseInt(state?.videoId));
                      localStorage.setItem('campaignID', parseInt(state?.campaignId));
                      checkoutSessionCall(e, data?.budget, data?.buttonId);
                    }}
                  />
                </SubscriptionContainer>
              );
            })}
        </SubscriptionOuterContainer>
      </Container>
    </>
  );
}
