import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row, setConfiguration } from 'react-grid-system';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';
import { styled as style } from '@stitches/react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import Upload from '../assets/images/upload.svg';

import axios from 'axios';

import consumerData from '../helpers/authentication';
import useAuthentication from '../helpers/authentication';

import {
  BottomPicker,
  BudgetCampaign,
  ChangingProgressProvider,
  ControlButtons,
  Header,
  NumberBox,
  StickyButton,
  VideoDisplay,
} from '../components';

import { toastMessage } from '../components/toast';
import { Skeleton } from '../components/skeleton';

import { iconPath } from '../assets/icons';
import { assetsImages } from '../assets/images';

const StyledTabs = style(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',
});

const StyledList = style(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  boxShadow: 'none',
  borderRadius: '8px',
  marginBottom: '15px',
  margin: '0 auto 20px',
  width: '100%',
  maxWidth: '360px',
  padding: '0px 0px 0px',
  boxShadow: '0px 6px 18px 0px rgba(24, 39, 75, 0.14)',
});

const StyledTrigger = style(TabsPrimitive.Trigger, {
  'all': 'unset',
  'fontFamily': 'inherit',
  'backgroundColor': 'white',
  'padding': '0 20px',
  'height': 45,
  'borderBottom': '2px solid #D9DDE3',
  'borderRadius': '0px',
  'flex': 1,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'fontSize': 16,
  'lineHeight': 1,
  'color': '#000',
  'userSelect': 'none',
  '&:hover': { color: '#9B26A6' },
  'button[id="tab-1"]&[data-state="active"]': {
    color: '#9B26A6',
    borderBottom: '2px solid #9B26A6',
  },
  'button[id="tab-2"]&[data-state="active"]': {
    color: '#9B26A6',
    borderBottom: '2px solid #9B26A6',
  },
  '@media (min-width: 1280px)': {
    fontSize: 16,
  },
});

const StyledContent = style(TabsPrimitive.Content, {
  flexGrow: 1,
  backgroundColor: 'white',
  outline: 'none',
});

export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;

const ContainerOuter = styled.div`
  padding: 0px 16px 16px 16px;
  overflow: hidden;
  max-width: 1140px;
  margin: 0 auto;
  /* @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  } */
`;

const VideoOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //margin-top: 7px;
  width: 100%;
  @media ${(props) => props.theme.devices.md} {
    align-items: flex-start;
  }

  @media ${(props) => props.theme.devices.xl} {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    height: 100%;
  }
`;

const VideoFirstContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  column-gap: 5px;
  width: 100%;
  justify-content: space-between;
  @media ${(props) => props.theme.devices.xl} {
    width: 100%;
  }
`;

const VideoSecondContainer = styled(VideoFirstContainer)`
  margin-bottom: ${(props) => (props.tabs ? '0px' : '100px')};
  @media ${(props) => props.theme.devices.xl} {
    column-gap: 10px;
    width: 100%;
  }
  @media ${(props) => props.theme.devices.xxl} {
    column-gap: 50px;
    width: 100%;
  }
`;

const VideoFirstSkeletonContainer = styled(VideoFirstContainer)`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  column-gap: 5px;
  justify-content: space-between;
  @media ${(props) => props.theme.devices.xl} {
    width: auto;
  }
`;

const GridContainer = styled(Container)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
  @media ${(props) => props.theme.devices.md} {
    padding: auto !important;
  }
`;

const GridRow = styled(Row)`
  width: 100%;
  margin: 0px !important;
`;

const VideoInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.devices.md} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '25px auto 0px 0px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '25px auto 0px 0px')};
  }
`;

const PromotedOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto 0px auto;
  justify-content: center;
`;

const TopPromotedContainer = styled.div`
  display: flex;
  column-gap: 6px;
  @media ${(props) => props.theme.devices.md} {
    justify-content: space-between;
  }
`;

const UploadContentContainer = styled.div``;

const ContentImageContainer = styled.div`
  margin: 0 auto;
  width: 335px;
  height: 209px;
  margin-top: 25px;
  margin-bottom: 25px;
  /* @media ${(props) => props.theme.devices.md} {
    width: 460px;
    margin: 100px auto 5px auto;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 600px;
  } */
`;

const UploadContentText = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.p};
  line-height: 1.125rem;
  margin: 0px 34px 129px 34px;
  font-weight: 400;
  letter-spacing: 0.1px;
  text-align: center;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
    line-height: 1.56rem;
    margin: 0px 80px 100px 80px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
    line-height: 1.87rem;
    margin: 0px 120px 100px 120px;
  } */
`;

const Line = styled.div`
  background: #d8d8d8;
  width: 100%;
  height: 1px;
`;

const CampaignNameText = styled.h4`
  margin: 10px 0px 12px 0px;
  word-break: break-all;
  font-size: 1rem;
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.5rem;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 1.2rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 1.6rem;
  }
`;

const SubscriptionText = styled.h5`
  margin: 0px 0px 17px 0px;
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 400;
  color: #707070;
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
`;

let checkLoaderStatus = {};

function VideosMain() {
  const {
    youtubeTokenGenerate,
    facebookTokenGenerate,
    getCampaignVideo,
    retrieveCampaignVideoDashboard,
    getVideoYoutube,
    getFacebookVideo,
    getStatistics,
    facebookUpload,
    campaignStatus,
    youtubeUpload,
    getSynthesiaVideo,
    loginTokenUrl,
    editCampaignVideo,
    postStripeSubscription,
  } = useAuthentication();

  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    pageState,
    allVideoFetch,
    setAllVideoFetch,
    promotedPageState,
    setPromotedPageState,
    setPageState,
    totalPageState,
    setTotalPageState,
    totalPromotedPageState,
    setTotalPromotedPageState,
    promotedNumberData,
    setPromotedNumberData,
  } = consumerData();

  const { PlayIcon, YoutubeMiniIcon, FacebookMiniIcon, HomeContentImage } = iconPath;

  const [videoToken] = useSearchParams();

  const [skeleton, setSkeleton] = useState(false);
  const [doneButton, setDoneButton] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [budgetPicker, setBudgetPicker] = useState();
  const [increaseData, setIncreaseData] = useState();
  const [noContent, setNoContent] = useState(false);
  const [callVideo, setCallVideo] = useState(false);
  const [videoSource, setVideoSource] = useState();
  const [subscriptionMessage, setSubscriptionMessage] = useState();
  const [rePromoteLoader, setRePromoteLoader] = useState({ videoId: null, flag: false });
  const [stopPromoteLoader, setStopPromoteLoader] = useState([]);
  const [facebookLoader, setFacebookLoader] = useState({ postVideoId: null, flag: false });
  const [fileUploadLoader, setFileUploadLoader] = useState([]);
  const [showVideoLoader, setShowVideoLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(videoToken.get('tab') === 'videos' ? 'tab2' : 'tab1');
  const [localTabView, setLocalTabView] = useState(
    localStorage.getItem('tabularView') === 'true' ? true : false,
  );
  const [width, setWidth] = useState(0);
  const [pickerKind, setPickerKind] = useState('');

  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  const date = new Date().toDateString();

  // PAGINATION NEXT PAGE

  const nextPage = () => {
    setPageState(pageState + 1);
  };

  const nextPromotedPage = () => {
    setPromotedPageState(promotedPageState + 1);
  };

  // CREATE LOADER SEPERATE FOR SYNTHESIA

  function createLoader(videoData) {
    return videoData.map((data) => {
      return {
        image: assetsImages.LoaderImage,
        loaderIcon: (
          <>
            <ChangingProgressProvider
              videoID={data?.id}
              nameProgress="synthesia"
              values={[
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
                44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
                65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85,
                86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
              ]}
            >
              {(percentage) => (
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: '#9B26A6',
                    textColor: '#9B26A6',
                    textSize: '1.8rem',
                  })}
                  strokeWidth={5}
                  value={percentage}
                  text={`${percentage}%`}
                />
              )}
            </ChangingProgressProvider>
          </>
        ),
        icon: '',
        date: date,
        video_duration: null,
        video_source_from_name: '',
        playIcon: '',
        loader: true,
        video_url: '',
        uuid: data?.id,
        videoId: '',
      };
    });
  }

  // IN_PROGRESS / COMPLETE SYNTHESIA API CALL

  async function interValGet(videoID) {
    try {
      const responseCreateData = await getSynthesiaVideo(`${videoID}/`);
      if (responseCreateData?.data?.status === 'complete') {
        clearInterval(checkLoaderStatus[`${videoID}`]);
        setAllVideoFetch((prevState) => {
          return { ...prevState, allVideos: [] };
        });
        setPageState(1);
        allVideoGet(1);
        localStorage.removeItem(videoID);
        toast.success(toastMessage.synthesiaCreated);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  // SYNTHESIA LOADER CHECK STATUS(IF VIDEO FOUND CLEAR INTERVAL AND AGAIN SET INTO OBJECT)

  async function loaderSynthesiaStatus(videoID) {
    if (checkLoaderStatus[`${videoID}`]) {
      clearInterval(checkLoaderStatus[`${videoID}`]);
    }
    const temp = setInterval(() => {
      interValGet(videoID);
    }, 30000);
    checkLoaderStatus = { ...checkLoaderStatus, [`${videoID}`]: temp };
  }

  // PASS INDIVIDUAL VIDEO ID FOR CHECK SYNTHESIA LOADER STATUS

  async function inProgressCreate(loader) {
    if (loader?.length > 0) {
      loader.forEach(async (videoLoader) => {
        loaderSynthesiaStatus(videoLoader?.uuid);
      });
    }
  }

  // GET ALL THE VIDEOS OF TAB - MY CAMPAIGN

  async function allVideoGet(value) {
    setSkeleton(true);
    if (pageState > 1) {
      setShowVideoLoader(true);
    }
    const allVideos = [];

    try {
      const responseVideo = await getCampaignVideo(
        `?sort_direction=descending&limit=10&page=${value ? value : pageState}`,
      );

      if (
        responseVideo?.code === 200 &&
        responseVideo?.success &&
        responseVideo?.data?.length > 0
      ) {
        setShowVideoLoader(false);
        setTotalPageState(responseVideo?.pagination?.total_pages);
        if (allVideoFetch?.allVideos?.length > 0 && pageState !== 1) {
          allVideos.push(...allVideoFetch?.allVideos, ...responseVideo?.data);
        } else {
          allVideos.push(...responseVideo?.data);
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }

    const date = new Date().toDateString();

    if (pageState === 1) {
      try {
        const responseYoutube = await getVideoYoutube();

        if (responseYoutube?.code === 200 && responseYoutube?.success) {
          navigate('/dashboard?tab=videos');
          setActiveTab('tab2');
          const tempYoutubeData = [];
          if (responseYoutube?.data?.length > 0) {
            setNoContent(false);
            if (localStorage.getItem('youtubeOauth') === 'true') {
              toast.success(`${responseYoutube?.data?.length} Youtube Video Fetched`);
            }
            for (let i = 0; i < responseYoutube?.data?.length; i++) {
              tempYoutubeData.push({
                image: responseYoutube?.data[i]?.thumbnails?.high?.url,
                thumbnail_url: responseYoutube?.data[i]?.thumbnails?.high?.url,
                playIcon: <PlayIcon />,
                icon: <YoutubeMiniIcon />,
                date: date,
                video_duration: `${responseYoutube?.data[i]?.duration}`,
                video_source_from_name: 'Youtube',
                youtubeUpload: true,
                video_url: responseYoutube?.data[i]?.video_url,
                id: responseYoutube?.data[i]?.video_source_id,
              });
            }
          } else if (responseYoutube?.data?.length === 0) {
            if (localStorage.getItem('youtubeOauth') === 'true') {
              toast.success(toastMessage.noYoutubeVideoFound);
            }
          }
          allVideos.push(...tempYoutubeData);
        }
        localStorage.setItem('youtubeOauth', false);
      } catch (error) {
        console.log(error.message);
      }

      try {
        const responseFacebook = await getFacebookVideo();
        if (responseFacebook?.code === 200 && responseFacebook?.success) {
          navigate('/dashboard?tab=videos');
          setActiveTab('tab2');
          const tempFacebookData = [];
          if (responseFacebook?.data?.length > 0) {
            setNoContent(false);
            if (localStorage.getItem('facebookOauth') === 'true') {
              toast.success(`${responseFacebook?.data?.length} Facebook Video Fetched`);
            }
            for (let i = 0; i < responseFacebook?.data?.length; i++) {
              tempFacebookData.push({
                image: responseFacebook?.data[i]?.image_url,
                thumbnail_url: responseFacebook?.data[i]?.image_url,
                playIcon: <PlayIcon />,
                icon: <FacebookMiniIcon />,
                date: date,
                video_duration: `${responseFacebook?.data[i]?.duration}`,
                video_source_from_name: 'Facebook',
                facebookUpload: true,
                video_url: responseFacebook?.data[i]?.video_url,
                id: responseFacebook?.data[i]?.post_id,
              });
            }
          } else if (responseFacebook?.data?.length === 0) {
            if (localStorage.getItem('facebookOauth') === 'true') {
              toast.success(toastMessage.noFacebookVideoFound);
            }
          }
          allVideos.push(...tempFacebookData);
        }
        localStorage.setItem('facebookOauth', false);
      } catch (error) {
        console.log(error.message);
      }
    }

    try {
      const inProgressVideos = await getCampaignVideo(`?status=1`);
      let loaderVideos = [];
      if (
        inProgressVideos?.code === 200 &&
        inProgressVideos?.success &&
        inProgressVideos?.data?.length > 0
      ) {
        const progressVideos = [...inProgressVideos?.data];
        setNoContent(false);
        if (pageState === 1) {
          loaderVideos = createLoader(progressVideos);
        }
      }

      if (allVideos?.length === 0 && loaderVideos?.length === 0) {
        setNoContent(true);
      }
      setAllVideoFetch({
        promotedVideos: [...allVideoFetch?.promotedVideos],
        allVideos: [...loaderVideos, ...allVideos],
        loaderSynthesia: loaderVideos,
      });
      setTimeout(() => {
        inProgressCreate(loaderVideos);
      }, 3000);
      setSkeleton(false);
    } catch (error) {
      toast.error(error.message);
    }
  }

  // GET ALL THE VIDEOS(YOUTUBE, FACEBOOK, DEVICE UPLOAD, SYNTHESIA)

  async function allVideoYoutubeFacebook(value) {
    setSkeleton(true);
    if (promotedPageState > 1) {
      setShowVideoLoader(true);
    }

    const filterAllVideos = [];

    try {
      const filterPromotedVideos = await retrieveCampaignVideoDashboard(
        `?sort_direction=descending&limit=10&page=${value ? value : promotedPageState}
        `,
      );
      if (
        filterPromotedVideos?.code === 200 &&
        filterPromotedVideos?.success &&
        filterPromotedVideos?.data?.length > 0
      ) {
        setShowVideoLoader(false);
        localStorage.setItem('PromotedFlag', true);
        localStorage.setItem('tabularView', true);
        setLocalTabView(true);
        setTotalPromotedPageState(filterPromotedVideos?.pagination?.total_pages);
        if (allVideoFetch?.promotedVideos?.length > 0 && promotedPageState !== 1) {
          filterAllVideos.push(...allVideoFetch?.promotedVideos, ...filterPromotedVideos?.data);
        } else {
          filterAllVideos.push(...filterPromotedVideos?.data);
        }
      }

      if (filterPromotedVideos?.data?.length === 0) {
        if (allVideoFetch?.allVideos?.length === 0) {
          setSkeleton(true);
          allVideoGet();
          setActiveTab('tab2');
          setNoContent(false);
        } else {
          setSkeleton(false);
          setAllVideoFetch({ ...allVideoFetch });
        }
      } else {
        setSkeleton(false);
        setNoContent(false);
      }

      const tempDataNumber = [];

      for (let i = 0; i < filterPromotedVideos?.data?.length; i++) {
        const tempId = filterPromotedVideos?.data[i]?.id;
        const tempCampaignId = filterPromotedVideos?.data[i]?.campaign_id;
        const filterID = filterPromotedVideos?.data[i]?.call_to_action;
        if (
          filterPromotedVideos?.data[i]?.campaign_status_name !== null &&
          filterPromotedVideos?.data[i]?.campaign_status_name !== ''
        ) {
          getStatistics(
            `?video_id=${filterPromotedVideos?.data[i]?.id}&campaign_id=${filterPromotedVideos?.data[i]?.campaign_id}`,
          ).then(({ code, data, success, message }) => {
            if (success && code === 200) {
              if (data === null || Object.keys(data)?.length === 0) {
                tempDataNumber.push({
                  videoId: tempId,
                  campaignId: tempCampaignId,
                  promotedData: [
                    {
                      numberText: '0',
                      descriptionText: 'Impressions',
                    },
                    {
                      numberText: '0',
                      numberSpanText: ` ${
                        filterID === 1 || filterID === 2
                          ? 'Calls'
                          : filterID === 3
                          ? 'Email'
                          : filterID === 4 || filterID === 5
                          ? 'Visits'
                          : ''
                      }`,
                      descriptionText: 'Uniques',
                    },
                    {
                      numberText: '$0',
                      descriptionText: 'Engagement',
                    },
                  ],
                });
              } else {
                tempDataNumber.push({
                  videoId: tempId,
                  campaignId: tempCampaignId,
                  promotedData: [
                    {
                      numberText: data?.wins,
                      descriptionText: 'Impressions',
                    },
                    {
                      numberText: data?.click,
                      numberSpanText: ` ${
                        filterID === 1 || filterID === 2
                          ? 'Calls'
                          : filterID === 3
                          ? 'Email'
                          : filterID === 4 || filterID === 5
                          ? 'Visits'
                          : ''
                      }`,
                      descriptionText: 'Uniques',
                    },
                    {
                      numberText: `$${data?.total}`,
                      descriptionText: 'Engagement',
                    },
                  ],
                });
              }
              if (promotedNumberData.find((data) => data?.campaignId === tempCampaignId)) {
              } else {
                setPromotedNumberData(promotedNumberData.concat(tempDataNumber));
              }
            } else {
              console.log(message);
            }
          });
        }
      }

      setAllVideoFetch({
        ...allVideoFetch,
        promotedVideos: [...filterAllVideos],
        allVideos: [...allVideoFetch?.allVideos],
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  // LOGIN WITH TOKEN URL

  function loginWithToken() {
    if (videoToken.get('login') === null && !localStorage.getItem('loginToken')) {
      navigate('/login');
    } else if (videoToken.get('login') !== null) {
      loginTokenUrl(`?token=${videoToken.get('login')}`).then(
        ({ code, data, message, success }) => {
          if (success && code === 200) {
            setCallVideo(true);
            localStorage.setItem('loginToken', data?.token?.access_token);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expireToken', data?.token?.expires_in);
            localStorage.setItem('loginTime', new Date());
          } else {
            navigate('/login');
            toast.error(message);
          }
        },
      );
    }
  }

  const widthSet = () => {
    setWidth(window.innerWidth);
  };

  const now = new Date(localStorage.getItem('loginTime'));
  const expiryDate = new Date(now.getTime() + localStorage.getItem('expireToken') * 1000);
  useEffect(() => {
    if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date()
    ) {
      return;
    } else {
      localStorage.clear();
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    loginWithToken();
    widthSet();
    window.addEventListener('resize', widthSet);
    return () => {
      window.removeEventListener('resize', widthSet);
    };
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date()
    ) {
      if (
        (allVideoFetch?.promotedVideos?.length === 0 &&
          promotedPageState === 1 &&
          activeTab === 'tab1') ||
        (allVideoFetch?.promotedVideos?.length > 0 &&
          promotedPageState !== 1 &&
          promotedPageState <= totalPromotedPageState &&
          activeTab === 'tab1')
      ) {
        allVideoYoutubeFacebook();
        setCallVideo(false);
      }
    }
  }, [promotedPageState, callVideo]);

  useEffect(() => {
    if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date()
    ) {
      if (
        (allVideoFetch?.allVideos?.length === 0 && pageState === 1 && activeTab === 'tab2') ||
        (allVideoFetch?.allVideos?.length > 0 &&
          pageState !== 1 &&
          pageState <= totalPageState &&
          activeTab === 'tab2')
      ) {
        allVideoGet();
        setCallVideo(false);
      }
    }
  }, [pageState, callVideo]);

  function navigatePromote(state) {
    navigate('/promote', {
      state: {
        ...state,
      },
    });
  }

  // UPDATE SUBSCRIPTION

  function updateSubscription(videoId, campaignId) {
    const payload = {
      currency: 'usd',
      video_id: videoId,
      campaign_id: campaignId,
    };

    postStripeSubscription(payload).then(({ code, data, success, message }) => {
      if (code === 201 && success) {
        const date = new Date(data?.current_period_end * 1000).toDateString();
        setSubscriptionMessage(date);
        setDoneButton(true);
        setButtonLoader(false);
        allVideoYoutubeFacebook(1);
        setAllVideoFetch({
          ...allVideoFetch,
          promotedVideos: [],
        });

        setPromotedPageState(1);
      } else {
        toast.error(toastMessage.subscriptionUpdateError);
      }
    });
  }

  // INCREASE THE BUDGET

  function increaseBudget(budgetValue) {
    const subdomain = window.location.origin.split('.')[0].split('//')[1];

    const todayDate = new Date().toISOString().slice(0, 10);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 3);
    const payload = {
      start_date: todayDate,
      end_date: endDate.toISOString().slice(0, 10),
      budget_amount: budgetValue,
      duration: parseInt(increaseData?.video_duration),
      daily_budget: budgetValue,
      campaign_video: increaseData?.id,
      zip_value: increaseData?.zip_value,
      call_to_action_value: increaseData?.call_to_action_value,
      call_to_action: increaseData?.call_to_action,
      status: 1,
      campaign_time: increaseData?.campaign_time,
      holiday: increaseData?.holiday,
      categories_data: increaseData?.campaign_channels,
      domain: subdomain,
    };
    setButtonLoader(true);
    editCampaignVideo(`${increaseData?.campaign_id}/`, payload).then(
      ({ code, data, success, message }) => {
        if (success && code === 200) {
          if (increaseData?.has_stripe_subscription) {
            updateSubscription(increaseData?.id, increaseData?.campaign_id);
          } else {
            allVideoYoutubeFacebook(1);
            setAllVideoFetch({
              ...allVideoFetch,
              promotedVideos: [],
            });
            setPromotedPageState(1);
            setButtonLoader(false);
            setPickerVisible(false);
          }
        } else {
          toast.error(toastMessage.campaignEditError);
          setButtonLoader(false);
          console.log(message);
        }
      },
    );
  }

  // PROMOTE FACEBOOK VIDEOS

  function postFacebookUpload(video, image, videoId, videoTime, state) {
    setFacebookLoader({ postVideoId: videoId, flag: true });
    facebookUpload({
      post_id: videoId,
      video_link: video,
      thumbnail_link: image,
      duration: parseInt(videoTime.split('Sec')[0]),
    }).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setFacebookLoader({ postVideoId: null, flag: false });
        const videoState = { ...state };
        videoState.videoId = data?.campaign_video;
        const stateVideo = videoState;
        setPageState(1);
        setAllVideoFetch({ ...allVideoFetch, allVideos: [] });
        navigatePromote(stateVideo);
      } else {
        setFacebookLoader({ postVideoId: null, flag: false });
        console.log(message);
      }
    });
  }

  //  PROMOTE YOUTUBE VIDEOS

  function postYoutubeUpload(video, image, id, videoTime, state) {
    setFacebookLoader({ postVideoId: id, flag: true });
    youtubeUpload({
      link: video,
      thumbnail_link: image,
      video_source_id: id,
      duration: parseInt(videoTime.split('Sec')[0]),
    }).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setFacebookLoader({ postVideoId: null, flag: false });
        const videoState = { ...state };
        videoState.videoId = data?.campaign_video_id;
        const stateVideo = videoState;
        setPageState(1);
        setAllVideoFetch({ ...allVideoFetch, allVideos: [] });
        navigatePromote(stateVideo);
      } else {
        setFacebookLoader({ postVideoId: null, flag: false });
        console.log(message);
      }
    });
  }

  // RE-PROMOTE VIDEOS

  function rePromotedVideo(data) {
    const subdomain = window.location.origin.split('.')[0].split('//')[1];

    const payload = {
      status: 1,
      domain: subdomain,
    };
    setRePromoteLoader({ videoId: data?.campaign_id, flag: true });
    campaignStatus(`${data.campaign_id}/`, payload).then(({ code, data, success, message }) => {
      console.log(data);
      if (success && code === 200) {
        setTimeout(() => {
          setAllVideoFetch((prevState) => {
            return { ...prevState, promotedVideos: [] };
          });
          setRePromoteLoader({ videoId: null, flag: false });
          setPromotedPageState(1);
          if (promotedPageState === 1) {
            allVideoYoutubeFacebook(1);
          }
        }, 5000);
      } else {
        setRePromoteLoader({ videoId: null, flag: false });
        toast.error(toastMessage.campaignEditError);
        console.log(message);
      }
    });
  }

  // STOP PROMOTED VIDEOS

  function stopPromoteVideos(data) {
    const subdomain = window.location.origin.split('.')[0].split('//')[1];

    const payload = {
      status: 2,
      domain: subdomain,
    };
    setStopPromoteLoader({ videoId: data?.campaign_id, flag: true });
    setButtonLoader(true);

    campaignStatus(`${data.campaign_id}/`, payload).then(({ code, data, success, message }) => {
      console.log(data);
      if (success && code === 200) {
        setTimeout(() => {
          setAllVideoFetch((prevState) => {
            return { ...prevState, promotedVideos: [] };
          });
          setStopPromoteLoader({ videoId: null, flag: false });
          setButtonLoader(false);
          setPromotedPageState(1);
          if (promotedPageState === 1) {
            allVideoYoutubeFacebook(1);
          }
        }, 5000);
        setDoneButton(true);
        setPickerVisible(false);
      } else {
        setStopPromoteLoader({ videoId: null, flag: false });
        setButtonLoader(false);
        toast.error(toastMessage.campaignEditError);
        console.log(message);
      }
    });
  }

  // RENDER STATIC

  function renderSkeleton() {
    const dataSkeleton = ['item1', 'item1', 'item1', 'item1'];
    if (width >= 768 && width < 1280) {
      dataSkeleton.push('item1', 'item1');
    } else if (width >= 1280) {
      dataSkeleton.push('item1', 'item1', 'item1', 'item1');
    }
    return dataSkeleton.map((data, index) => {
      return (
        <Col
          key={index}
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={3}
          xxxl={3}
          style={{
            width: '50vw',
            margin: '0px 0px 20px 0px',
          }}
        >
          <VideoInnerContainer>
            <Skeleton />
          </VideoInnerContainer>
        </Col>
      );
    });
  }

  // GET VIDEO DURATION

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  function createFileUploadLoader(data) {
    return {
      image: assetsImages.LoaderImage,
      loaderValueFile: data,
      loaderIcon: (
        <>
          <ChangingProgressProvider nameProgress="fileUpload" value={data}>
            {(percentage) => {
              return percentage !== 'Processing' ? (
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: '#9B26A6',
                    textColor: '#9B26A6',
                    textSize: '1.8rem',
                  })}
                  strokeWidth={5}
                  value={percentage}
                  text={`${percentage}%`}
                />
              ) : (
                <>
                  <p
                    style={{
                      color: '#9B26A6',
                      textAlign: 'center',
                    }}
                  >
                    Processing....
                  </p>
                </>
              );
            }}
          </ChangingProgressProvider>
        </>
      ),
      icon: '',
      date: date,
      video_duration: null,
      video_source_from_name: '',
      playIcon: '',
      loader: true,
      video_url: '',
      id: '',
    };
  }

  // UPLOAD VIDEO FROM DEVICE

  async function uploadVideo(e) {
    e.preventDefault();
    const uploadFile = e.target.files[0];

    if (
      uploadFile?.type === 'image/jpeg' ||
      uploadFile?.type === 'image/png' ||
      uploadFile?.type === 'image/jpg'
    ) {
      alert('JPEG/PNG/JPG File Format Not Acceptable');
    } else {
      const duration = await getVideoDuration(e.target.files[0]);

      const videoSourceUrl = URL.createObjectURL(e.target.files[0]);
      setVideoSource(videoSourceUrl);

      const image = document.createElement('video');
      image.src = videoSource;

      const uniqueIndex = fileUploadLoader?.length;

      if (duration > 30 || duration < 15) {
        alert('Please Upload File between 15 to 30 Seconds');
      } else if ((uploadFile.size / 1024 / 1024).toFixed(0) > 50) {
        alert('Please Upload File Under 50MB');
      } else {
        setNoContent(false);

        window.scrollTo({ top: 0, behavior: 'smooth' });

        setFileUploadLoader((prevState) => {
          return [...prevState, 0];
        });

        navigate('/dashboard?tab=videos');
        setActiveTab('tab2');

        const url = process.env.REACT_APP_API_URL;
        const headerConfig = {
          'Content-Type': 'video/mp4',
        };

        const config = {
          headers: headerConfig,
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            setFileUploadLoader((prevState) => {
              const tempArray = [...prevState];
              tempArray[uniqueIndex] = percentCompleted;

              return tempArray;
            });
          },
        };

        const presignedUrlResponse = await axios.post(
          `${url}/presigned-url/`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('loginToken')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        if (presignedUrlResponse?.data?.success && presignedUrlResponse?.data?.code === 200) {
          const presignedPostResponse = await axios.put(
            presignedUrlResponse?.data?.data?.data,
            uploadFile,
            config,
          );
          if (presignedPostResponse?.status === 200) {
            setFileUploadLoader((prevState) => {
              const tempArray = [...prevState];
              tempArray[uniqueIndex] = 'Processing';
              return tempArray;
            });
            const keyCampaignResponse = await axios.post(
              `${url}/campaign-video/`,
              {
                key: presignedUrlResponse?.data?.data?.key,
                title: 'Device Upload',
                video_duration: duration,
              },
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('loginToken')}`,
                  'Content-Type': 'application/json',
                },
              },
            );
            if (keyCampaignResponse?.data?.success && keyCampaignResponse?.data?.code === 200) {
              setFileUploadLoader((prevState) => {
                const tempArray = [...prevState];
                tempArray[uniqueIndex] = null;
                return tempArray;
              });
              setAllVideoFetch({
                allVideos: [],
                promotedVideos: [],
                loaderSynthesia: [],
              });
              setPageState(1);
              allVideoGet(1);
            }
          }
        }
      }
    }
  }

  // YOUTUBE AUTHENTICATION

  function youtubeAuthentication() {
    const subdomain = window.location.origin.split('.')[0].split('//')[1];
    youtubeTokenGenerate(subdomain).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        window.location.href = data;
        localStorage.setItem('youtubeOauth', true);
      } else {
        console.log(message);
      }
    });
  }

  // FACEBOOK AUTHENTICATION

  function facebookAuthentication() {
    facebookTokenGenerate().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        window.location.href = data;
        localStorage.setItem('facebookOauth', true);
      } else {
        console.log(message);
      }
    });
  }

  let allVideosWithLoader = [...allVideoFetch?.allVideos];

  if (fileUploadLoader?.length > 0) {
    const tempArray = fileUploadLoader
      .filter((data) => data !== null)
      .map((data, index) => {
        return createFileUploadLoader(data);
      });
    allVideosWithLoader = [...tempArray, ...allVideoFetch?.allVideos];
  }

  return (
    <>
      <Header />
      <ContainerOuter>
        {noContent && (
          <>
            <UploadContentContainer>
              <ContentImageContainer>
                <img src={Upload} />
              </ContentImageContainer>
              <UploadContentText>
                Upload Video-Ad to get started. You can also import from you social media accounts.
              </UploadContentText>
            </UploadContentContainer>
            <Line />
          </>
        )}
        {localTabView ? (
          <div className="containerTab">
            <Tabs
              value={activeTab}
              style={{
                marginBottom: `${activeTab === 'tab1' ? '0rem' : '9rem'}`,
              }}
            >
              <TabsList aria-label="Campaigns" className="tabsMain">
                <TabsTrigger
                  value="tab1"
                  id="tab-1"
                  onClick={() => {
                    setActiveTab('tab1');
                    navigate('/dashboard');
                    if (allVideoFetch?.promotedVideos?.length === 0) {
                      allVideoYoutubeFacebook(1);
                    }
                  }}
                >
                  My Campaigns
                </TabsTrigger>
                <TabsTrigger
                  value="tab2"
                  id="tab-2"
                  onClick={() => {
                    navigate('/dashboard?tab=videos');
                    if (allVideoFetch?.allVideos?.length === 0) {
                      allVideoGet();
                    }
                    setActiveTab('tab2');
                  }}
                >
                  My Videos
                </TabsTrigger>
              </TabsList>
              <VideoOuterContainer>
                <TabsContent value="tab2">
                  {skeleton && pageState === 1 && (
                    <>
                      <VideoOuterContainer>
                        <VideoFirstSkeletonContainer>
                          <GridContainer>
                            <GridRow gutterWidth={16}>{renderSkeleton()}</GridRow>
                          </GridContainer>
                        </VideoFirstSkeletonContainer>
                      </VideoOuterContainer>
                    </>
                  )}
                  <VideoSecondContainer tabs={true}>
                    <GridContainer key="gridVideos">
                      <GridRow gutterWidth={16} key="videos">
                        {allVideosWithLoader?.length > 0 &&
                          allVideosWithLoader.map((data, index) => {
                            const videoTime =
                              data?.video_duration !== null
                                ? data?.video_duration.includes(':')
                                  ? `${parseInt(data?.video_duration.split(':')[2])} Sec`
                                  : `${parseInt(data?.video_duration)} Sec`
                                : '';

                            return (
                              <>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={4}
                                  xxl={4}
                                  key={data?.video_id}
                                  style={{
                                    padding: '0px',
                                    margin: '0px 0px 20px',
                                  }}
                                >
                                  <div className="cards">
                                    <div className="card">
                                      <Col
                                        key={data?.video_id + 'pr'}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{
                                          padding: '0px',
                                        }}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        xxxl={12}
                                      >
                                        <VideoDisplay
                                          activeTab={activeTab}
                                          data={data}
                                          isLast={allVideoFetch?.allVideos.length - 1 === index}
                                          videoTime={videoTime}
                                          nextPage={nextPage}
                                          allVideoFetch={allVideoFetch}
                                          index={index}
                                          facebookLoader={facebookLoader}
                                          buttonLoader={buttonLoader}
                                          kind="video"
                                          postFacebookUpload={(videoUrl, image, id, time, state) =>
                                            postFacebookUpload(videoUrl, image, id, time, state)
                                          }
                                          postYoutubeUpload={(videoUrl, image, id, time, state) =>
                                            postYoutubeUpload(videoUrl, image, id, time, state)
                                          }
                                          navigatePromote={(state) => navigatePromote(state)}
                                        />
                                      </Col>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            );
                          })}
                      </GridRow>
                    </GridContainer>
                  </VideoSecondContainer>
                  {showVideoLoader && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="loader"></div>
                    </div>
                  )}
                </TabsContent>
                <TabsContent value="tab1">
                  {skeleton && promotedPageState === 1 && (
                    <>
                      <VideoOuterContainer>
                        <VideoFirstSkeletonContainer>
                          <GridContainer>
                            <GridRow gutterWidth={16}>{renderSkeleton()}</GridRow>
                          </GridContainer>
                        </VideoFirstSkeletonContainer>
                      </VideoOuterContainer>
                    </>
                  )}

                  <VideoFirstContainer>
                    <GridContainer key="gridPromotedVideos">
                      <GridRow gutterWidth={16} key="rowPromotedVideos">
                        {allVideoFetch?.promotedVideos?.length > 0 &&
                          allVideoFetch?.promotedVideos.map((data, index) => {
                            const videoTime = data?.video_duration
                              ? data?.video_duration.includes(':')
                                ? `${parseInt(data?.video_duration.split(':')[2])} Sec`
                                : `${parseInt(data?.video_duration)} Sec`
                              : '30 Sec';

                            const videoIDUnique = data?.video_id;

                            return (
                              <>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  xxl={4}
                                  xxxl={4}
                                  key={data?.video_id}
                                  style={{
                                    padding: '0px',
                                    margin: '0px 0px 20px',
                                  }}
                                >
                                  <div className="cards">
                                    <div className="card">
                                      <div className="card-video-body">
                                        <div
                                          className="video-body"
                                          key={data?.video_id}
                                          style={{
                                            padding: '0px',
                                          }}
                                        >
                                          <VideoDisplay
                                            data={data}
                                            isLast={
                                              allVideoFetch?.promotedVideos.length - 1 === index
                                            }
                                            videoTime={videoTime}
                                            nextPagePromoted={nextPromotedPage}
                                            allVideoFetch={allVideoFetch}
                                            index={index}
                                            kind="description"
                                          />
                                        </div>
                                        <div className="video-content">
                                          {data?.name && (
                                            <CampaignNameText className="title">
                                              {data?.name}
                                            </CampaignNameText>
                                          )}
                                          <BudgetCampaign
                                            xs={13}
                                            budgetAmount={data?.budget_amount}
                                          />
                                        </div>
                                      </div>

                                      {/* {data?.next_billing_date !== null &&
                                        data?.next_cycle_daily_amount !== data?.budget_amount && (
                                          <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            xxxl={12}
                                          >
                                            <SubscriptionText>
                                              Your Updated subscription will be start from{' '}
                                              <span
                                                style={{
                                                  color: '#9B26A6',
                                                }}
                                              >
                                                {new Date(data?.next_billing_date).toDateString()}
                                              </span>{' '}
                                              and Updated daily budget is{' '}
                                              <span
                                                style={{
                                                  color: '#9B26A6',
                                                }}
                                              >
                                                ${data?.next_cycle_daily_amount}
                                              </span>
                                            </SubscriptionText>
                                          </Col>
                                        )} */}
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        xxxl={12}
                                      >
                                        <PromotedOuterContainer>
                                          <Container
                                            style={{
                                              padding: '0px',
                                              margin: '0px',
                                            }}
                                          >
                                            <Row>
                                              <Col xs={12} sm={12} md={12}>
                                                <TopPromotedContainer>
                                                  {promotedNumberData?.length > 0 &&
                                                    promotedNumberData
                                                      .filter(
                                                        (item) =>
                                                          item.campaignId === data?.campaign_id,
                                                      )
                                                      .map((numberData) => {
                                                        return (
                                                          numberData?.promotedData.length > 0 &&
                                                          numberData?.promotedData.map(
                                                            (data, index) => {
                                                              return (
                                                                <NumberBox
                                                                  uniqueKey={index}
                                                                  key={
                                                                    videoIDUnique + 'number' + index
                                                                  }
                                                                  numberText={data?.numberText}
                                                                  numberSpanText={
                                                                    data?.numberSpanText
                                                                  }
                                                                  descriptionText={
                                                                    data?.descriptionText
                                                                  }
                                                                />
                                                              );
                                                            },
                                                          )
                                                        );
                                                      })}
                                                </TopPromotedContainer>
                                              </Col>
                                            </Row>
                                            <ControlButtons
                                              pickerKind={(kind) => {
                                                setPickerKind(kind);
                                              }}
                                              data={data}
                                              rePromoteLoader={rePromoteLoader}
                                              stopPromoteLoader={stopPromoteLoader}
                                              rePromotedVideo={(data) => rePromotedVideo(data)}
                                              stopPromoteVideos={(data) => stopPromoteVideos(data)}
                                              pickerShowStop={(amount) => {
                                                setPickerVisible(true);
                                                setSubscriptionMessage();
                                                setBudgetPicker(amount);
                                              }}
                                              pickerShowIncrease={(amount) => {
                                                setPickerVisible(true);
                                                setIncreaseData(data);
                                                setBudgetPicker(amount);
                                              }}
                                            />
                                          </Container>
                                        </PromotedOuterContainer>
                                      </Col>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            );
                          })}
                      </GridRow>
                    </GridContainer>
                  </VideoFirstContainer>
                  {showVideoLoader && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="loader"></div>
                    </div>
                  )}
                </TabsContent>
                <BottomPicker
                  kind={pickerKind}
                  visible={pickerVisible}
                  doneButton={doneButton}
                  buttonLoader={buttonLoader}
                  stopPromoteLoader={stopPromoteLoader}
                  subscriptionMessage={subscriptionMessage}
                  budgetAmount={budgetPicker}
                  onDismiss={() => {
                    setDoneButton(false);
                    setSubscriptionMessage('');
                    setPickerVisible(false);
                    setBudgetPicker();
                  }}
                  onSubmit={(budgetValue) => {
                    increaseBudget(budgetValue);
                  }}
                  onSubmitTop={(data) => {
                    stopPromoteVideos(data);
                  }}
                  onDoneButton={(value) => {
                    setPickerVisible(false);
                    setDoneButton(value);
                    setSubscriptionMessage('');
                    setBudgetPicker();
                  }}
                />
              </VideoOuterContainer>
            </Tabs>
          </div>
        ) : (
          <>
            {skeleton && pageState === 1 && (
              <>
                <VideoOuterContainer>
                  <VideoFirstSkeletonContainer>
                    <GridContainer>
                      <GridRow gutterWidth={16}>{renderSkeleton()}</GridRow>
                    </GridContainer>
                  </VideoFirstSkeletonContainer>
                </VideoOuterContainer>
              </>
            )}
            <VideoOuterContainer>
              <VideoSecondContainer>
                <GridContainer>
                  <GridRow gutterWidth={16}>
                    {allVideosWithLoader?.length > 0 &&
                      allVideosWithLoader.map((data, index) => {
                        const videoTime =
                          data?.video_duration !== null
                            ? data?.video_duration.includes(':')
                              ? `${parseInt(data?.video_duration.split(':')[2])} Sec`
                              : `${parseInt(data?.video_duration)} Sec`
                            : '';

                        return (
                          <>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              xxl={4}
                              xxxl={4}
                              key={data?.video_id}
                              style={{
                                padding: '0px',
                                margin: '0px 0px 20px',
                              }}
                            >
                              <div className="cards">
                                <div className="card">
                                  <Col
                                    key={data?.video_id}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    xxxl={12}
                                    style={{
                                      padding: '0px',
                                    }}
                                  >
                                    <VideoDisplay
                                      data={data}
                                      activeTab={activeTab}
                                      isLast={allVideoFetch?.allVideos.length - 1 === index}
                                      videoTime={videoTime}
                                      nextPage={nextPage}
                                      allVideoFetch={allVideoFetch}
                                      index={index}
                                      facebookLoader={facebookLoader}
                                      buttonLoader={buttonLoader}
                                      kind="video"
                                      postFacebookUpload={(videoUrl, image, id, time, state) =>
                                        postFacebookUpload(videoUrl, image, id, time, state)
                                      }
                                      postYoutubeUpload={(videoUrl, image, id, time, state) =>
                                        postYoutubeUpload(videoUrl, image, id, time, state)
                                      }
                                      navigatePromote={(state) => navigatePromote(state)}
                                    />
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </GridRow>
                </GridContainer>
                {showVideoLoader && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
              </VideoSecondContainer>
            </VideoOuterContainer>
          </>
        )}
      </ContainerOuter>
      {(activeTab !== 'tab1' || !localTabView) && (
        <StickyButton
          facebookAuthentication={facebookAuthentication}
          youtubeAuthentication={youtubeAuthentication}
          uploadVideo={uploadVideo}
        />
      )}
    </>
  );
}

export default VideosMain;
