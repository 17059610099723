import styled from 'styled-components';

const ButtonContainer = styled.button`
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  cursor: pointer;
  justify-content: center;
  background: ${(props) => (props.disabled === true ? '#e7e7e7' : props.theme.colors.primary)};
  align-items: center;
  border: none;

  ${(props) =>
    props.kind === 'landing'
      ? `
      padding: 12px 20px;
      border-radius: 8px;
`
      : props.kind === 'text'
      ? `
      background: transparent;
      position: absolute;
      left: 0;
      bottom: -4px;
      right: inherit;
      width: auto;
      `
      : props.kind === 'upload'
      ? `
      border-radius: 8px;
      border: 1px solid #D9DDE3 !important;
      background: transparent;
      padding:10px 12px;
      width:192px;
      `
      : props.kind === 'text-remove'
      ? `
      background: transparent;
      position: absolute;
      left: inherit;
      top: -10px;
      right: 0;
      width: auto;
      `
      : props.kind === 'round'
      ? `
      padding: 12px 20px;
      border-radius: 30px;
      @media ${props.theme.devices.md} {
        padding: 10px 15px;
      }
      @media ${props.theme.devices.lg} {
        padding: 18px 25px;
        font-size: 16px;
        line-height: 16px;
      }
`
      : props.kind === 'border'
      ? `
      background: none;
      border: 2px solid ${props.theme.colors.primary};
      padding: 16px 0px;
     
`
      : props.kind === 'mini'
      ? `
      background: #9B26A6;
      border: ${props.disabled ? '1px solid #9B26A6' : `1px solid ${props.theme.colors.primary}`};
      background-color: ${props.disabled ? '#9B26A6' : '#9B26A6'};
      height: 40px;
      padding: 8px 13px;
      width: 40px;
      margin: -10px 0px 0px 15px;`
      : props.kind === 'mini-round'
      ? `
      background: #f4f4f4;
      border: none;
      position: absolute;
      right:20px;
      top: 10px;
      border-radius: 50%;
      height: 30px;
      padding: 8px 13px;
      width: 30px;
      margin: 0px 0px 0px 15px;
      @media ${props.theme.devices.md} {
      height: 40px;
      width: 40px;
      right:20px;
      top: 12px;
      }
      @media ${props.theme.devices.lg} {
        width: 35px;
      height: 35px;
      }
`
      : props.kind === 'mini-close'
      ? `
      background: ${props.disabled ? '#e7e7e7' : '#E11900'};
      border: 1px solid ${props.disabled ? '#E11900' : '#FFFFFF'};
      width: 40px;
      padding: 14px 28px;
      margin: 0px 10px 0px 0px;
      `
      : props.kind === 'secondary'
      ? `
      background: #F4F4F4;
      border-radius: 4px;
      padding: 8px 20px;
      width: fit-content;
      height: 35px;
      border: none;`
      : props.kind === 'promote'
      ? `
      padding: 4px 16px;
      width: 6.5rem;
      height: 2rem;
      border-radius: 0.25rem;
      @media ${props.theme.devices.md} {
        height: 40px;
        width:120px;
        padding: 10px 28px;
      }
`
      : `
      border: none;
      height: 50px;
      @media ${props.theme.devices.md} {
        height: 50px;
      }
      @media ${props.theme.devices.lg} {
        height: 50px;
      }
`}
  &:active {
    background: ${(props) =>
      props.disabled ? 'none' : props.kind === 'secondary' ? '#cecece' : 'transparent'};
  }

  &:focus {
    outline: none;
    border: ${(props) =>
      props.kind === 'mini' || props.kind === 'border'
        ? `1px solid ${props.theme.colors.primary}`
        : 'none'};
  }
`;

const IconContainer = styled.div`
  margin-right: 4px;
  display: flex;
  @media ${(props) => props.theme.devices.md} {
    margin-right: 10px;
  }
`;

const ButtonText = styled.span`
  line-height: 16px;
  margin: 0px;
  color: ${(props) => (props.disabled ? '#787878' : props.theme.colors.light)};
  font-size: 1rem;
  font-weight: 500;
  ${(props) =>
    props.kind === 'mini'
      ? `color: ${props.theme.colors.light};`
      : props.kind === 'border'
      ? `color: ${props.disabled ? props.theme.colors.light : props.theme.colors.primary};`
      : props.kind === 'mini-round'
      ? `color: ${props.theme.colors.quaternary};`
      : props.kind === 'mini-close'
      ? `display: flex;`
      : props.kind === 'secondary'
      ? `color: ${props.theme.colors.dark};`
      : props.kind === 'text'
      ? `color: ${props.theme.colors.primary}; font-size:12px;`
      : props.kind === 'text-remove'
      ? `color: ${props.theme.colors.lightGray}; font-size:12px;`
      : props.kind === 'upload'
      ? `color: ${props.theme.colors.primary};`
      : props.kind === 'promote'
      ? `letter-spacing: 0.1px;`
      : ``}
`;

const ButtonTextRound = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

export default function Button(props) {
  const { buttonText, kind, icon, disabled, buttonLoader, name, img } = props;
  return (
    <>
      <ButtonContainer kind={kind} disabled={disabled} {...props} name={name} id={name}>
        {props.children && props.children}
        {icon && <IconContainer>{icon}</IconContainer>}
        {img && <img src={img} width={20} height={20} style={{ marginRight: 10 }} />}
        {buttonLoader && (
          <div
            style={{
              marginRight: kind === 'promote' ? '4px' : '10px',
            }}
          >
            <div className="loader"></div>
          </div>
        )}
        {kind === 'round' ? (
          <ButtonTextRound kind={kind} disabled={disabled}>
            {buttonText}
          </ButtonTextRound>
        ) : (
          <ButtonText kind={kind} disabled={disabled}>
            {buttonText}
          </ButtonText>
        )}
      </ButtonContainer>
    </>
  );
}
