import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-size: ${(props) => props.theme.fonts.sm.p};
  line-height: 1.125rem;
  font-weight: 400;
  width: 100%;
  border: none;

  ${(props) =>
    props.buttonLoaderZip
      ? `
  position: relative
  `
      : ``}
  ${(props) =>
    props.kind === 'background'
      ? `
          background: ${props.theme.colors.light};
          border-radius: 0.5rem;
          padding: 16px 0px 16px 30px;
        `
      : props.kind === 'full'
      ? `
          background: ${props.theme.colors.gray};
          color: ${props.theme.colors.dark};
          border-radius: 0.5rem;
          border: ${props.errorList === true ? '1px solid red' : 'none'};
          margin: ${props.prefixBox ? `0px 0px 0px 8px` : `0px 0px 0px 0px`};
          padding: 0px 0px 0px 16px;
          `
      : props.kind === 'mini'
      ? `
          background: ${props.theme.colors.gray};
          border-radius: 0.5rem;
          width:64px;
          text-align:center;
          `
      : props.kind === 'border-full' ||
        props.kind === 'border-full-grey' ||
        props.kind === 'border-mini'
      ? `
        border-radius: 4px;
        padding: 12px 14px;
        border: 1px solid #D9DDE3;`
      : `
        color: ${props.theme.colors.quaternary};
        border-bottom: 1px dashed ${props.theme.colors.quaternary};
        border-radius: 0px;
        padding: 16px 0px 5px 30px;
        @media ${props.theme.devices.md} {
          padding: 20px 0px 20px 40px;
        }
        @media ${props.theme.devices.lg} {
          padding: 41px 0px 30px 50px;
        }
        `}
          &:focus {
    outline: ${(props) =>
      props.kind === 'mini' ? `1px solid ${props.theme.colors.primary}` : 'none'};
  }
  ::placeholder {
    color: ${(props) =>
      props.kind === 'full' || props.kind === 'border-full'
        ? `${props.theme.colors.lightGray}`
        : `${props.theme.colors.lightGray}`};
    font-size: ${(props) => props.theme.fonts.sm.p};
    font-weight: 400;
    line-height: 16px;
  }
`;

const InputOuterContainer = styled.div`
  display: flex;
  margin: ${(props) => (props.kind === 'mini' ? '0px' : '8px 0px 15px')};
  height: ${(props) =>
    props.kind === 'full'
      ? '50px'
      : props.kind === 'dashed' ||
        props.kind === 'border-full' ||
        props.kind === 'border-full-grey' ||
        props.kind === 'border-mini'
      ? '40px'
      : '100%'};
  position: relative;
  width: 100%;
  width: ${(props) => props.kind === 'mini' && '60px'};
  @media ${(props) => props.theme.devices.md} {
    width: ${(props) => props.kind === 'mini' && '80px'};
    height: ${(props) =>
      props.kind === 'full'
        ? '50px'
        : props.kind === 'dashed' ||
          props.kind === 'border-full' ||
          props.kind === 'border-full-grey' ||
          props.kind === 'border-mini'
        ? '40px'
        : '100%'};
  }
  @media ${(props) => props.theme.devices.lg} {
    width: ${(props) => props.kind === 'mini' && '100px'};
    height: ${(props) =>
      props.kind === 'full'
        ? '50px'
        : props.kind === 'dashed' ||
          props.kind === 'border-full' ||
          props.kind === 'border-full-grey' ||
          props.kind === 'border-mini'
        ? '40px'
        : '100%'};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 17px;
  left: 7px;
  width: 15px;
  @media ${(props) => props.theme.devices.md} {
    width: 20px;
    left: 10px;
    top: ${(props) => (props.kind === 'dashed' ? '15px' : '22px')};
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 28px;
    top: ${(props) => (props.kind === 'dashed' ? '28px' : '18px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 22px;
    left: 10px;
    top: ${(props) => (props.kind === 'dashed' ? '30px' : '18px')};
  }
`;

const InputBox = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    placeholder,
    icon,
    type,
    kind,
    maxLength,
    prefixBox,
    errorList,
    value,
    postFix,
    onKeyPress,
    buttonLoaderZip,
  } = props;

  return (
    <>
      <InputOuterContainer kind={kind}>
        {icon && <IconContainer kind={kind}>{icon}</IconContainer>}
        <Input
          ref={ref}
          key={id}
          name={name}
          buttonLoaderZip={buttonLoaderZip}
          placeholder={placeholder}
          type={type}
          kind={kind}
          defaultValue={value}
          maxLength={maxLength}
          prefixBox={prefixBox}
          onKeyPress={onKeyPress}
          {...props}
          errorList={errorList}
        />
        {buttonLoaderZip && kind !== 'background' && <div className="loadingZip"></div>}
        {buttonLoaderZip && kind === 'background' && <div className="loadingZipHome"></div>}
        {postFix && postFix}
      </InputOuterContainer>
    </>
  );
});

InputBox.displayName = 'InputBox';

export default InputBox;
