const url = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://sbadsapp.infy.tv/api/v1';

if (url.endsWith('/')) {
  url = url.substring(0, api.length - 1);
}

const routes = {
  userCreated: '/user-registration/',
  sendOtp: '/send-otp/',
  verifyOtp: '/verify-otp/',
  login: '/login-verify-otp/',
  logout: '/logout/',
  searchBusiness: '/business-search/',
  businessDetail: '/place-details/',
  saveBusiness: '/business/',
  uploadBusiness: '/upload/',
  zipCodes: '/households/',
  categoriesChannel: '/categories/',
  scriptGenerate: '/script-generate/',
  createVideo: '/synthesia-video/',
  campaignVideo: '/campaign-video/',
  campaignStatus: '/campaign-status/',
  createCampaign: '/campaign/',
  paymentCal: '/get-payment-cal/',
  paymentIntent: '/payment-intent/',
  conversionMode: '/conversion-mode/',
  statistics: '/conversion/',
  youtubeToken: '/youtube-token/',
  videoYoutube: '/video-youtube/',
  youtubeUpload: '/youtube-video/',
  facebookToken: '/facebook-oauth/',
  videoFacebook: '/facebook-video/',
  facebookUpload: '/facebook-upload/',
  stripeSubscription: '/stripe-subscription/',
  presignedUrl: '/presigned-url/',
  checkoutSession: '/checkout-session/',
  billingDetail: '/customer-portal/',
  loginTokenUrl: '/login-token/',
  zipCodeNearBy: '/zipcode-near-by/?zip_code=',
  // googleToken: '/google-token/',
  // googleAuth: '/google-verify-authentication/',
  googleBusinessList: '/get-business-list/',
  googleLogin: '/google-login/',
};

const payloadMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

export default function api(action, method, payload, params, multipart, postUrl) {
  return new Promise(function (resolve, reject) {
    const header = {
      'Authorization': `Bearer ${localStorage.getItem('loginToken')}`,
      'Content-Type': 'application/json',
    };
    const multipartHeader = {
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
    };
    const options = {
      method: method,
      headers: multipart === 'multipart' ? multipartHeader : header,
    };
    const postOptions = {
      method: method,
      headers: {
        'Content-Type': 'video/mp4',
      },
    };
    if (payloadMethods.includes(method)) {
      if (postUrl) {
        postOptions.body = payload;
      } else {
        options.body = multipart === 'multipart' ? payload : JSON.stringify(payload);
      }
    }

    params !== ''
      ? fetch(
          postUrl ? postUrl : url + `${routes[action]}${params}`,
          postUrl ? postOptions : options,
        )
          .then((response) => {
            if (postUrl) {
              return resolve(response);
            } else {
              return response.json();
            }
          })
          .then((data) => resolve(data))
          .catch((error) => reject(error))
      : fetch(postUrl ? postUrl : url + routes[action], postUrl ? postOptions : options)
          .then((response) => {
            if (postUrl) {
              return resolve(response);
            } else {
              return response.json();
            }
          })
          .then((data) => resolve(data))
          .catch((error) => reject(error));
  });
}
