function rangeDisplay(rangeValue) {
  if (rangeValue[0] > 12) {
    const newValues = [...rangeValue];
    newValues[0] = `${rangeValue[0] - 12}${rangeValue[0] === 24 ? 'AM' : 'PM'}`;
    newValues[1] = `${rangeValue[1] - 12}${rangeValue[1] === 24 ? 'AM' : 'PM'}`;
    return newValues;
  } else if (rangeValue[1] > 12) {
    const newValues = [...rangeValue];
    newValues[1] = `${rangeValue[1] - 12}${rangeValue[1] === 24 ? 'AM' : 'PM'}`;
    if (rangeValue[0] === 0) {
      newValues[0] = `${12}AM`;
    } else {
      newValues[0] = `${rangeValue[0]}${rangeValue[0] === 12 ? 'PM' : 'AM'}`;
    }
    return newValues;
  } else {
    if (rangeValue[0] === 0) {
      const newValues = [...rangeValue];
      newValues[0] = `${12 - rangeValue[0]}AM`;
      if (rangeValue[1] === 0) {
        newValues[1] = `${12}AM`;
      } else {
        newValues[1] = `${rangeValue[1]}${rangeValue[1] === 12 ? 'PM' : 'AM'}`;
      }
      return newValues;
    } else if (rangeValue[1] === 24) {
      const newValues = [...rangeValue];
      newValues[1] = rangeValue[1] - 12;
      return newValues;
    } else {
      const newValues = [...rangeValue];
      newValues[1] = `${rangeValue[1]}${rangeValue[1] === 12 ? 'PM' : 'AM'}`;
      newValues[0] = `${rangeValue[0]}${rangeValue[0] === 12 ? 'PM' : 'AM'}`;
      return newValues;
    }
  }
}

export default rangeDisplay;
