import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 8 8"
      style={{
        verticalAlign: 'middle',
      }}
    >
      <path
        stroke="#9B26A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 .742v6.25M6.667 3.409L4 .742M1.332 3.409L3.999.742"
      ></path>
    </svg>
  );
}

export default Icon;
