import styled from 'styled-components';

import Video from './video';

import { iconPath } from '../assets/icons';
import { ReactComponent as PlayIconNew } from '../assets/images/play-icon.svg';

const VideoInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.promotedFlag ? '16px auto 0px auto' : '0px 0px 0px 0px')};
  @media ${(props) => props.theme.devices.md} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '0px auto 0px 0px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '0px auto 0px 0px')};
  }
`;

const SourceLabel = styled.p`
  margin: 0px;
  font-size: 12px;
  span {
    color: #8a94a6;
    margin-right: 5px;
  }
`;

export default function VideoDisplay({
  data,
  nextPagePromoted,
  isLast,
  nextPage,
  videoTime,
  index,
  kind,
  facebookLoader,
  buttonLoader,
  postFacebookUpload,
  postYoutubeUpload,
  navigatePromote,
  activeTab,
}) {
  const date = new Date().toDateString();

  const { FacebookMiniIcon, YoutubeMiniIcon, PlayIcon, UploadDeviceIcon } = iconPath;

  return (
    <>
      <VideoInnerContainer key={data?.videoUrl} id={index}>
        <Video
          activeTab={activeTab}
          nextPagePromoted={nextPagePromoted}
          nextPage={nextPage}
          isLast={isLast}
          icon={
            data?.video_source_from_name === 'Facebook' ? (
              <SourceLabel>
                <span>Source:</span>Facbook
              </SourceLabel>
            ) : data?.video_source_from_name === 'Youtube' ? (
              <SourceLabel>
                <span>Source:</span>Youtube
              </SourceLabel>
            ) : data?.video_source_from_name === 'Device' ? (
              <SourceLabel>
                <span>Source:</span>Device
              </SourceLabel>
            ) : (
              ''
            )
          }
          playIcon={data?.loaderIcon ? '' : <PlayIconNew />}
          date={data?.created_at ? new Date(data?.created_at).toDateString() : date}
          thumbnailUrl={data?.thumbnail_url}
          videoImage={data?.image}
          videoUrl={data?.video_url}
          videoTime={videoTime}
          flag={
            (data?.campaign_status_name === 'runnable' &&
              data?.campaign_id !== null &&
              data?.has_stripe_subscription === true) ||
            (data?.campaign_status_name === 'offline' &&
              data?.campaign_id !== null &&
              data?.has_stripe_subscription === true)
              ? true
              : false
          }
          type={data?.video_source_from_name}
          loaderIcon={data?.loaderIcon ? data?.loaderIcon : ''}
          loaderButton={
            buttonLoader || (data?.id == facebookLoader?.postVideoId && facebookLoader?.flag)
          }
          campaignStatusId={data?.campaign_status_id}
          campaignStatus={data?.campaign_status_name}
          campaignVerified={data?.is_campaign_verified}
          budgetAmount={data?.budget_amount}
          kind={kind}
          onClick={() => {
            const videoTimeCheck = parseInt(videoTime.split('Sec')[0]);
            const state = {
              date: data?.created_at ? new Date(data?.created_at).toDateString() : date,
              videoUrl: data?.video_url,
              videoImage: data?.image,
              videoTime: videoTime,
              thumbnailUrl: data?.thumbnail_url,
              campaignId: data?.campaign_id,
              isPromoted: data?.is_promoted,
              type: data?.video_source_from_name,
              videoId: data?.id,
              callValue: data?.call_to_action_value,
              callId: data?.call_to_action,
              callName: data?.call_to_action_name,
            };
            if (
              videoTimeCheck > 30 ||
              (videoTimeCheck < 15 && data?.video_source_from_name !== 'synthesia')
            ) {
              alert(`This Video Can't be Promoted. Please Upload Video between 15 to 30 Seconds`);
            } else {
              if (data?.video_source_from_name === 'Facebook' && data?.facebookUpload) {
                localStorage.setItem('isReload', true);
                postFacebookUpload(
                  data?.video_url,
                  data?.thumbnail_url,
                  data?.id,
                  videoTime,
                  state,
                );
              } else if (data?.video_source_from_name === 'Youtube' && data?.youtubeUpload) {
                localStorage.setItem('isReload', true);
                postYoutubeUpload(data?.video_url, data?.thumbnail_url, data?.id, videoTime, state);
              } else {
                navigatePromote(state);
              }
            }
          }}
        />
      </VideoInnerContainer>
    </>
  );
}
