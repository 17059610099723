import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

// Styled components
const RadioButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #d9dde3;
  box-shadow: 0px 12px 16px -4px rgba(24, 39, 75, 0.1);
  border-radius: 8px;
  /* @media ${(props) => props.theme.devices.md} {
    width: 50%;
  } */
  ${(props) =>
    props.checked &&
    css`
      border: 1px solid #731780;
    `}
`;

const RadioButtonInput = styled.input`
  margin-right: 0px;
  opacity: 0;
  position: absolute;
`;

const RadioButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const RadioButtonName = styled.div`
  font-size: 16px;
`;

// RadioButton component
const RadioButton = ({ name, image, checked, onChange, value }) => {
  const wrapperClassName = classNames('', {
    selected: checked,
  });
  return (
    <RadioButtonWrapper className={wrapperClassName} checked={checked}>
      <RadioButtonInput type="radio" value={value} checked={checked} onChange={onChange} />
      <RadioButtonLabel>
        <RadioButtonImage src={image} alt={name} />
        <RadioButtonName>{name}</RadioButtonName>
      </RadioButtonLabel>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
