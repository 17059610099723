import { createContext, useContext, useState } from 'react';
import api from './api';

const Authentication = createContext({});

function useAuthentication() {
  return {
    userRegistration(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('sendOtp', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },

    userCreated(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('userCreated', 'POST', payload, '');

          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    verifyOtp(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('verifyOtp', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    login(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('login', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    detailBusiness(placeBusinessId) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('businessDetail', 'GET', '', placeBusinessId);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    searchBusiness(searchBusinessValue) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('searchBusiness', 'GET', '', searchBusinessValue);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    saveBusiness(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('saveBusiness', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getBusiness() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('saveBusiness', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    editBusiness(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('saveBusiness', 'PUT', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    fileUploadBusiness(payload, multipart) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('uploadBusiness', 'POST', payload, '', multipart);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    createVideo(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createVideo', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getSynthesiaVideo(campaignID) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createVideo', 'GET', '', campaignID);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    preSignedUrl(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('presignedUrl', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    preSignedPost(payload, postUrl) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('presignedPost', 'PUT', payload, '', '', postUrl);
          resolve({
            code: response.status,
            // data: response.data,
            // success: response.success,
            // message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },

    googleLogin(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('googleLogin', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    googleBusiness() {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('loginToken') ?? ''}`);
      const options = {
        headers: myHeaders,
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
       
        fetch('https://adsapp.infy.tv/api/v1/get-business-list/', options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    companyDetailsAPI(payload) {
      const options = {
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
        fetch(`https://adsapp.infy.tv/api/v1/customer_info?domain=${payload}`, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    youtubeTokenGenerate(domain) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('youtubeToken', 'GET', '', `?domain=${domain}`);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getVideoYoutube() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('videoYoutube', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    youtubeUpload(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('youtubeUpload', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },

    facebookTokenGenerate() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('facebookToken', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getFacebookVideo() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('videoFacebook', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    facebookUpload(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('facebookUpload', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    zipCodes(zipCode) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('zipCodes', 'GET', '', zipCode);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    zipCodeNearBy(zipCode) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('zipCodeNearBy', 'GET', '', zipCode);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getStatistics(videoId) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('statistics', 'GET', '', videoId);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    scriptGenerate() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('scriptGenerate', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getBillingDetail() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('billingDetail', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    categoriesChannel(type) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('categoriesChannel', 'GET', '', type);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCampaignVideo(type) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('campaignVideo', 'GET', '', type);
          resolve({
            code: response.code,
            data: response.data,
            pagination: response.paginator,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    postKeyCampaignVideo(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('campaignVideo', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    postCampaignVideo(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createCampaign', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    retrieveCampaignVideo(videoId) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createCampaign', 'GET', '', videoId);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    retrieveCampaignVideoDashboard(videoId) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createCampaign', 'GET', '', videoId);
          resolve({
            code: response.code,
            data: response.data,
            pagination: response.paginator,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    editCampaignVideo(params, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('createCampaign', 'PUT', payload, params);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    campaignStatus(campaignId, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('campaignStatus', 'PUT', payload, campaignId);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentCal(amount) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('paymentCal', 'GET', '', amount);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getConversionMode() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('conversionMode', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    intentPayment(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('paymentIntent', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    checkoutSession(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('checkoutSession', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    loginTokenUrl(token) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('loginTokenUrl', 'GET', '', token);
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    postStripeSubscription(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('stripeSubscription', 'POST', payload, '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    logout() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api('logout', 'GET', '', '');
          resolve({
            code: response.code,
            data: response.data,
            success: response.success,
            message: response.message[0],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  };
}

export function Provider({ children }) {
  const [videoFetch, setVideoFetch] = useState([]);
  const [allVideoFetch, setAllVideoFetch] = useState({
    allVideos: [],
    promotedVideos: [],
    loaderSynthesia: [],
  });
  const [pageState, setPageState] = useState(1);
  const [promotedPageState, setPromotedPageState] = useState(1);
  const [totalPageState, setTotalPageState] = useState();
  const [totalPromotedPageState, setTotalPromotedPageState] = useState();
  const [promotedNumberData, setPromotedNumberData] = useState([]);

  return (
    <Authentication.Provider
      value={{
        ...useAuthentication(),
        videoFetch,
        promotedPageState,
        setPromotedPageState,
        pageState,
        setPageState,
        totalPageState,
        setTotalPageState,
        totalPromotedPageState,
        setTotalPromotedPageState,
        setVideoFetch,
        promotedNumberData,
        setPromotedNumberData,
        allVideoFetch,
        setAllVideoFetch,
      }}
    >
      {children}
    </Authentication.Provider>
  );
}

export default function Consumer() {
  return useContext(Authentication);
}
