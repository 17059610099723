import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 410.73 410.89"
      style={{
        filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
      }}
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M409.72 193.42v24c-.25 1.42-.53 2.83-.73 4.25-1.22 8.55-1.85 17.22-3.69 25.62-11.68 53.06-39.74 95.66-84.57 126.41-48.14 33-101.34 43.4-158.48 31.34-45.4-9.58-83.19-32.61-113-68.09-36.94-44-52.69-95.12-47.24-152.28 3.85-40.47 19.06-76.69 44.94-108.11C79.12 37.51 120.16 13.2 170.06 4.19c7.69-1.39 15.5-2.14 23.25-3.19h23.95l8.65 1c33.31 3.7 64.23 14.28 92 33.14 48.17 32.73 78 77.72 88.58 135.11 1.47 7.64 2.19 15.44 3.23 23.17zM24.88 205.2c-.17 99.4 80.85 180.56 180.34 180.65 99.28.08 180.21-80.62 180.55-180S305.18 25.21 205.62 24.96C106.25 24.7 25.05 105.68 24.88 205.2z"
      ></path>
      <path
        fill="#fff"
        d="M-345.65 146l177 118-177 118zm23.86 191.33l110-73.33-110-73.29z"
        transform="translate(491.36 -58.65)"
      ></path>
      <path
        fill="#fff"
        d="M-330.1 348.45V179.67L-203.5 264z"
        transform="translate(491.36 -58.65)"
      ></path>
    </svg>
  );
}

export default Icon;
