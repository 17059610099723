import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <path
        fill="#9B26A6"
        d="M17.621 6.852l-7.312 3.351c-.356.203-.61.457-.813.813l-3.351 7.312c-.356.863.507 1.727 1.37 1.371l7.313-3.351c.356-.203.61-.457.813-.813l3.351-7.312c.356-.864-.508-1.727-1.37-1.371zm-3.91 7.566c-.61.66-1.676.66-2.285 0a1.566 1.566 0 010-2.285c.61-.66 1.676-.66 2.285 0 .66.61.66 1.676 0 2.285zM12.594.656C5.637.656 0 6.344 0 13.25c0 6.957 5.637 12.594 12.594 12.594 6.906 0 12.594-5.637 12.594-12.594C25.188 6.344 19.5.656 12.594.656zm0 22.75A10.121 10.121 0 012.438 13.25c0-5.586 4.519-10.156 10.156-10.156 5.586 0 10.156 4.57 10.156 10.156 0 5.637-4.57 10.156-10.156 10.156z"
      ></path>
    </svg>
  );
}

export default Icon;
