import Select, { components } from 'react-select';
import { iconPath } from '../assets/icons';

export default function ReactSelect({
  onChange,
  loader,
  kind,
  value,
  options,
  onInputChange,
  search,
  inputValue,
}) {
  const { DropdownIcon } = iconPath;

  function DropdownIndicator(props) {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIcon />
      </components.DropdownIndicator>
    );
  }

  const CustomStyle = {
    indicatorSeparator: ({ provided }) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: ({ provided }) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: ({ provided }) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    control: ({ ...provided }) => ({
      ...provided,
      'padding': '8px',
      'fontSize': '0.875rem',
      'height': '40px',
      '@media (min-width: 768px)': {
        height: '40px',
        fontSize: '14px',
      },
      '@media (min-width: 1024px)': {
        height: '40px',
        fontSize: '14px',
      },
    }),
    menuList: ({ ...provided }) => ({
      ...provided,
      'fontSize': '0.87rem',
      '@media (min-width: 768px)': {
        height: '120px',
        fontSize: '14px',
      },
      '@media (min-width: 1024px)': {
        height: '120px',
        fontSize: '14px',
      },
    }),
  };

  const CustomStyleBusiness = {
    indicatorSeparator: ({ provided }) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: ({ provided }) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: ({ provided }) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: ({ provided }) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    loadingIndicator: ({ provided }) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      'border': state.isFocused ? 'none' : 'none',
      'box-shadow': state.isFocused ? 'none' : 'none',
      'border-bottom': '1px dashed #a2a2a2',
      'border-bottom-left-radius': '0px',
      'border-bottom-right-radius': '0px',
      'padding': '8px',
      'fontSize': '0.875rem',
      'height': '40px',
      '@media (min-width: 768px)': {
        height: '50px',
        fontSize: '1.25rem',
      },
      '@media (min-width: 1024px)': {
        height: '60px',
        fontSize: '1.56rem',
      },
    }),
    menuList: ({ ...provided }) => ({
      ...provided,
      'fontSize': '0.87rem',
      '@media (min-width: 768px)': {
        height: '50px',
        fontSize: '1.25rem',
      },
      '@media (min-width: 1024px)': {
        height: '60px',
        fontSize: '1.56rem',
      },
    }),
  };

  return (
    <>
      <Select
        isSearchable={search ? true : false}
        styles={kind === 'searchBusiness' ? CustomStyleBusiness : CustomStyle}
        components={{ DropdownIndicator }}
        defaultValue={value}
        onChange={onChange}
        onInputChange={onInputChange}
        value={value}
        options={options}
        inputValue={inputValue}
        isLoading={loader}
        noOptionsMessage={() => null}
      />
    </>
  );
}
