import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" fill="none" viewBox="0 0 23 24">
      <path
        fill="#9B26A6"
        d="M22.326 17.137l-5.031-2.157a1.327 1.327 0 00-.45-.09c-.314 0-.628.18-.808.405l-2.246 2.74a16.67 16.67 0 01-7.951-7.951l2.74-2.246c.225-.18.404-.494.404-.854 0-.134-.045-.27-.09-.404L6.739 1.55A1.135 1.135 0 005.705.875C5.66.875 5.57.92 5.48.92L.81 1.998C.314 2.133 0 2.538 0 3.031a20.801 20.801 0 0020.844 20.844c.494 0 .898-.314 1.033-.809l1.078-4.671v-.225c0-.45-.27-.854-.629-1.033z"
      ></path>
    </svg>
  );
}

export default Icon;
