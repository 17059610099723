import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { iconPath } from '../assets/icons';
import LogoPlaceholde from '../assets/images/logo-placeholder-image.png';

import { Input, Button, Label, Header, Radio } from '../components';

const Container = styled.div`
  padding: 16px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 30px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const FormOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  //margin-top: 10px;
  @media ${(props) => props.theme.devices.xl} {
    padding: 0px 200px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 0px 400px;
  }
`;

const FormInnerContainer = styled.div`
  margin-bottom: 27px;
`;

const LabelText = styled.label`
  font-size: ${(props) => props.theme.fonts.sm.p};
  color: ${(props) => props.theme.colors.tt1};
  font-weight: 500;
  margin: 0px 0px 8px 0px;
`;

const LabelTextColor = styled.span`
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 400;
  margin: 0px 0px 8px 0px;
  color: ${(props) => (props.labelColor >= props.minimumValue ? 'green' : 'red')};
`;

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.businessPage === 'business-script' ? '32px' : '0px')};
  @media ${(props) => props.theme.devices.md} {
    justify-content: flex-start;
  }
`;

const RadioInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #eff1f3;
  padding: 6px 15px;
  border-radius: 10px;
  height: 46px;
  align-items: center;
`;

const InputSuffixContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  margin: 0px auto;
  background: ${(props) => props.theme.colors.light};
  z-index: 9;
  box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.25);
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  max-width: 870px;
  margin: 0 auto;
  /* @media ${(props) => props.theme.devices.xxl} {
    padding: 8px 440px;
  } */
`;

const UploadBox = styled.input``;

const UploadLabel = styled.label`
  position: absolute;
  cursor: pointer;
  width: inherit;
`;

const TextRequiredError = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.t};
  font-weight: 400;
  color: #e40606;
  margin: 10px 0px 0px 0px;
  text-align: center;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  } */
`;

const TextRequiredErrorCondition = styled(TextRequiredError)`
  margin-top: 0px;
  text-align: initial;
`;

const UploadOuterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ImageOuterContainer = styled.div`
  width: 119px;
  height: 119px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
`;

const ImageOuterMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ClearBitText = styled.a`
  font-size: 12px;
  cursor: pointer;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.t};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.4rem;
  }
`;

const SelectedFileImage = styled.img`
  object-fit: cover;
`;

const BillingContainer = styled.div`
  margin-bottom: 70px;
`;

const LinkBillText = styled.h3`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0px;
  color: ${(props) => props.theme.colors.dark};
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 1.4rem;
  } */
`;

const LinkBillTextSpan = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 1.4rem;
  } */
`;

const StepTitle = styled.h3`
  font-size: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 0px;
`;

const StepTitleCount = styled.span`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.light};
  width: 21px;
  height: 21px;
  font-size: 16px;
  padding: 3px;
  border-radius: 100%;
`;

const CallToAction = styled(RadioButtonContainer)`
  //gap: 10px;
  border-radius: 12px;
  border: 1px solid #d9dde3;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
`;

const CallToActionTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  padding: 20px 20px;
  margin: 0px;
  width: 100%;
  border-bottom: 1px solid #d9dde3;
  margin-bottom: 5px;
`;

const ErrorBottom = styled.div`
  padding: 0px;
  p {
    text-align: left;
  }
`;

const skeletonLoadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const SkeletonRound = styled.div`
  width: 100px; /* Adjust the width to your desired size */
  height: 100px; /* Adjust the height to your desired size */
  border-radius: 50%; /* To create a round shape */
  background: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 50%,
    #f6f7f8 100%
  ); /* Adjust the gradient colors as needed */
  background-size: 200px 100%;
  animation: ${skeletonLoadingAnimation} 1.5s infinite linear;
`;

export default function BusinessCommon({
  kind,
  businessDataGet,
  businessData,
  businessName,
  onChangeFile,
  handleSelectOptionalChange,
  onChangeBusiness,
  onChangeServices,
  onChangeProducts,
  onChangeMarketing,
  onChangeUsps,
  onClickRemoveLabel,
  addServices,
  generateScriptError,
  selectedFile,
  clearBit,
  buttonLoader,
  radioButton,
  radios,
  radioValue,
  radiosOptional,
  onRadioChange,
  saveBusinessData,
  servicesArrayList,
  productsArrayList,
  uspsArrayList,
  businessGetLoader,
  radioButtonOptional,
  radioValueCallOptional,
  errorText,
  buttonLoaderSave,
  edit,
  updateWebsites,
  minusCall,
  plusCall,
  businessEdit,
  businessSave,
  billingDetails,
  businessPage,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { ClosePromoteIcon } = iconPath;

  const usps = useRef();
  const services = useRef();
  const products = useRef();

  const BASE_URL = process.env.REACT_APP_API_URL
    ? 'https://infy-ads.s3.us-east-2.amazonaws.com/'
    : 'https://infy-ads.s3.us-east-2.amazonaws.com/';

  return (
    <>
      <Header kind={kind} />
      <Container>
        <FormOuterContainer>
          <FormInnerContainer>
            {location.pathname.startsWith('/business') && (
              <StepTitle>
                <StepTitleCount>2</StepTitleCount>
                Business & Ad Details
              </StepTitle>
            )}
            {location.pathname.startsWith('/generate-script') && (
              <StepTitle>
                <StepTitleCount>1</StepTitleCount>
                Generate Script
              </StepTitle>
            )}
            {businessGetLoader ? (
              <UploadOuterContainer>
                <ImageOuterMainContainer>
                  {' '}
                  <SkeletonRound />
                </ImageOuterMainContainer>
              </UploadOuterContainer>
            ) : (
              <UploadOuterContainer>
                {selectedFile ? (
                  <ImageOuterMainContainer>
                    <ImageOuterContainer>
                      <SelectedFileImage
                        src={
                          clearBit
                            ? selectedFile
                            : selectedFile.includes('https')
                            ? selectedFile
                            : `${BASE_URL}${selectedFile}`
                        }
                        height="100%"
                        width="100%"
                        alt=""
                      />
                    </ImageOuterContainer>
                    {/* {clearBit && (
                    <ClearBitText
                      href="https://clearbit.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Logos provided by Clearbit
                    </ClearBitText>
                  )} */}
                  </ImageOuterMainContainer>
                ) : (
                  <ImageOuterMainContainer>
                    <ImageOuterContainer>
                      <SelectedFileImage src={LogoPlaceholde} height="100%" width="100%" />
                    </ImageOuterContainer>
                  </ImageOuterMainContainer>
                )}
                <Button
                  buttonText="Upload New Logo"
                  kind="upload"
                  buttonLoader={buttonLoader}
                  disabled={buttonLoader}
                  name="upload"
                >
                  <UploadLabel htmlFor="files" onChange={(e) => onChangeFile(e)}>
                    <UploadBox
                      id="files"
                      type="file"
                      style={{ visibility: 'hidden' }}
                      name="file_name"
                      accept="image/*"
                    />
                  </UploadLabel>
                </Button>
              </UploadOuterContainer>
            )}
            {generateScriptError?.file?.length > 0 && (
              <TextRequiredErrorCondition
                style={{
                  marginTop: '10px',
                  textAlign: 'center',
                }}
              >
                {generateScriptError?.file}
              </TextRequiredErrorCondition>
            )}
          </FormInnerContainer>
          <FormInnerContainer
            style={{
              marginBottom: '0px',
            }}
          >
            <LabelText>Full Bussines Name</LabelText>
            <Input
              placeholder="Enter Full Bussines Name"
              type="text"
              id="businessName"
              kind="border-full-grey"
              defaultValue={businessDataGet?.name || businessName}
              name="name"
              onChange={(e) => {
                onChangeBusiness(e);
              }}
            />
            {generateScriptError?.name?.length > 0 && (
              <TextRequiredErrorCondition
                style={{
                  marginTop: '10px',
                }}
              >
                {generateScriptError?.name}
              </TextRequiredErrorCondition>
            )}
          </FormInnerContainer>

          <FormInnerContainer>
            <RadioButtonContainer
              style={{
                marginTop: '8px',
              }}
            >
              <LabelText>What Do You Sell?</LabelText>
              <RadioInnerContainer>
                <Radio
                  radioButtonData={radioButton}
                  radioValue={radioValue}
                  onChange={(e) => onRadioChange(e)}
                  kind="business"
                  radios={radiosOptional}
                />
              </RadioInnerContainer>
            </RadioButtonContainer>
          </FormInnerContainer>
          {radioValue !== 'products' && (
            <FormInnerContainer>
              <LabelText>
                Enter Services{' '}
                <LabelTextColor labelColor={saveBusinessData?.services?.length} minimumValue={2}>
                  ({saveBusinessData?.services?.length} out of 5)
                </LabelTextColor>
              </LabelText>
              <InputSuffixContainer>
                <Input
                  placeholder="e.g. Water Leakage, Household Installation, Gardening"
                  type="text"
                  ref={services}
                  kind="border-mini"
                  name="services"
                  value={
                    servicesArrayList[0] === ' '
                      ? servicesArrayList.split(' ').join('')
                      : servicesArrayList
                  }
                  onChange={(e) => {
                    onChangeServices(e);
                  }}
                />
                <Button
                  buttonText="+"
                  kind="mini"
                  name="services"
                  disabled={
                    (servicesArrayList?.length === 0 ||
                      saveBusinessData['services']?.length === 5) &&
                    true
                  }
                  onClick={() => {
                    addServices('services', servicesArrayList, saveBusinessData.services, services);
                  }}
                />
              </InputSuffixContainer>
              {generateScriptError?.services?.length > 0 && (
                <TextRequiredErrorCondition>
                  {generateScriptError?.services}
                </TextRequiredErrorCondition>
              )}
              {saveBusinessData?.services?.length > 0 &&
                saveBusinessData?.services.map((data, index) => {
                  return (
                    <Label
                      labelText={data}
                      key={index}
                      onClick={() =>
                        onClickRemoveLabel('services', index, saveBusinessData.services)
                      }
                    />
                  );
                })}
            </FormInnerContainer>
          )}
          {radioValue !== 'services' && (
            <FormInnerContainer>
              <LabelText>
                Product Name{' '}
                <LabelTextColor labelColor={saveBusinessData?.products?.length} minimumValue={3}>
                  ({saveBusinessData?.products?.length} out of 5)
                </LabelTextColor>
              </LabelText>
              <InputSuffixContainer>
                <Input
                  placeholder="e.g. Ceiling Fan, Stean, Iron"
                  type="text"
                  kind="border-mini"
                  ref={products}
                  value={
                    productsArrayList[0] === ' '
                      ? productsArrayList.split(' ').join('')
                      : productsArrayList
                  }
                  name="productNames"
                  onChange={(e) => {
                    onChangeProducts(e);
                  }}
                />
                <Button
                  buttonText="+"
                  disabled={
                    (productsArrayList?.length === 0 ||
                      saveBusinessData['products']?.length === 5) &&
                    true
                  }
                  name="products"
                  kind="mini"
                  onClick={() => {
                    addServices('products', productsArrayList, saveBusinessData.products, products);
                  }}
                />
              </InputSuffixContainer>
              {generateScriptError?.products?.length > 0 && (
                <TextRequiredErrorCondition>
                  {generateScriptError?.products}
                </TextRequiredErrorCondition>
              )}
              {saveBusinessData?.products?.length > 0 &&
                saveBusinessData?.products.map((data, index) => {
                  return (
                    <Label
                      labelText={data}
                      key={index}
                      onClick={() =>
                        onClickRemoveLabel('products', index, saveBusinessData.products)
                      }
                    />
                  );
                })}
            </FormInnerContainer>
          )}
          <FormInnerContainer>
            <LabelText>
              What are you known for:{' '}
              <LabelTextColor labelColor={saveBusinessData?.usps?.length} minimumValue={2}>
                ({saveBusinessData?.usps?.length} out of 5)
              </LabelTextColor>
            </LabelText>
            <InputSuffixContainer>
              <Input
                placeholder="e.g. Same day Appointment, 3 Month Guarantee, 24x7 Support"
                type="text"
                kind="border-mini"
                name="usps"
                ref={usps}
                value={uspsArrayList[0] === ' ' ? uspsArrayList.split(' ').join('') : uspsArrayList}
                onChange={(e) => {
                  onChangeUsps(e);
                }}
              />
              <Button
                buttonText="+"
                kind="mini"
                name="usps"
                disabled={
                  (uspsArrayList?.length === 0 || saveBusinessData['usps']?.length === 5) && true
                }
                onClick={() => {
                  addServices('usps', uspsArrayList, saveBusinessData.usps, usps);
                }}
              />
            </InputSuffixContainer>
            {generateScriptError?.usps?.length > 0 && (
              <TextRequiredErrorCondition>{generateScriptError?.usps}</TextRequiredErrorCondition>
            )}
            {saveBusinessData?.usps?.length > 0 &&
              saveBusinessData?.usps.map((data, index) => {
                return (
                  <Label
                    labelText={data}
                    key={index}
                    onClick={() => onClickRemoveLabel('usps', index, saveBusinessData.usps)}
                  />
                );
              })}
          </FormInnerContainer>
          <FormInnerContainer>
            {businessGetLoader && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="loader"></div>
              </div>
            )}
            <CallToAction businessPage={businessPage} className="call-to-action">
              <CallToActionTitle>Select the Call-To-Action:</CallToActionTitle>
              <Radio
                page={businessPage}
                type={'business-cta'}
                kind={'business-full'}
                mobileValue={radios
                  .filter((data) => data?.name === 'mobile')
                  .map((data) => {
                    return data?.value;
                  })}
                saveValue={radios
                  .filter((data) => data?.name === 'save')
                  .map((data) => {
                    return data?.value;
                  })}
                radioButtonData={radioButtonOptional}
                businessData={saveBusinessData}
                radioValue={radioValueCallOptional}
                onChange={(e) => {
                  handleSelectOptionalChange(e);
                }}
                radios={radios}
                updateWebsites={(e, v) => updateWebsites(e, v)}
                minusCall={(id, name) => minusCall(id, name)}
                plusCall={(name) => plusCall(name)}
              ></Radio>
              {generateScriptError?.email?.length > 0 && (
                <TextRequiredErrorCondition
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  {generateScriptError?.email}
                </TextRequiredErrorCondition>
              )}
              {generateScriptError?.radio?.length > 0 && (
                <TextRequiredErrorCondition
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  {generateScriptError?.radio}
                </TextRequiredErrorCondition>
              )}
              {generateScriptError?.address?.length > 0 && (
                <TextRequiredErrorCondition
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  {generateScriptError?.address}
                </TextRequiredErrorCondition>
              )}
              {generateScriptError?.websites?.length > 0 && (
                <TextRequiredErrorCondition
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  {generateScriptError?.websites}
                </TextRequiredErrorCondition>
              )}
            </CallToAction>
          </FormInnerContainer>
          {businessPage === 'business' && (
            <FormInnerContainer
              style={{
                marginBottom: `${localStorage.getItem('PromotedFlag') === 'true' ? '0px' : '70px'}`,
              }}
            >
              <LabelText>Marketing Partner Code</LabelText>
              <Input
                placeholder="Enter Marketing Partner code"
                type="text"
                id="partner"
                kind="border-full-grey"
                defaultValue={businessDataGet?.partner || businessData?.partner || null}
                name="partner"
                onChange={(e) => {
                  onChangeMarketing(e);
                }}
              />
            </FormInnerContainer>
          )}
          <ErrorBottom>
            {errorText && (
              <TextRequiredError style={{ marginTop: '0px', marginBottom: '15px' }}>
                {errorText}
              </TextRequiredError>
            )}
          </ErrorBottom>
          {businessPage === 'business' && localStorage.getItem('PromotedFlag') === 'true' && (
            <>
              <BillingContainer>
                <LabelText>Billing Information</LabelText>
                <hr />
                <LinkBillText>
                  To see invoices, change credit card, VAT or address,{' '}
                  <LinkBillTextSpan onClick={() => billingDetails()}>
                    please click here
                  </LinkBillTextSpan>
                </LinkBillText>
              </BillingContainer>
            </>
          )}
        </FormOuterContainer>
      </Container>
      <ButtonContainer>
        <ButtonInnerContainer>
          <Button
            buttonText={<ClosePromoteIcon />}
            kind="mini-close"
            onClick={() => {
              if (businessPage === 'business') {
                if (edit) {
                  navigate('/dashboard');
                } else {
                  if (
                    localStorage.getItem('googleOauth') === 'true' &&
                    localStorage.getItem('businessData') === 'true'
                  ) {
                    navigate('/select-business');
                  } else {
                    navigate('/search-business');
                  }
                }
              } else {
                navigate('/dashboard');
              }
            }}
          />
          {businessPage === 'business' ? (
            <Button
              name="save"
              buttonText="Save"
              onClick={edit ? businessEdit : businessSave}
              buttonLoader={buttonLoaderSave}
              disabled={buttonLoaderSave}
            />
          ) : (
            <Button
              name="generateScript"
              buttonText="Generate Script"
              onClick={businessEdit}
              buttonLoader={buttonLoaderSave}
              disabled={buttonLoaderSave}
            />
          )}
        </ButtonInnerContainer>
      </ButtonContainer>
    </>
  );
}
