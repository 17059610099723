import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';

import useAuthentication from '../helpers/authentication';

import { toastMessage } from '../components/toast';

const MyUploader = () => {
  let key = '';
  let durationVideo;
  const { preSignedUrl, postKeyCampaignVideo } = useAuthentication();

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const getUploadParams = async ({ file, meta }) => {
    const duration = await getVideoDuration(file);
    durationVideo = duration;

    const dataPresigned = await preSignedUrl();
    console.log(dataPresigned);
    if (dataPresigned.code === 200 && dataPresigned.success) {
      key = dataPresigned.data.key;
      return {
        url: dataPresigned.data.data,
        body: file,
        method: 'PUT',
      };
    }
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  const handleSubmit = async (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    postKeyCampaignVideo({
      key: key,
      title: 'Device Upload',
      video_duration: durationVideo,
    }).then(({ code, success, message }) => {
      if (success && code === 200) {
        alert('File Uploaded Successfully');
        toast.success(toastMessage.videoUploadSuccess);
      } else {
        console.log(message);
      }
    });

    // allFiles.forEach((f) => f.remove());
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*,audio/*,video/*"
    />
  );
};

export default MyUploader;
