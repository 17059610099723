import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row, setConfiguration } from 'react-grid-system';
import styled, { keyframes } from 'styled-components';

import consumerData from '../helpers/authentication';
import useAuthentication from '../helpers/authentication';

import { Button, Header, Radio } from '../components';

import { iconPath } from '../assets/icons';
import EditIcon from '../assets/images/editing.png';
import LogoPlaceholde from '../assets/images/logo-placeholder-image.png';
import Female from '../assets/images/synthesia_model_female.png';
import Male from '../assets/images/synthesia_model_male.png';

const OuterContainer = styled.div`
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  @media ${(props) => props.theme.devices.md} {
    padding: 30px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const ScriptContainer = styled.div`
  padding: 20px 0px;
`;

const ScriptOuterContainer = styled.div`
  /* overflow-y: auto;
  height: calc(100vh - 200px);
  overflow-x: hidden; */
  @media ${(props) => props.theme.devices.xl} {
    padding: 0px 200px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 0px 400px;
  }
`;
const LabelText = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.h5};
  color: ${(props) => props.theme.colors.dark};
  font-weight: 400;
  margin: 0px 0px 10px 0px;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    margin: 0px 0px 12px 0px;
    font-size: ${(props) => props.theme.fonts.lg.p};
  } */
`;

const ScriptArea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  box-sizing: border-box;
  color: #4e5d78;
  &:focus {
    outline: none;
  }
`;

const ModalTextContainer = styled.div`
  margin-bottom: 14px;
`;

const ModalText = styled.h5`
  font-size: ${(props) => props.theme.fonts.sm.h5};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark};
  margin: 0px 0px 0px 0px;
  &:focus {
    outline: none;
  }
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  } */
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  max-width: 870px;
  margin: 0 auto;
  /* @media ${(props) => props.theme.devices.xxl} {
    padding: 8px 440px;
  } */
`;

const GridContainer = styled(Container)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  @media ${(props) => props.theme.devices.md} {
    padding: auto !important;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #c8ccd2;
`;
const ProfileImageWrap = styled.div`
  width: 97px;
  height: 97px;
  background: gray;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const ProfileContant = styled.div``;
const ProfileTitle = styled.h3`
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  color: #12141d;
  line-height: 160%;
`;
const ProfileCategory = styled.p`
  margin: 0px;
  color: #323b4b;
  font-size: 14px;
  font-weight: 450;
  line-height: 16px;
  margin-bottom: 10px;
`;
const ProfileButton = styled.button`
  border-radius: 8px;
  border: 1px solid #9b26a6;
  color: #9b26a6;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 10px;
  background: transparent;
  cursor: pointer;
`;

const skeletonLoadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const SkeletonRound = styled.div`
  width: 100px; /* Adjust the width to your desired size */
  height: 100px; /* Adjust the height to your desired size */
  border-radius: 50%; /* To create a round shape */
  background: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 50%,
    #f6f7f8 100%
  ); /* Adjust the gradient colors as needed */
  background-size: 200px 100%;
  animation: ${skeletonLoadingAnimation} 1.5s infinite linear;
`;

// Define the loading animation
const skeletonAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

// Styled component for the skeleton line
const SkeletonLine = styled.div`
  height: 20px;
  width: 100%;
  background-color: #f5f5f5;
  background-image: linear-gradient(90deg, #f5f5f5, #e8e8e8, #f5f5f5);
  background-size: 200px 100%;
  animation: ${skeletonAnimation} 1.5s infinite;
`;

export default function Script() {
  const { allVideoFetch, setAllVideoFetch, setPageState } = consumerData();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [radioValue, setRadioValue] = useState('female');

  const { ClosePromoteIcon } = iconPath;

  // RADIOBUTTON MODEL SELECTION

  const radioButton = [
    {
      id: 1,
      label: Female,
      name: 'female',
    },
    {
      id: 2,
      label: Male,
      name: 'male',
    },
  ];
  const [scriptData, setScriptData] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [scriptChange, setScriptChange] = useState('');
  const [businessDataGet, setBusinessDataGet] = useState({});
  const [businessGetLoader, setBusinessGetLoader] = useState(false);

  const { scriptGenerate, createVideo, getBusiness } = useAuthentication();

  // RESPONSIVE CONFIGURATION ACCORDING TO SCREEN BREAKPOINTS

  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  // MODEL CHANGE SELECTION

  function handleSelectChange(e) {
    setRadioValue(e.target.value);
  }

  // SCRIPT GENERATE GET API

  function scriptGeneration() {
    setBusinessGetLoader(true);
    scriptGenerate().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setScriptData(data);
        setBusinessGetLoader(false);
      } else {
        console.log(message);
      }
    });
  }

  // CREATE VIDEO WITH SELECTION OF MODELS

  function postVideo() {
    setButtonLoader(true);
    createVideo({
      synthesia_script: scriptChange === '' ? scriptData.trim() : scriptChange,
      model: radioValue === 'female' ? 2 : 1,
      ...state,
    }).then(({ code, data, success, message }) => {
      if (success && code === 200) {
        setButtonLoader(false);
        localStorage.removeItem('circularLoader');
        localStorage.setItem('campaignID', data?.campaign_video_id);

        setAllVideoFetch({ ...allVideoFetch, allVideos: [] });
        setPageState(1);
        navigate('/dashboard?tab=videos', {
          state: {
            campaignVideoId: data?.campaign_video_id,
          },
        });
      } else {
        setButtonLoader(false);
        toast.error(message);
      }
    });
  }

  function getBusinessData() {
    getBusiness().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        if (Object.keys(data).length > 0) {
          setBusinessDataGet(data);
        }
      } else {
        if (message === 'Business not found' || code === 404) {
          setBusinessDataGet({});
        } else {
          console.log(message);
        }
      }
    });
  }

  useEffect(() => {
    getBusinessData();
    scriptGeneration();
  }, []);

  return (
    <>
      <Header />
      <OuterContainer>
        <ScriptOuterContainer>
          <ProfileContainer>
            {businessGetLoader ? (
              <ProfileImageWrap>
                <SkeletonRound />
              </ProfileImageWrap>
            ) : (
              <ProfileImageWrap>
                <img src={businessDataGet?.logo_name} alt={businessDataGet?.name} />
              </ProfileImageWrap>
            )}
            <ProfileContant>
              <ProfileTitle>{businessDataGet?.name}</ProfileTitle>
              <ProfileCategory>{businessDataGet?.business_type_name}</ProfileCategory>
              <ProfileButton onClick={() => navigate('/business')}>
                <img src={EditIcon} alt="Edit" /> Edit Business Details
              </ProfileButton>
            </ProfileContant>
          </ProfileContainer>
          <ScriptContainer>
            <LabelText>AI Generated Script:</LabelText>
            {businessGetLoader ? (
              <>
                <SkeletonLine />
                <br />
                <SkeletonLine />
                <br />
                <SkeletonLine />
              </>
            ) : (
              <>
                {scriptData?.length > 0 && (
                  <ScriptArea readOnly rows={8} onChange={(e) => setScriptChange(e.target.value)}>
                    {scriptData.trim()}
                  </ScriptArea>
                )}
              </>
            )}
          </ScriptContainer>
          <ModalTextContainer>
            <ModalText>Choose your avatar</ModalText>
          </ModalTextContainer>
          <GridContainer>
            <Row
              style={{
                marginBottom: '80px',
                flexWrap: 'nowrap',
              }}
            >
              <Radio
                radioButtonData={radioButton}
                radioValue={radioValue}
                radioKind="script"
                onChange={(e) => handleSelectChange(e)}
                radios={radioButton}
              />
            </Row>
          </GridContainer>
        </ScriptOuterContainer>
      </OuterContainer>
      <ButtonContainer>
        <ButtonInnerContainer>
          <Button
            buttonText={<ClosePromoteIcon buttonLoader={buttonLoader} />}
            kind="mini-close"
            onClick={() => navigate('/generate-script')}
            disabled={buttonLoader}
          />
          <Button
            buttonText="Generate Commercial"
            onClick={() => postVideo()}
            buttonLoader={buttonLoader}
            disabled={buttonLoader}
          />
        </ButtonInnerContainer>
      </ButtonContainer>
    </>
  );
}
