import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { iconPath } from '../assets/icons';

const AddContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: 100%;
  position: fixed;
  padding: 10px 0px 0px 0px;
  bottom: ${(props) => (props.scrollEvent === 'Down' ? '-80px' : '0px')};
  transition: 0.3s all linear;
  -webkit-transition: 0.3s all linear !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: -webkit- 0.3s all linear !important;
  transition: -webkit- 0.3s all linear !important;
  background: ${(props) => props.theme.colors.light};
  box-shadow: 0px -5px 40px 1px rgba(24, 39, 75, 0.12);
`;

const AddContentRow = styled.div`
  display: flex;
  align-items: center;
`;

const AddContentOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddContent = styled.div`
  border-radius: 8px;
  width: 105px;
  height: 45px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 7px;
`;

const BottomIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BottomIconInnerContainer = styled.div`
  width: 18px;
  @media ${(props) => props.theme.devices.md} {
    width: 22px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 24px;
  }
`;

const BottomIconText = styled.h3`
  margin: 15px 0px 10px;
  font-size: 12px;
`;

const CreateCommercial = styled.button`
  background-color: #9b26a6;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 20px;
  margin-bottom: 15px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  width: 340px;
  cursor: pointer;
  svg {
    height: 15px;
    path {
      stroke: #fff;
    }
  }
`;

export default function StickyButton({
  facebookAuthentication,
  youtubeAuthentication,
  uploadVideo,
}) {
  const navigate = useNavigate();

  const { UploadIcon, YoutubeIcon, FacebookIcon, PlusIcon } = iconPath;

  return (
    <AddContentContainer>
      <AddContentOuter>
        <CreateCommercial onClick={() => navigate('/generate-script', { replace: false })}>
          <PlusIcon /> Create Commercial
        </CreateCommercial>
      </AddContentOuter>
      <AddContentRow>
        <AddContentOuter>
          <AddContent type={'file'} backgroundColor="#F2F5FF">
            <BottomIconContainer>
              <label
                onChange={uploadVideo}
                htmlFor="uploadBtn"
                style={{
                  cursor: 'pointer',
                }}
              >
                <input name="file" accept="video/mp4" type="file" id="uploadBtn" hidden />
                <BottomIconInnerContainer>
                  <UploadIcon />
                </BottomIconInnerContainer>
              </label>
            </BottomIconContainer>
          </AddContent>
          <BottomIconText>Upload</BottomIconText>
        </AddContentOuter>
        <AddContentOuter>
          <AddContent backgroundColor="#F5F5F5" onClick={() => youtubeAuthentication()}>
            <BottomIconContainer>
              <BottomIconInnerContainer>
                <YoutubeIcon />
              </BottomIconInnerContainer>
            </BottomIconContainer>
          </AddContent>
          <BottomIconText>Youtube</BottomIconText>
        </AddContentOuter>
        <AddContentOuter style={{
          pointerEvents:'none',
          opacity:.5
        }}>
          <AddContent backgroundColor="#F5F5F5" onClick={() => facebookAuthentication()}>
            <BottomIconContainer>
              <BottomIconInnerContainer>
                <FacebookIcon />
              </BottomIconInnerContainer>
            </BottomIconContainer>
          </AddContent>
          <BottomIconText>Facebook</BottomIconText>
        </AddContentOuter>
      </AddContentRow>
    </AddContentContainer>
  );
}
