import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
      <rect width="21.677" height="21.677" x="0.902" y="0.773" fill="#1D58D8" rx="10.839"></rect>
      <path
        fill="#fff"
        d="M13.602 12.111l.218-1.437h-1.39v-.938c0-.406.187-.78.812-.78h.64V7.72s-.577-.11-1.124-.11c-1.14 0-1.89.703-1.89 1.953v1.11H9.585v1.437h1.281v3.5h1.563v-3.5h1.172z"
      ></path>
    </svg>
  );
}

export default Icon;
