import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
      <path
        fill="#fff"
        d="M9.154 2.668c0 1.816-.976 2.915-2.26 3.73V8.25c0 .285-.21.63-.47.754l-1.74.877c-.062.024-.124.037-.186.05a.416.416 0 01-.42-.42V7.657l-.42.395a.578.578 0 01-.802 0l-.89-.902a.578.578 0 010-.802l.396-.396H.522a.416.416 0 01-.42-.42c0-.061.012-.135.049-.185l.877-1.741c.136-.26.47-.47.753-.47h1.84C4.436 1.842 5.536.866 7.363.866c.445 0 .89 0 1.458.124.098.024.197.123.21.21.123.568.123 1.025.123 1.47zm-1.556.457a.704.704 0 10-.704.704.696.696 0 00.704-.704z"
      ></path>
    </svg>
  );
}

export default Icon;
