import { useEffect } from 'react';
import { useState } from 'react';
import { Col, setConfiguration } from 'react-grid-system';
import styled from 'styled-components';

import { Input, Button } from '.';

const RadioButtonInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  position: relative;

  ${(props) =>
    props.kind === 'business-full'
      ? `
    width: 100%;
    margin-right: 0px;
  `
      : props.kind === 'business'
      ? `
      @media ${props.theme.devices.md} {
        margin-right: 0px;
      }
      @media ${props.theme.devices.lg} {
        margin-right: 0px;
      }
      `
      : `
  @media ${props.theme.devices.md} {
    width: 80%;
    height: 100%;
  }
  `};
`;

const RadioButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  //width: ${(props) => (props.kind === 'business-full' ? '40%' : '')};
  ${(props) =>
    props.radioKind === 'script'
      ? `
  border-radius: 6px;
  position: relative;
  padding: 5px;
  width: 100%;
  height: 100%;
    box-shadow: 1px 1px 5px -1px #c2b8b8;
  `
      : ``}
`;

const RadioButton = styled.input.attrs({
  type: 'radio',
})`
  cursor: pointer;
  margin: 0px 9px 0px 0px;
  width: 20px;
  height: 20px;
  ${(props) =>
    props.radioKind === 'script'
      ? `
  position: absolute;
  top: 12px;
  right: 5px;
  `
      : ``}
`;

const RadioButtonLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 500;
  margin: ${(props) => (props.kind === 'business' ? '0px' : '0px 24px 0px 0px')};
  width: ${(props) => (props.kind === 'business' ? '' : '100%')};
  /* background: ${(props) =>
    props.checked && props.kind === 'business-full'
      ? '#EFF1F3'
      : props.checked && props.kind === 'business'
      ? '#ffffff'
      : 'transparent'}; */
  position: relative;

  ${(props) =>
    props.checked && props.kind === 'business-full'
      ? `background: #EFF1F3;`
      : props.checked && props.kind === 'business'
      ? `border-radius: 6px;
        border: 1px solid #d9dde3;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);`
      : `background: transparent;`}
`;

const FormBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FormBottomContainerText = styled(FormBottomContainer)`
  flex-direction: column;
`;

const InputSuffixContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ChooseAvtar = styled.div``;

const ImageThumbnail = styled.div``;

export default function Radio(props) {
  const {
    plusCall,
    radioValue,
    businessData,
    kind,
    type,
    radioKind,
    radios,
    updateWebsites,
    minusCall,
    page,
    mobileValue,
    saveValue,
  } = props;

  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  const [mobileValueChange, setMobileValueChange] = useState('');
  const [saveValueChange, setSaveValueChange] = useState('');
  const [inputChange, setInputChange] = useState(false);
  const [saveChange, setSaveChange] = useState(false);

  useEffect(() => {
    if (inputChange === false) {
      setMobileValueChange(mobileValue);
    }
  }, [mobileValue]);

  useEffect(() => {
    if (saveChange === false) {
      setSaveValueChange(saveValue);
    }
  }, [saveValue]);

  return (
    <>
      {radios?.length > 0 &&
        radios.map((item, index) => {
          return (
            <>
              {radioKind === 'script' ? (
                <ChooseAvtar>
                  <RadioButtonInnerContainer kind={kind} key={index}>
                    <RadioButtonLabelContainer
                      kind={kind}
                      radioKind={radioKind}
                      checked={radioValue === item?.name}
                    >
                      <RadioButton
                        checked={radioValue === item?.name}
                        value={item?.name}
                        {...props}
                        id={item?.name}
                        radioKind={radioKind}
                        disabled={item?.disableRadio}
                      />
                      <RadioButtonLabel
                        kind={kind}
                        checked={radioValue === item?.name}
                        for={item?.name}
                      >
                        {radioKind === 'script' ? (
                          <ImageThumbnail>
                            <img
                              alt=""
                              src={item?.label}
                              height="100%"
                              width="100%"
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </ImageThumbnail>
                        ) : (
                          <span>{item?.label}</span>
                        )}
                      </RadioButtonLabel>
                    </RadioButtonLabelContainer>
                  </RadioButtonInnerContainer>
                </ChooseAvtar>
              ) : (
                <RadioButtonInnerContainer
                  kind={kind}
                  key={index}
                  className={kind === 'business' ? 'radio-sell' : 'call-action'}
                >
                  <RadioButtonLabelContainer kind={kind} radioKind={radioKind}>
                    {/* {page !== 'business' && (
                      <RadioButton
                        checked={radioValue === item?.name}
                        value={item?.name}
                        {...props}
                        id={item?.name}
                        radioKind={radioKind}
                        disabled={item?.disableRadio}
                      />
                    )} */}
                    <RadioButton
                      checked={radioValue === item?.name}
                      value={item?.name}
                      {...props}
                      id={item?.name}
                      radioKind={radioKind}
                      disabled={item?.disableRadio}
                    />
                    <RadioButtonLabel
                      kind={kind}
                      checked={radioValue === item?.name}
                      for={item?.name}
                    >
                      {radioKind === 'script' ? (
                        <ImageThumbnail>
                          <img
                            src={item?.label}
                            alt=""
                            height="100%"
                            width="100%"
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        </ImageThumbnail>
                      ) : (
                        <span>{item?.label}</span>
                      )}
                    </RadioButtonLabel>
                  </RadioButtonLabelContainer>

                  {type === 'business-cta' &&
                    item?.type !== 'website' &&
                    item?.type !== 'address' && (
                      <>
                        <FormBottomContainerText>
                          {item?.name === 'email' ? (
                            <Input
                              id={item.id}
                              inputMode={item.inputMode}
                              kind="border-full-grey"
                              type="text"
                              defaultValue={item.value}
                              name={item?.name}
                              onChange={(e) => updateWebsites(e.target.name, e.target.value)}
                            />
                          ) : item?.name === 'mobile' ? (
                            <Input
                              id={item.id}
                              inputMode={item.inputMode}
                              kind="border-full-grey"
                              type="text"
                              pattern={item.pattern}
                              value={mobileValueChange}
                              onInput={(e) => {
                                setInputChange(true);
                                if (e.target.validity.valid) {
                                  setMobileValueChange(e.target.value);
                                } else {
                                  setMobileValueChange(mobileValueChange);
                                }
                              }}
                              name={item?.name}
                              onChange={(e) => updateWebsites(e.target.name, e.target.value)}
                            />
                          ) : (
                            <Input
                              id={item.id}
                              inputMode={item.inputMode}
                              kind="border-full-grey"
                              type="text"
                              pattern={item.pattern}
                              value={saveValueChange}
                              onInput={(e) => {
                                setSaveChange(true);

                                if (e.target.validity.valid) {
                                  setSaveValueChange(e.target.value);
                                } else {
                                  setSaveValueChange(saveValueChange);
                                }
                              }}
                              name={item?.name}
                              onChange={(e) => updateWebsites(e.target.name, e.target.value)}
                            />
                          )}
                        </FormBottomContainerText>
                      </>
                    )}
                  {type === 'business-cta' &&
                    (item?.type === 'website' || item?.type === 'address') && (
                      <FormBottomContainerText>
                        <InputSuffixContainer>
                          <Input
                            id={item.id}
                            name={item.name}
                            inputmode="url"
                            defaultValue={item.value}
                            kind="border-full-grey"
                            onChange={(e) => updateWebsites(e.target.name, e.target.value)}
                          />
                          {item?.kind === 'primary' ? (
                            <Button
                              id={item.id}
                              name={item.name}
                              buttonText={
                                item.name.includes('address') ? '+ Add Location' : '+ Add Link'
                              }
                              kind="text"
                              onClick={() => {
                                plusCall(item.name);
                              }}
                              disabled={
                                (businessData['address_data']?.length > 5 &&
                                  item.name.includes('address') &&
                                  true) ||
                                (businessData['websites']?.length > 5 &&
                                  item.name.includes('websites') &&
                                  true)
                              }
                            />
                          ) : (
                            <Button
                              buttonText="Remove"
                              kind="text-remove"
                              onClick={() => {
                                minusCall(item.id, item.name);
                              }}
                            />
                          )}
                        </InputSuffixContainer>
                      </FormBottomContainerText>
                    )}
                </RadioButtonInnerContainer>
              )}
            </>
          );
        })}
    </>
  );
}
