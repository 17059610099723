import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
      <path
        stroke="#9B26A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 14.5v2a2 2 0 002 2h12a2 2 0 002-2v-2M4 6.5l5-5 5 5M9 1.5v12"
      ></path>
    </svg>
  );
}

export default Icon;
