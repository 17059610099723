import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastMessage = {
  loginSuccess: 'Successfully Logged In',
  loginError: 'Error is occur into Login',
  businessCreate: 'Business Created Successfully',
  businessCreateError: 'Business Create Error Occur',
  businessSave: 'Business Saved Successfully',
  signUp: 'User Created Successfully',
  videoUploadSuccess: 'Video is Added Successfully',
  videoUploadFail: 'Video Upload Error Occur',
  campaignCreateSuccess: 'Campaign Created Successfully',
  campaignCreateError: 'Campaign Create Error Occur',
  campaignEditSuccess: 'Campaign Edited Successfully',
  paymentError: 'Payment Error Occur',
  videoScreenError: 'Something went wrong. Please try again later',
  subscriptionUpdate: 'Subscription Updated Successfully',
  subscriptionUpdateError: 'Subscription Update Error Occur',
  noYoutubeVideoFound: 'No Youtube Video Found.',
  noFacebookVideoFound: 'No Facebook Video Found.',
  synthesiaCreated: 'Video Created Successfully',
  campaignEditError: 'Campaign Edit Error Occur',
  logoutSuccess: 'Successfully Logged Out',
  logoutError: 'Logout Error Occur',
  stopPromoteSuccess: 'Your Campaign Stopped successfully',
  stopPromoteError: 'Campaign Stop error occur. Please try Again',
  rePromoteSuccess: 'Your Campaign Re-promoted successfully',
  rePromoteError: 'Campaign Re-promote error occur. Please try Again',
};

export default function Toast() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
