import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-grid-system';
import '../styles/global.scss';
import styled from 'styled-components';

import { useObserver } from '../helpers/useLoadItems';

import Button from './button';

import { assetsImages } from '../assets/images';
import { iconPath } from '../assets/icons';
import CheckMark from '../assets/images/check-mark.png';

const VideoContainerOuter = styled.div`
  visibility: ${(props) => (props.visibility === false ? 'hidden' : 'visible')};
`;

const VideoContainer = styled.div`
  width: ${(props) => (props.kind === 'big' ? '100%' : '168px')};
  //position: relative;
  background-image: ${(props) => props.type !== 'Device' && `url(${props.videoImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
`;

const IconContainer = styled.div`
  /* position: absolute;
  top: 0px;
  right: 10px;
  top: 10px;
  width: 2rem;
  @media ${(props) => props.theme.devices.md} {
    width: 35px;
    top: 10px;
    right: 7px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 45px;
    right: 10px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 40px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    width: 48px;
  } */
`;

const PlayIconContainer = styled.div`
  position: absolute;
  top: 40%;
  cursor: pointer;
  left: 45%;
  width: 2.5rem;
  @media ${(props) => props.theme.devices.md} {
    width: ${(props) => (props.kind === 'big' ? '70px' : '40px')};
  }
  @media ${(props) => props.theme.devices.lg} {
    width: ${(props) => (props.kind === 'big' ? '100px' : '50px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    top: 38%;
    left: 41%;
    width: ${(props) => (props.kind === 'big' ? '100px' : '45px')};
  }
  @media ${(props) => props.theme.devices.xxl} {
    top: 38%;
    left: 41%;
    width: ${(props) => (props.kind === 'big' ? '100px' : '60px')};
  }
`;

const TimeContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  @media ${(props) => props.theme.devices.md} {
    padding: ${(props) => (props.pageScreen === 'promote' ? '15px 60px' : '11px 16px')};
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: ${(props) => (props.pageScreen === 'promote' ? '20px 80px' : '13px 20px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: ${(props) => (props.pageScreen === 'promote' ? '20px 60px' : '10px 15px')};
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: ${(props) => (props.pageScreen === 'promote' ? '20px 60px' : '15px 30px')};
  }
`;

const TimeText = styled.h5`
  font-size: 0.7rem;
  color: ${(props) => props.theme.colors.light};
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0.1px;
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => (props.pageScreen === 'promote' ? '1rem' : '0.625rem')};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => (props.pageScreen === 'promote' ? '1.2rem' : '0.813rem')};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: ${(props) => (props.pageScreen === 'promote' ? '1.2rem' : '0.7rem')};
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: ${(props) => (props.pageScreen === 'promote' ? '1.2rem' : '0.9rem')};
  }
`;

const ActiveContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 10px;
  left: 10px;
  border-radius: 64px;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 11px;
  @media ${(props) => props.theme.devices.md} {
    bottom: 10px;
    left: 10px;
  }
  @media ${(props) => props.theme.devices.md} {
    padding: 8px 12px;
  }
`;
const StopContainer = styled(ActiveContainer)``;

const ActiveIcon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  background: #b9db52;
  @media ${(props) => props.theme.devices.md} {
    width: 8px;
    height: 8px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 10px;
    height: 10px;
  }
`;

const StopIcon = styled(ActiveIcon)`
  background: #fa5252;
`;

const ActiveText = styled(TimeText)``;

const StopText = styled(TimeText)``;

const PromoteContainer = styled.div`
  display: flex;
  // margin-top: ${(props) => (props.loaderIcon ? '13px' : '8px')};
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 13px;
  padding-top: 20px;
`;

const DateText = styled(TimeText)`
  color: ${(props) => props.theme.colors.dark};
  font-size: 0.85rem;
`;

const PromoteIconContainer = styled.div`
  width: 1rem;
  @media ${(props) => props.theme.devices.lg} {
    width: 16px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 20px;
  }
`;

const PromotedContainer = styled.div`
  position: absolute;
  display: flex;
  //flex-direction: column;
  //align-items: baseline;
  padding: 3px;
  /* ${(props) =>
    props.status === 'promoted'
      ? `
  width: 68px;
  height: 68px;
  `
      : `
  width: 62px;
  height: 62px;
  `} */
  top: 0;
  left: 11px;
  right: 9px;
  background: linear-gradient(to top left, transparent 50%, ${(props) => props.color} 50%);
  background: ${(props) => props.color};
  /* @media ${(props) => props.theme.devices.md} {
    width: 50px;
    height: 50px;
    padding: 7px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 65px;
    height: 65px;
  } */
`;

const PromotedIconContainer = styled.div`
  width: 20px;
  cursor: pointer;
  /* @media ${(props) => props.theme.devices.md} {
    width: 12px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 16px;
  } */
`;

const PromotedText = styled.h5`
  padding: 0px 3px 0px 5px;
  ${(props) =>
    props.status === 'promoted'
      ? `
    margin: -5px;
    padding: 0px 3px 0px 5px;
    `
      : `
    margin: -5px;
  padding: 0px 3px 0px 5px;
  `}
  background: ${(props) => props.color};
  color: ${(props) => props.theme.colors.light};
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  /* transform: rotate(-45deg);
  text-transform: uppercase;
  @media ${(props) => props.theme.devices.md} {
    font-size: 0.37rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 0.625rem;
    line-height: 0px;
    margin: 3px 0px 0px -4px;
  } */
`;

export default function Video({
  playIcon,
  videoImage,
  videoTime,
  icon,
  date,
  onClick,
  kind,
  videoUrl,
  loaderIcon,
  type,
  isLast,
  nextPage,
  nextPagePromoted,
  loaderButton,
  thumbnailUrl,
  campaignStatusId,
  campaignStatus,
  campaignVerified,
  activeTab,
  pageScreen,
}) {
  const navigate = useNavigate();

  const videoRef = useRef();
  const entry = useObserver(videoRef, { rootMargin: '200px', threshold: 1.0, root: null });

  const { PromoteIcon, PromotedIcon, PendingIcon } = iconPath;

  useEffect(() => {
    if (!entry) return;
    if (isLast && entry.isIntersecting && kind === 'video') {
      nextPage();
    }
    if (isLast && entry.isIntersecting && kind === 'description') {
      nextPagePromoted();
    }
  }, [entry, isLast]);

  return (
    <>
      <VideoContainerOuter>
        <VideoContainer videoImage={videoUrl ? '' : videoImage} kind={kind} type={type}>
          <div
            style={{
              position: 'relative',
              height: activeTab === 'tab2' && '202px',
            }}
          >
            <video
              preload="none"
              ref={videoRef}
              poster={thumbnailUrl === null ? assetsImages.NoThumbnail : thumbnailUrl}
              src={videoUrl}
              style={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
              }}
            ></video>

            <PlayIconContainer
              className="play"
              kind={kind}
              onClick={() => {
                if (playIcon !== '') {
                  navigate('/video-player', {
                    state: {
                      videoUrl: videoUrl,
                      activeTab: activeTab,
                    },
                  });
                }
              }}
            >
              {playIcon !== '' ? playIcon : ''}
              {loaderIcon && loaderIcon}
            </PlayIconContainer>
          </div>
          {videoTime !== '' && (
            <TimeContainer pageScreen={pageScreen} className="time">
              <TimeText pageScreen={pageScreen}>{videoTime}</TimeText>
            </TimeContainer>
          )}
          {campaignStatusId === 1 &&
            campaignStatus === 'runnable' &&
            campaignVerified === true &&
            kind === 'description' && (
              <ActiveContainer className="status first">
                <ActiveIcon />
                <ActiveText>Active</ActiveText>
              </ActiveContainer>
            )}
          {campaignVerified === true &&
            campaignStatusId === 2 &&
            campaignStatus === 'offline' &&
            kind === 'description' && (
              <>
                <PromotedContainer color="#E11900" status="promoted" className="status">
                  <PromotedText color="#E11900" status="promoted">
                    <span>Pause</span>
                  </PromotedText>
                </PromotedContainer>
                {/* <StopContainer className="status">
                <StopIcon />
                <StopText>Stop</StopText>
              </StopContainer> */}
              </>
            )}
          {campaignStatus === 'runnable' && kind === 'description' && (
            <PromotedContainer color="#05944F" status="promoted" className="status">
              <PromotedText color="#05944F" status="promoted">
                {/* <PromotedIconContainer>
                  <PromotedIcon />
                </PromotedIconContainer> */}
                <img src={CheckMark} alt="running" width={14} height={14} />
                <span>Running</span>
              </PromotedText>
            </PromotedContainer>
          )}
          {campaignStatus === 'offline' && campaignVerified === false && kind === 'description' && (
            <PromotedContainer color="#ffa500" className="status">
              <PromotedText color="#ffa500">
                {' '}
                <PromotedIconContainer
                  onClick={() =>
                    alert(
                      'Admin user will review the video. After Reviewing Campaign will get Active',
                    )
                  }
                >
                  <PendingIcon />
                </PromotedIconContainer>
                <span>Pending</span>
              </PromotedText>
            </PromotedContainer>
          )}
        </VideoContainer>
        {kind === 'video' ? (
          <>
            <Container
              style={{
                padding: '0px',
                margin: '0px',
              }}
            >
              <Row>
                <Col sm={12}>
                  <PromoteContainer loaderIcon={loaderIcon}>
                    <Col sm={6} style={{ maxWidth: '50%' }}>
                      <DateText>{date}</DateText>
                      {icon && <IconContainer>{icon}</IconContainer>}
                    </Col>
                    <Col sm={6} style={{ flex: 'unset', maxWidth: 'auto', width: 'auto' }}>
                      <Button
                        buttonText={loaderIcon ? 'Processing' : 'Promote'}
                        icon={
                          loaderIcon ? (
                            ''
                          ) : (
                            <PromoteIconContainer>
                              <PromoteIcon disabled={loaderButton} />
                            </PromoteIconContainer>
                          )
                        }
                        onClick={onClick}
                        kind="promote"
                        buttonLoader={loaderButton}
                        disabled={loaderIcon || loaderButton ? true : false}
                      />
                    </Col>
                  </PromoteContainer>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          ''
        )}
      </VideoContainerOuter>
    </>
  );
}
