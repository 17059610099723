import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
      <path
        stroke="#9B26A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 4.666H5a2.5 2.5 0 00-2.5 2.5v6.667a2.5 2.5 0 002.5 2.5h10a2.5 2.5 0 002.5-2.5V7.166a2.5 2.5 0 00-2.5-2.5zM2.5 8.834h15M5.834 13h.008M9.166 13h1.667"
      ></path>
    </svg>
  );
}

export default Icon;
