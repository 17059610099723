import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 18">
      <path
        fill="#FC2626"
        d="M25.234 2.86c-.28-1.126-1.172-2.016-2.25-2.297C20.97 0 13 0 13 0S4.984 0 2.969.563C1.89.843 1 1.734.719 2.858.156 4.83.156 9.047.156 9.047s0 4.172.563 6.187a3.127 3.127 0 002.25 2.25C4.984 18 13 18 13 18s7.969 0 9.984-.516a3.127 3.127 0 002.25-2.25c.563-2.015.563-6.187.563-6.187s0-4.219-.563-6.188zm-14.859 9.984V5.25l6.656 3.797-6.656 3.797z"
      ></path>
    </svg>
  );
}

export default Icon;
