import styled from 'styled-components';

const BudgetText = styled.h4`
  margin: 0px;
  font-weight: bold;
  font-size: ${(props) => props.theme.fonts.sm.h5};
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 14px;
  } */
`;

const RupeeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  @media ${(props) => props.theme.devices.md} {
    margin-top: 14px;
  }
`;

const RupeeText = styled.h4`
  font-size: 3.5rem;
  margin: 0px;
  font-weight: 500;
  ${(props) =>
    props.budgetValue >= 5
      ? `color: ${props.theme.colors.dark};`
      : `color: ${props.theme.colors.quaternary};
    `}
  @media ${(props) => props.theme.devices.md} {
    font-size: 70px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h1};
  }
`;

const RupeeSpanText = styled.span`
  font-size: ${(props) => props.theme.fonts.md.h5};
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
`;

const RupeeButton = styled.button`
  width: 26px;
  height: 26px;
  border: 1px solid #d9bdd7;
  cursor: pointer;
  border-radius: 3.125rem;
  background: ${(props) => (props.budgetValue >= 5 ? 'transparent' : '#f4f4f4')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 16px 0px 16px;
  @media ${(props) => props.theme.devices.md} {
    width: 35px;
    height: 35px;
    margin: 0px 20px 0px 20px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 45px;
    height: 45px;
    margin: 0px 25px 0px 25px;
  }
`;

const RupeeButtonText = styled.h5`
  margin: 0px;
  font-size: ${(props) => props.theme.fonts.sm.h5};
  font-weight: 400;
  color: ${(props) =>
    props.budgetValue >= 5 ? `${props.theme.colors.primary}` : `${props.theme.colors.quaternary}`};
  @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: ${(props) => props.theme.fonts.xl.p};
  }
`;

const ZipText = styled(BudgetText)`
  font-weight: 400;
  margin-top: 8px;
  @media ${(props) => props.theme.devices.md} {
    margin-top: 14px;
  }
`;

const WeekText = styled.p`
  font-size: 14px;
`;

export default function Budget(props) {
  const { budgetValue, clickPlus, clickMinus, budgetText, zipBudget } = props;

  return (
    <>
      <BudgetText>Daily Ad Budget:</BudgetText>
      <RupeeContainer>
        {budgetValue >= 5 && (
          <RupeeButton
            disabled={budgetValue <= zipBudget && true}
            onClick={clickMinus}
            budgetValue={budgetValue}
          >
            <RupeeButtonText budgetValue={budgetValue}>-</RupeeButtonText>
          </RupeeButton>
        )}
        <RupeeText budgetValue={budgetValue}>
          ${budgetValue}
          <RupeeSpanText>.00</RupeeSpanText>
        </RupeeText>
        {budgetValue >= 5 && (
          <RupeeButton budgetValue={budgetValue} onClick={clickPlus}>
            <RupeeButtonText budgetValue={budgetValue}>+</RupeeButtonText>
          </RupeeButton>
        )}
      </RupeeContainer>
      {budgetValue >= 5 && (
        <WeekText>
          <b>${budgetValue}/day</b>: This amount will be pre authorized but actual charges will be as per the
          campaign spent.
        </WeekText>
      )}
      {budgetText && <ZipText>Starting from $5 per 250 Household</ZipText>}
    </>
  );
}
