import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" fill="none" viewBox="0 0 23 24">
      <path
        fill="#9B26A6"
        d="M19.297 4.227A11.093 11.093 0 0011.324.875C5.13.875.051 5.953.051 12.148c0 2.032.558 3.961 1.523 5.637L0 23.625l5.941-1.523c1.676.863 3.504 1.37 5.383 1.37 6.246 0 11.426-5.078 11.426-11.273 0-3.047-1.32-5.84-3.453-7.972zm-7.973 17.316c-1.676 0-3.3-.457-4.773-1.32l-.305-.203-3.555.964.965-3.453-.254-.355c-.914-1.524-1.422-3.25-1.422-5.028 0-5.128 4.215-9.343 9.395-9.343 2.488 0 4.824.965 6.602 2.742 1.777 1.777 2.843 4.113 2.843 6.652 0 5.13-4.316 9.344-9.496 9.344zm5.18-7.008c-.305-.152-1.676-.812-1.93-.914-.254-.101-.457-.152-.66.152-.152.254-.71.915-.863 1.118-.203.152-.356.203-.61.05-1.675-.812-2.742-1.472-3.859-3.351-.305-.508.305-.457.813-1.524.101-.203.05-.355 0-.507-.051-.153-.66-1.524-.864-2.082-.254-.559-.457-.508-.66-.508h-.508a.952.952 0 00-.761.355c-.254.305-.965.965-.965 2.336 0 1.422.965 2.742 1.117 2.945.152.153 1.98 2.997 4.824 4.215 1.777.813 2.488.864 3.402.711.508-.05 1.625-.66 1.88-1.32.253-.66.253-1.219.152-1.32-.051-.153-.254-.204-.508-.356z"
      ></path>
    </svg>
  );
}

export default Icon;
