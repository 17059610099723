import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18">
      <path
        stroke="#2E3032"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.917 6.017V4.6A1.417 1.417 0 008.5 3.184H3.542A1.417 1.417 0 002.125 4.6v8.5a1.417 1.417 0 001.417 1.417H8.5A1.417 1.417 0 009.917 13.1v-1.416M4.957 8.85h9.917m0 0l-2.125-2.125m2.125 2.125l-2.125 2.125"
      ></path>
    </svg>
  );
}

export default Icon;
