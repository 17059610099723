import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toast } from './components';
import MyUploader from './pages/test-component';

import {
  Home,
  Login,
  VerifyOtp,
  SearchBusiness,
  SelectBusiness,
  PaymentSetting,
  Videos,
  Promote,
  Subscriptions,
  Script,
  VideoPlayer,
  NotFound,
  PaymentSuccess,
  PaymentFailure,
  GenerateScript,
  VideosMain,
  NotMatch,
} from './pages';

const App = () => {
  function RequireAuth() {
    const now = new Date(localStorage.getItem('loginTime'));
    const expiryDate = new Date(now.getTime() + localStorage.getItem('expireToken') * 1000);
    if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date()
    ) {
      return <Outlet />;
    } else {
      localStorage.clear();
      return <Navigate to="/login" />;
    }
  }

  function RequireAuthLogin() {
    const now = new Date(localStorage.getItem('loginTime'));
    const expiryDate = new Date(now.getTime() + localStorage.getItem('expireToken') * 1000);
    if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date() &&
      localStorage.getItem('business') === 'true'
    ) {
      return <Navigate to="/dashboard" />;
    } else if (
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      expiryDate > new Date() &&
      localStorage.getItem('business') === 'false'
    ) {
      return <Navigate to="/search-business" />;
    } else {
      return <Outlet />;
    }
  }

  const CLIENT_ID = process.env.REACT_APP_API_URL
  ? '975554017795-gct8r9aikfjepeji3uoul0hhna5likd2.apps.googleusercontent.com'
  : '665407838807-rd61nah34ro5rhj7n6eo8bb6bltnkm0t.apps.googleusercontent.com';

  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <Toast />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<RequireAuthLogin />}>
              <Route path="/login" element={<Login />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/not-match" element={<NotMatch />} />
            </Route>
            <Route path="/videos" element={<Videos />} />
            <Route path="/dashboard" element={<VideosMain />} />
            <Route element={<RequireAuth />}>
              <Route path="/search-business" element={<SearchBusiness />} />
              <Route path="/select-business" element={<SelectBusiness />} />
              <Route path="/business" element={<PaymentSetting />} />
              <Route path="/promote" element={<Promote />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/script" element={<Script />} />
              <Route path="/generate-script" element={<GenerateScript />} />
              <Route path="/video-player" element={<VideoPlayer />} />
              <Route path="/payment-failure" element={<PaymentFailure />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/test" element={<MyUploader />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
