import styled from 'styled-components';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuthentication from '../helpers/authentication';

import UploadDeviceIcon from '../assets/icons/upload';
import FacebookMiniIcon from '../assets/icons/facebook';
import YoutubeMiniIcon from '../assets/icons/youtube';
import PlayIcon from '../assets/icons/play-icon';
import HomeContentImage from '../assets/images/home-content';

import background from '../assets/images/dummy.png';
import loaderImage from '../assets/images/loader.png';

import 'react-spring-bottom-sheet/dist/style.css';
import { Container, Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';

import {
  BottomPicker,
  ControlButtons,
  Header,
  NumberBox,
  StickyButton,
  Video,
} from '../components';
import { toast } from 'react-toastify';
import { toastMessage } from '../components/toast';
import { Skeleton } from '../components/skeleton';
import consumerData from '../helpers/authentication';
import ChangingProgressProvider from '../components/circular-progress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ContainerOuter = styled.div`
  padding: 8px 13px 13px 13px;
  overflow: hidden;
  @media ${(props) => props.theme.devices.md} {
    padding: 25px;
  }
  @media ${(props) => props.theme.devices.lg} {
    padding: 40px;
  }
`;

const UploadContentContainer = styled.div``;

const ContentImageContainer = styled.div`
  margin: 0 auto;
  width: 335px;
  height: 209px;
  margin-top: 25px;
  margin-bottom: 25px;
  /* @media ${(props) => props.theme.devices.md} {
    width: 460px;
    margin: 100px auto 5px auto;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 600px;
  } */
`;

const UploadContentText = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.p};
  line-height: 1.125rem;
  margin: 0px 34px 129px 34px;
  font-weight: 400;
  letter-spacing: 0.1px;
  text-align: center;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.p};
    line-height: 1.56rem;
    margin: 0px 80px 100px 80px;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.p};
    line-height: 1.87rem;
    margin: 0px 120px 100px 120px;
  } */
`;

const Line = styled.div`
  background: #d8d8d8;
  width: 100%;
  height: 1px;
`;

const ContentContainer = styled.div`
  margin: 75px auto 85px auto;
  @media ${(props) => props.theme.devices.lg} {
    margin: 0px;
  }
`;

// const UploadText = styled(ContentText)`
//   font-size: ${(props) => props.theme.fonts.sm.p};
//   margin: 0px auto 3px auto;
//   @media ${(props) => props.theme.devices.md} {
//     font-size: ${(props) => props.theme.fonts.md.p};
//     margin: 0px auto 10px auto;
//   }
//   @media ${(props) => props.theme.devices.xl} {
//     font-size: 28px;
//   }
// `;

// const ContentTextSecond = styled(UploadText)`
//   font-size: ${(props) => props.theme.fonts.sm.t};
//   font-weight: 400;
//   margin: 0px auto 50px auto;
//   @media ${(props) => props.theme.devices.md} {
//     font-size: ${(props) => props.theme.fonts.md.t};
//   }
//   @media ${(props) => props.theme.devices.xl} {
//     font-size: 22px;
//   }
// `;

const VideoOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media ${(props) => props.theme.devices.md} {
    align-items: flex-start;
  }

  @media ${(props) => props.theme.devices.xl} {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    height: 100%;
  }
`;

const LinePromotedContainer = styled.div`
  width: 100%;
  @media ${(props) => props.theme.devices.xl} {
    width: 4%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`;

const LinePromoted = styled(Line)`
  margin: 10px 0px 8px 0px;
  @media ${(props) => props.theme.devices.lg} {
    margin: 20px 0px 8px 0px;
  }
  @media ${(props) => props.theme.devices.xl} {
    // position: absolute;
    width: 1px;

    height: 100vh;
    // left: 50%;
    // height: 100%;
    margin: 25px 0px 0px 0px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    left: 49.5%;
  }
`;

const PromotedOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto 0px auto;
  justify-content: center;
  // min-width: 170px;
  margin-bottom: 20px;
  @media ${(props) => props.theme.devices.md} {
    margin: 25px auto 0px auto;

    // min-width: 250px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin: 25px auto 0px auto;
    // min-width: 605px;
  }
  @media ${(props) => props.theme.devices.xl} {
    // min-width: 290px;
  }
`;

const TopPromotedContainer = styled.div`
  display: flex;
  // margin-top: 15px;
  column-gap: 6px;
  // justify-content: center;
  @media ${(props) => props.theme.devices.md} {
    justify-content: space-between;
  }
`;

// const ControlButtonContainer = styled.div`
//   display: flex;
//   margin-top: 8px;
//   // justify-content: center;
//   @media ${(props) => props.theme.devices.md} {
//     justify-content: flex-start;
//   }
// `;

const VideoInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.promotedFlag ? '16px auto 0px auto' : '16px 0px 0px 0px')};
  @media ${(props) => props.theme.devices.md} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '25px auto 0px 0px')};
  }
  @media ${(props) => props.theme.devices.xl} {
    margin: ${(props) => (props.promotedFlag ? '25px auto 0px auto' : '25px auto 0px 0px')};
  }
`;

const LineBottom = styled(Line)`
  margin-bottom: 40px;
  @media ${(props) => props.theme.devices.xl} {
    visibility: hidden;
  }
`;

const VideoFirstContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  column-gap: 5px;
  justify-content: space-between;
  @media ${(props) => props.theme.devices.xl} {
    width: ${(props) => (props.gridView === true ? 'auto' : '48%')};
  }
`;

const VideoFirstSkeletonContainer = styled(VideoFirstContainer)`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  column-gap: 5px;
  justify-content: space-between;
  @media ${(props) => props.theme.devices.xl} {
    width: auto;
  }
`;

const VideoSecondContainer = styled(VideoFirstContainer)`
  margin-bottom: 40px;
  @media ${(props) => props.theme.devices.xl} {
    column-gap: 10px;
    width: ${(props) => (props.gridView === true ? '100%' : '48%')};
  }
  @media ${(props) => props.theme.devices.xxl} {
    column-gap: 50px;
    // width: ${(props) => (props.promoted === true ? '48%' : '100%')};
    width: ${(props) => (props.gridView === true ? '100%' : '48%')};
  }
`;

const GridContainer = styled(Container)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  @media ${(props) => props.theme.devices.md} {
    padding: auto !important;
  }
`;

const GridRow = styled(Row)``;

const synthesiaVideoShow = {
  synthesiaVideo: false,
  synthesiaApi: false,
  generatedVideo: false,
  uploadFile: false,
};

export default function Videos() {
  const navigate = useNavigate();
  const {
    getCampaignVideo,
    preSignedPost,
    youtubeTokenGenerate,
    getVideoYoutube,
    facebookTokenGenerate,
    getFacebookVideo,
    postKeyCampaignVideo,
    facebookUpload,
    youtubeUpload,
    getSynthesiaVideo,
    preSignedUrl,
    getStatistics,
    campaignStatus,
    postStripeSubscription,
    editCampaignVideo,
    loginTokenUrl,
  } = useAuthentication();

  const {
    videoFetch,
    setVideoFetch,
    pageState,
    setPageState,
    totalPageState,
    setTotalPageState,
    promotedNumberData,
    setPromotedNumberData,
  } = consumerData();

  const { state } = useLocation();
  const [videoToken] = useSearchParams();

  const [pickerVisible, setPickerVisible] = useState(false);
  const [doneButton, setDoneButton] = useState(false);
  const [lineFlag, setLineFlag] = useState(false);
  const [bottomLineFlag, setBottomLineFlag] = useState(false);
  const [youtubeData, setYoutubeData] = useState([]);
  const [facebookData, setFacebookData] = useState([]);
  const [loaderContainer, setLoaderContainer] = useState(false);
  const [videoSource, setVideoSource] = useState();
  const [skeleton, setSkeleton] = useState(false);
  const [budgetPicker, setBudgetPicker] = useState();
  const [noContent, setNoContent] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [increaseData, setIncreaseData] = useState();
  const [subscriptionMessage, setSubscriptionMessage] = useState();
  const [stopPromoteLoader, setStopPromoteLoader] = useState([]);
  const [callVideo, setCallVideo] = useState(false);
  const [rePromoteLoader, setRePromoteLoader] = useState({ videoId: null, flag: false });
  const [facebookLoader, setFacebookLoader] = useState({ postVideoId: null, flag: false });

  const nextPage = () => {
    setPageState(pageState + 1);
  };

  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  // FETCH CAMPAIGN VIDEO

  function getVideoCampaign(value) {
    setSkeleton(true);

    getCampaignVideo(`?sort_direction=descending&limit=10&page=${value ? value : pageState}`).then(
      ({ code, data, pagination, success, message }) => {
        if (success && code === 200 && data?.length > 0) {
          setTotalPageState(pagination?.total_pages);
          setSkeleton(false);
          const date = new Date().toDateString();
          const tempData = [];
          const tempDataNumber = [];
          for (let i = 0; i < data?.length; i++) {
            const tempId = data[i]?.id;
            if (data[i]?.campaign_status_name !== null && data[i]?.campaign_status_name !== '') {
              getStatistics(`?video_id=${data[i]?.id}`).then(({ code, data, success, message }) => {
                if (success && code === 200) {
                  if (data === null || Object.keys(data)?.length === 0) {
                    tempDataNumber.push({
                      videoId: tempId,
                      promotedData: [
                        {
                          numberText: '0',
                          descriptionText: 'Impressions',
                        },
                        {
                          numberText: '0',
                          numberSpanText: ' visits',
                          descriptionText: 'Uniques',
                        },
                        {
                          numberText: '$0',
                          descriptionText: 'Engagement',
                        },
                      ],
                    });
                  } else {
                    tempDataNumber.push({
                      videoId: tempId,
                      promotedData: [
                        {
                          numberText: data?.wins,
                          descriptionText: 'Impressions',
                        },
                        {
                          numberText: data?.click,
                          numberSpanText: ' visits',
                          descriptionText: 'Uniques',
                        },
                        {
                          numberText: `$${data?.total}`,
                          descriptionText: 'Engagement',
                        },
                      ],
                    });
                  }
                  if (promotedNumberData.find((data) => data?.videoId === tempId)) {
                  } else {
                    setPromotedNumberData(promotedNumberData.concat(tempDataNumber));
                  }
                } else {
                  console.log(message);
                }
              });
            }

            if (data[i]?.video_url !== null) {
              tempData.push({
                holiday: data[i]?.holiday,
                campaignTime: data[i]?.campaign_time,
                zipValue: data[i]?.zip_value,
                callValue: data[i]?.call_to_action_value,
                callId: data[i]?.call_to_action,
                callName: data[i]?.call_to_action_name,
                image: background,
                promotedFlag: data[i]?.is_promoted,
                thumbnailUrl: data[i]?.thumbnail_url,
                campaignChannels: data[i]?.campaign_channels,
                playIcon: <PlayIcon />,
                campaignStatus: data[i]?.campaign_status_name,
                campaignVerified: data[i]?.is_campaign_verified,
                hasStripeSubscription: data[i]?.has_stripe_subscription,
                campaignStatusId: data[i]?.campaign_status_id,
                icon:
                  data[i]?.video_source_from_name === 'Facebook' ? (
                    <FacebookMiniIcon />
                  ) : data[i]?.video_source_from_name === 'Youtube' ? (
                    <YoutubeMiniIcon />
                  ) : data[i]?.video_source_from_name === 'Device' ? (
                    <UploadDeviceIcon />
                  ) : (
                    ''
                  ),
                date: data[i]?.created_at ? new Date(data[i]?.created_at).toDateString() : date,
                videoTime: data[i]?.video_duration
                  ? data[i]?.video_duration.includes(':')
                    ? `${parseInt(data[i]?.video_duration.split(':')[2])} Sec`
                    : `${parseInt(data[i]?.video_duration)} Sec`
                  : '30 Sec',
                type: data[i]?.video_source_from_name,
                videoUrl: data[i]?.video_url,
                videoId: data[i]?.id,
                facebookId: data[i]?.video_source_id,
                campaignId: data[i]?.campaign_id,
                flag:
                  (data[i]?.campaign_status_name === 'runnable' &&
                    data[i]?.campaign_id !== null &&
                    data[i]?.has_stripe_subscription === true) ||
                  (data[i]?.campaign_status_name === 'offline' &&
                    data[i]?.campaign_id !== null &&
                    data[i]?.has_stripe_subscription === true)
                    ? true
                    : false,
                budgetAmount: data[i]?.budget_amount,
                picker: false,
                campaignDeviceType:
                  data[i]?.video_source_from_name === 'Facebook'
                    ? 'Facebook'
                    : data[i]?.video_source_from_name === 'Youtube'
                    ? 'Youtube'
                    : '',
              });
            }
          }

          const promotedFlagFind =
            tempData?.length > 0 && tempData.find((data) => data?.promotedFlag === true);
          if (promotedFlagFind === undefined) {
            localStorage.setItem('PromotedFlag', false);
          } else if (promotedFlagFind?.length > 0 || Object.keys(promotedFlagFind)?.length > 0) {
            localStorage.setItem('PromotedFlag', true);
          } else {
            localStorage.setItem('PromotedFlag', false);
          }

          const findCampaign = [...data];
          const findTempCampaign = [...data];
          const temp = findTempCampaign.find((data) => data?.campaign_id === null);
          const campaign = findCampaign.find((data) => data?.campaign_id !== null);
          const campaignStatus = findCampaign.find(
            (data) => data.campaign_status_name !== null && data.campaign_status_name !== '',
          );
          if (campaignStatus !== undefined) {
            setGridView(false);
          } else {
            setGridView(true);
          }
          if (campaign !== undefined && temp && Object.keys(temp)?.length > 0) {
            setBottomLineFlag(true);
          } else {
            setBottomLineFlag(false);
          }
          if (campaign !== undefined) {
            setLineFlag(true);
          } else {
            setLineFlag(false);
          }
          if (
            synthesiaVideoShow.uploadFile &&
            videoFetch.length === 0 &&
            localStorage.getItem('circularLoader') &&
            localStorage.getItem('circularLoader') !== '0'
          ) {
            setVideoFetch(
              videoFetch.concat(tempData, [
                {
                  image: loaderImage,
                  loaderIcon: (
                    <>
                      <ChangingProgressProvider
                        values={[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                          39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
                          57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
                          75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
                          93, 94, 95, 96, 97, 98, 99,
                        ]}
                      >
                        {(percentage) => (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#9B26A6',
                              textColor: '#9B26A6',
                              textSize: '1.8rem',
                            })}
                            strokeWidth={5}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        )}
                      </ChangingProgressProvider>
                    </>
                  ),
                  icon: '',
                  date: date,
                  videoTime: '30 sec',
                  type: '',
                  loader: true,
                  videoUrl: '',
                  videoId: '',
                },
              ]),
            );
            synthesiaVideoShow.uploadFile = false;
            tempData = [];
          } else {
            setVideoFetch(videoFetch.concat(tempData));
          }
        } else {
          setSkeleton(false);
          setVideoFetch([]);
          setNoContent(true);
          console.log(message);
        }
      },
    );
  }

  useEffect(() => {
    if (videoToken.get('login') === null && !localStorage.getItem('loginToken')) {
      navigate('/login');
    } else if (videoToken.get('login') !== null) {
      // if (!localStorage.getItem('loginToken')) {
      loginTokenUrl(`?token=${videoToken.get('login')}`).then(
        ({ code, data, message, success }) => {
          if (success && code === 200) {
            setCallVideo(true);
            localStorage.setItem('loginToken', data?.token?.access_token);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expireToken', data?.token?.expires_in);
            localStorage.setItem('loginTime', new Date());
          } else {
            //  setSkeleton(false);
            navigate('login');
            toast.error(message);
          }
        },
      );
      // }
    }
  }, []);

  // FETCH YOUTUBE VIDEO

  function getYoutubeVideo() {
    getVideoYoutube().then(({ code, data, success, message }) => {
      const date = new Date().toDateString();
      if (success && code === 200) {
        const tempYoutubeData = [];
        if (data?.length > 0) {
          for (let i = 0; i < data?.length; i++) {
            tempYoutubeData.push({
              image: data[i]?.thumbnails?.high?.url,
              thumbnailUrl: data[i]?.thumbnails?.high?.url,
              playIcon: <PlayIcon />,
              icon: <YoutubeMiniIcon />,
              date: date,
              videoTime: `${parseInt(data[i]?.duration)} Sec`,
              type: 'Youtube',
              videoUrl: data[i]?.video_url,
              videoId: data[i]?.video_source_id,
              // flag: data[i]?.campaign_id !== null ? true : false,
            });
          }
          setYoutubeData(tempYoutubeData);
        } else {
          setYoutubeData([]);
        }
      } else {
        console.log(message);
      }
    });
  }

  // HANDLE THE PAGINATION

  useEffect(() => {
    if (
      (videoFetch?.length === 0 && pageState === 1) ||
      (videoFetch?.length > 0 && pageState !== 1 && pageState <= totalPageState)
    ) {
      getVideoCampaign();
      setCallVideo(false);
    }
  }, [pageState, callVideo]);

  // SET FACEBOOK DATA IN THE MAIN VIDEO

  useEffect(() => {
    setVideoFetch((prevState) => {
      return [...prevState, ...facebookData];
    });
  }, [facebookData]);

  // SET YOUTUBE DATA IN THE MAIN VIDEO

  useEffect(() => {
    setVideoFetch((prevState) => {
      return [...prevState, ...youtubeData];
    });
  }, [youtubeData]);

  useEffect(() => {
    if (synthesiaVideoShow.synthesiaApi === false) {
      synthesiaVideoGet();
      if (state?.campaignVideoId) {
        setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 1000);
      }
    }
  }, []);

  // FETCH YOUTUBE FACEBOOK VIDEO EVERY TIME WHEN PAGE RENDER

  useEffect(() => {
    if (videoFetch?.length === 0) {
      setTimeout(() => {
        getYoutubeVideo();
        faceBookVideo();
      }, 500);
    }
  }, []);

  // FETCH FACEBOOK VIDEO

  function faceBookVideo() {
    getFacebookVideo().then(({ code, data, success, message }) => {
      const date = new Date().toDateString();
      if (success && code === 200) {
        const tempFacebookData = [];
        if (data?.length > 0) {
          for (let i = 0; i < data?.length; i++) {
            tempFacebookData.push({
              image: data[i]?.image_url,
              thumbnailUrl: data[i]?.image_url,
              playIcon: <PlayIcon />,
              icon: <FacebookMiniIcon />,
              date: date,
              videoTime: `${parseInt(data[i]?.duration)} Sec`,
              type: 'Facebook',
              videoUrl: data[i]?.video_url,
              videoId: data[i]?.post_id,
              // flag: data[i]?.campaign_id !== null ? true : false,
            });
          }
          // setVideoFetch([...videoFetch, tempFacebookData]);
          setFacebookData(tempFacebookData);
        } else {
          setFacebookData([]);
        }
      } else {
        console.log(message);
      }
    });
  }

  // FETCH SYNTHESIA VIDEO

  function synthesiaVideoGet() {
    if (synthesiaVideoShow.synthesiaApi === false) {
      setNoContent(false);
      if (state?.campaignVideoId) {
        getSynthesiaVideo(`${state?.campaignVideoId}/`).then(({ code, data, success, message }) => {
          if (success && code === 200) {
            if (data?.status === 'in_progress') {
              if (synthesiaVideoShow.synthesiaVideo === false) {
                const date = new Date().toDateString();
                setNoContent(false);
                setVideoFetch((prevState) => {
                  return [
                    ...prevState,
                    {
                      image: loaderImage,
                      loaderIcon: (
                        <>
                          <ChangingProgressProvider
                            key="synthesia"
                            values={[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                              20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
                              37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                              54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
                              71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
                              88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
                            ]}
                          >
                            {(percentage) => (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: '#9B26A6',
                                  textColor: '#9B26A6',
                                  textSize: '1.8rem',
                                })}
                                strokeWidth={5}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            )}
                          </ChangingProgressProvider>
                        </>
                      ),
                      icon: '',
                      date: date,
                      videoTime: '',
                      type: '',
                      loader: true,
                      videoUrl: '',
                      videoId: '',
                    },
                  ];
                });
              }

              synthesiaVideoShow.generatedVideo = true;
              synthesiaVideoShow.synthesiaVideo = true;

              setTimeout(() => {
                synthesiaVideoGet();
              }, 30000);
            } else if (data?.status === 'complete') {
              // if (data?.status === 'complete') {
              if (synthesiaVideoShow.generatedVideo) {
                localStorage.setItem('circularLoader', 0);
                toast.success(toastMessage.synthesiaCreated);
                getVideoCampaign(1);
                setVideoFetch([]);
                setPageState(1);
                faceBookVideo();
                getYoutubeVideo();
                synthesiaVideoShow.synthesiaApi = true;
                synthesiaVideoShow.synthesiaVideo = false;
              }
              synthesiaVideoShow.generatedVideo = true;
              // }
            }
          } else {
            console.log(message);
          }
        });
      }
    } else {
    }
  }

  // SCROLLING EFFECT OF BOTTOM BUTTONS
  // let scrollBefore = 0;
  // useEffect(() => {
  //   window.addEventListener('scroll', function (e) {
  //     const scrolled = window.scrollY;
  //     if (scrollBefore > scrolled) {
  //       scrollBefore = scrolled;
  //       setScrollEvent('Down');
  //     } else {
  //       scrollBefore = scrolled;
  //       setScrollEvent('Up');
  //     }
  //   });
  // }, []);

  // GET VIDEO DURATION

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  // LOADING THUMBNAIL WHEN UPLOAD FROM THE DEVICE

  useEffect(() => {
    const date = new Date().toDateString();

    if (loaderContainer) {
      setVideoFetch((prevState) => {
        return prevState.concat([
          {
            image: '',
            // image: loaderImage,
            loaderIcon: (
              <>
                <div className="loader"></div>
              </>
            ),
            icon: '',
            date: date,
            videoTime: '',
            type: '',
            loader: true,
            videoUrl: videoSource,
            videoId: '',
          },
        ]);
      });
    }
  }, [loaderContainer]);

  // UPLOAD VIDEO FROM DEVICE

  async function uploadVideo(e) {
    e.preventDefault();
    const uploadFile = e.target.files[0];

    if (
      uploadFile?.type === 'image/jpeg' ||
      uploadFile?.type === 'image/png' ||
      uploadFile?.type === 'image/jpg'
    ) {
      alert('JPEG/PNG/JPG File Format Not Acceptable');
    } else {
      const duration = await getVideoDuration(e.target.files[0]);

      const videoSource = URL.createObjectURL(e.target.files[0]);
      setVideoSource(videoSource);

      const image = document.createElement('video');
      image.src = videoSource;

      // image.addEventListener('loadedmetadata', function (e) {
      //   const width = e.path[0].videoWidth;
      //   const height = e.path[0].videoHeight;
      //   if (width > height) {

      if (duration > 30 || duration < 15) {
        alert('Please Upload File between 15 to 30 Seconds');
      } else if ((uploadFile.size / 1024 / 1024).toFixed(0) > 50) {
        alert('Please Upload File Under 50MB');
      } else {
        setLoaderContainer(true);
        setNoContent(false);
        preSignedUrl().then(async ({ code, data, success, message }) => {
          if (code === 200 && success) {
            const { data: url, key } = data;
            if (success && code === 200) {
              preSignedPost(uploadFile, url).then(({ code, success }) => {
                if (code === 200) {
                  postKeyCampaignVideo({
                    key: key,
                    title: 'Device Upload',
                    video_duration: duration,
                  }).then(({ code, success, message }) => {
                    if (success && code === 200) {
                      // if (synthesiaVideoShow.synthesiaVideo === true) {
                      //   synthesiaVideoShow.synthesiaVideo = false;
                      //   synthesiaVideoShow.synthesiaApi = false;
                      synthesiaVideoShow.uploadFile = true;
                      // }
                      alert('File Uploaded Successfully');
                      toast.success(toastMessage.videoUploadSuccess);
                      setLoaderContainer(false);
                      getVideoCampaign(1);
                      setVideoFetch([]);
                      setPageState(1);
                      faceBookVideo();
                      getYoutubeVideo();
                    } else {
                      console.log(message);
                    }
                  });
                } else {
                  toast.error(toastMessage.videoUploadFail);
                }
              });
            } else {
              console.log(message);
              setNoContent(false);
            }
          } else {
            setNoContent(false);
          }
        });
      }
      //   }
      //   else {
      //     alert('Please Upload Landscape Video');
      //   }
      // });
    }
  }

  // YOUTUBE AUTHENTICATION

  function youtubeAuthentication() {
    youtubeTokenGenerate().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        window.location.href = data;
      } else {
        console.log(message);
      }
    });
  }

  // FACEBOOK AUTHENTICATION

  function facebookAuthentication() {
    facebookTokenGenerate().then(({ code, data, success, message }) => {
      if (success && code === 200) {
        window.location.href = data;
      } else {
        console.log(message);
      }
    });
  }

  // PROMOTE FACEBOOK VIDEO

  function postFacebookUpload(video, image, videoId, videoTime, state) {
    setFacebookLoader({ postVideoId: videoId, flag: true });
    facebookUpload({
      post_id: videoId,
      video_link: video,
      thumbnail_link: image,
      duration: parseInt(videoTime.split('Sec')[0]),
    }).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setFacebookLoader({ postVideoId: null, flag: false });
        const videoState = { ...state };
        videoState.videoId = data?.campaign_video;
        const stateVideo = videoState;
        navigatePromote(stateVideo);
      } else {
        setFacebookLoader({ postVideoId: null, flag: false });
        console.log(message);
      }
    });
  }

  // PROMOTE YOUTUBE VIDEO

  function postYoutubeUpload(video, image, id, videoTime, state) {
    setFacebookLoader({ postVideoId: id, flag: true });
    youtubeUpload({
      link: video,
      thumbnail_link: image,
      video_source_id: id,
      duration: parseInt(videoTime.split('Sec')[0]),
    }).then(({ code, data, success, message }) => {
      if (code === 200 && success) {
        setFacebookLoader({ postVideoId: null, flag: false });
        const videoState = { ...state };
        videoState.videoId = data?.campaign_video_id;
        const stateVideo = videoState;
        navigatePromote(stateVideo);
      } else {
        setFacebookLoader({ postVideoId: null, flag: false });
        console.log(message);
      }
    });
  }

  // NAVIGATION CONDITIONALLY DEPEND ON THE VIDEOS COMING FROM

  function navigatePromote(state) {
    navigate('/promote', {
      state: {
        ...state,
      },
    });
  }

  // STOP PROMOTE VIDEOS EDIT CAMPAIGN

  function stopPromoteVideos(data) {
    const payload = {
      status: 2,
    };
    setStopPromoteLoader({ videoId: data?.videoId, flag: true });
    campaignStatus(`${data.campaignId}/`, payload).then(({ code, data, success, message }) => {
      console.log(data);
      if (success && code === 200) {
        setTimeout(() => {
          setStopPromoteLoader({ videoId: null, flag: false });
          getVideoCampaign(1);
          setVideoFetch([]);
          setPageState(1);
        }, 5000);
      } else {
        setStopPromoteLoader({ videoId: null, flag: false });
        toast.error(toastMessage.campaignEditError);
        console.log(message);
      }
    });
  }

  const data = ['item1', 'item1', 'item1', 'item1', 'item1', 'item1'];

  // RENDER STATIC SKELETON

  function renderSkeleton() {
    return data.map((data, index) => {
      return (
        <Col
          key={index}
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={6}
          xxl={6}
          xxxl={12}
          style={{
            width: '50vw',
          }}
        >
          <VideoInnerContainer>
            <Skeleton />
          </VideoInnerContainer>
        </Col>
      );
    });
  }

  // UPDATE SUBSCRIPTION

  function updateSubscription(videoId) {
    const payload = {
      currency: 'usd',
      video_id: videoId,
    };

    postStripeSubscription(payload).then(({ code, data, success, message }) => {
      if (code === 201 && success) {
        const date = new Date(data?.current_period_end * 1000).toDateString();
        setSubscriptionMessage(date);
        setDoneButton(true);
        setButtonLoader(false);
      } else {
        toast.error(toastMessage.subscriptionUpdateError);
      }
    });
  }

  // INCREASE THE BUDGET

  function increaseBudget(budgetValue) {
    const subdomain = window.location.origin.split('.')[0].split('//')[1];
    const todayDate = new Date().toISOString().slice(0, 10);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 3);
    const payload = {
      start_date: todayDate,
      end_date: endDate.toISOString().slice(0, 10),
      budget_amount: budgetValue,
      duration: parseInt(increaseData?.videoTime?.split('Sec')[0]),
      daily_budget: budgetValue,
      campaign_video: increaseData?.videoId,
      zip_value: increaseData?.zipValue,
      call_to_action_value: increaseData?.callValue,
      call_to_action: increaseData?.callId,
      status: 1,
      campaign_time: increaseData?.campaignTime,
      holiday: increaseData?.holiday,
      categories_data: increaseData?.campaignChannels,
      domain: subdomain,
    };
    setButtonLoader(true);
    editCampaignVideo(`${increaseData?.campaignId}/`, payload).then(
      ({ code, data, success, message }) => {
        if (success && code === 200) {
          if (increaseData?.hasStripeSubscription) {
            updateSubscription(increaseData?.videoId, increaseData?.campaignId);
          } else {
            getVideoCampaign(1);
            setVideoFetch([]);
            setPageState(1);
            setButtonLoader(false);
          }
        } else {
          toast.error(toastMessage.campaignEditError);
          setButtonLoader(false);
          console.log(message);
        }
      },
    );
  }

  // RE-PROMOTE THE VIDEO

  function rePromotedVideo(data) {
    const payload = {
      status: 1,
    };
    setRePromoteLoader({ videoId: data?.videoId, flag: true });
    campaignStatus(`${data.campaignId}/`, payload).then(({ code, data, success, message }) => {
      console.log(data);
      if (success && code === 200) {
        setTimeout(() => {
          setRePromoteLoader({ videoId: null, flag: false });
          getVideoCampaign(1);
          setVideoFetch([]);
          setPageState(1);
        }, 5000);
      } else {
        setRePromoteLoader({ videoId: null, flag: false });
        toast.error(toastMessage.campaignEditError);
        console.log(message);
      }
    });
  }

  return (
    <>
      <Header />
      <ContainerOuter>
        {skeleton && pageState === 1 && (
          <>
            <VideoOuterContainer>
              <VideoFirstSkeletonContainer>
                <GridContainer>
                  <GridRow gutterWidth={16}>{renderSkeleton()}</GridRow>
                </GridContainer>
              </VideoFirstSkeletonContainer>
            </VideoOuterContainer>
          </>
        )}
        <StickyButton
          facebookAuthentication={facebookAuthentication}
          youtubeAuthentication={youtubeAuthentication}
          uploadVideo={uploadVideo}
        />
        <VideoOuterContainer>
          <VideoFirstContainer promoted={lineFlag} gridView={gridView}>
            <GridContainer>
              <GridRow gutterWidth={16}>
                {videoFetch?.length > 0 &&
                  videoFetch.map((data, index) => {
                    return (
                      <>
                        {data?.flag && (
                          <>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} xxxl={12}>
                              <VideoInnerContainer key={index} promotedFlag={lineFlag} id={index}>
                                <Video
                                  nextPage={nextPage}
                                  isLast={videoFetch?.length - 1 === index}
                                  icon={data?.icon}
                                  playIcon={data?.playIcon}
                                  date={data?.date}
                                  thumbnailUrl={data?.thumbnailUrl}
                                  videoImage={data?.image}
                                  videoUrl={data?.videoUrl}
                                  videoTime={data?.videoTime}
                                  flag={data?.flag}
                                  type={data?.type}
                                  campaignStatusId={data?.campaignStatusId}
                                  campaignStatus={data?.campaignStatus}
                                  campaignVerified={data?.campaignVerified}
                                  loaderIcon={data?.loaderIcon}
                                  budgetAmount={data?.budgetAmount}
                                  kind={data?.flag ? 'description' : 'video'}
                                />
                              </VideoInnerContainer>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} xxxl={12}>
                              <PromotedOuterContainer>
                                <Container
                                  style={{
                                    padding: '0px',
                                    margin: '0px',
                                  }}
                                >
                                  <Row>
                                    <Col sm={12}>
                                      <TopPromotedContainer>
                                        {promotedNumberData?.length > 0 &&
                                          promotedNumberData
                                            .filter((item) => item.videoId === data?.videoId)
                                            .map((numberData) => {
                                              return (
                                                numberData?.promotedData.length > 0 &&
                                                numberData?.promotedData.map((data) => {
                                                  return (
                                                    <NumberBox
                                                      uniqueKey={index}
                                                      key={index}
                                                      numberText={data?.numberText}
                                                      numberSpanText={data?.numberSpanText}
                                                      descriptionText={data?.descriptionText}
                                                    />
                                                  );
                                                })
                                              );
                                            })}
                                      </TopPromotedContainer>
                                    </Col>
                                  </Row>
                                  <ControlButtons
                                    data={data}
                                    rePromoteLoader={rePromoteLoader}
                                    stopPromoteLoader={stopPromoteLoader}
                                    rePromotedVideo={(data) => rePromotedVideo(data)}
                                    stopPromoteVideos={(data) => stopPromoteVideos(data)}
                                    pickerShowStop={(amount) => {
                                      setPickerVisible(true);
                                      setSubscriptionMessage();
                                      setBudgetPicker(amount);
                                    }}
                                    pickerShowIncrease={(amount) => {
                                      setPickerVisible(true);
                                      setIncreaseData(data);
                                      setBudgetPicker(amount);
                                    }}
                                  />
                                </Container>
                              </PromotedOuterContainer>
                            </Col>
                          </>
                        )}
                      </>
                    );
                  })}
              </GridRow>
            </GridContainer>
          </VideoFirstContainer>
          <BottomPicker
            visible={pickerVisible}
            doneButton={doneButton}
            buttonLoader={buttonLoader}
            subscriptionMessage={subscriptionMessage}
            budgetAmount={budgetPicker}
            onDismiss={() => setPickerVisible(false)}
            onSubmit={(budgetValue) => {
              // setPickerVisible(false);
              increaseBudget(budgetValue);
            }}
            onDoneButton={(value) => {
              setPickerVisible(value);
              setDoneButton(value);
              setSubscriptionMessage('');
              setBudgetPicker();
            }}
          />
          {bottomLineFlag && !gridView && (
            <LinePromotedContainer>
              <LinePromoted />
            </LinePromotedContainer>
          )}
          <VideoSecondContainer promoted={lineFlag} gridView={gridView}>
            <GridContainer>
              <GridRow gutterWidth={16}>
                {videoFetch?.length > 0 &&
                  videoFetch.map((data, index) => {
                    return (
                      <>
                        {!data?.flag && (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={gridView ? 3 : 6}
                            xxl={gridView ? 3 : 6}
                            xxxl={12}
                          >
                            <VideoInnerContainer key={data?.videoUrl} id={index}>
                              <Video
                                nextPage={nextPage}
                                isLast={videoFetch?.length - 1 === index}
                                icon={data?.icon}
                                playIcon={data?.playIcon}
                                date={data?.date}
                                videoImage={data?.image}
                                videoUrl={data?.videoUrl}
                                videoTime={data?.videoTime}
                                flag={data?.flag}
                                type={data?.type}
                                thumbnailUrl={data?.thumbnailUrl}
                                loaderIcon={data?.loaderIcon}
                                loaderButton={
                                  buttonLoader ||
                                  (data?.videoId == facebookLoader.postVideoId &&
                                    facebookLoader.flag)
                                }
                                kind={data?.flag ? 'description' : 'video'}
                                onClick={() => {
                                  const videoTimeCheck = parseInt(data?.videoTime.split('Sec')[0]);
                                  const state = {
                                    date: data?.date,
                                    videoUrl: data?.videoUrl,
                                    videoImage: data?.image,
                                    videoTime: data?.videoTime,
                                    thumbnailUrl: data?.thumbnailUrl,
                                    campaignId: data?.campaignId,
                                    type: data?.type,
                                    videoId: data?.videoId,
                                    callValue: data?.callValue,
                                    callId: data?.callId,
                                    callName: data?.callName,
                                  };
                                  if (
                                    videoTimeCheck > 30 ||
                                    (videoTimeCheck < 15 && data?.type !== 'synthesia')
                                  ) {
                                    alert(
                                      `This Video Can't be Promoted. Please Upload Video between 15 to 30 Seconds`,
                                    );
                                  } else {
                                    if (
                                      data?.type === 'Facebook' &&
                                      data?.campaignDeviceType !== 'Facebook'
                                    ) {
                                      postFacebookUpload(
                                        data?.videoUrl,
                                        data?.image,
                                        data?.videoId,
                                        data?.videoTime,
                                        state,
                                      );
                                    } else if (
                                      data?.type === 'Youtube' &&
                                      data?.campaignDeviceType !== 'Youtube'
                                    ) {
                                      postYoutubeUpload(
                                        data?.videoUrl,
                                        data?.image,
                                        data?.videoId,
                                        data?.videoTime,
                                        state,
                                      );
                                    } else {
                                      navigatePromote(state);
                                    }
                                  }
                                }}
                              />
                            </VideoInnerContainer>
                          </Col>
                        )}
                      </>
                    );
                  })}
              </GridRow>
            </GridContainer>
          </VideoSecondContainer>
        </VideoOuterContainer>
        {noContent && (
          <>
            <UploadContentContainer>
              <ContentImageContainer>
                <HomeContentImage />
              </ContentImageContainer>
              <UploadContentText>
                Upload Video-Ad to get started. You can also import from you social media accounts.
              </UploadContentText>
            </UploadContentContainer>
            <Line />
          </>
        )}
        <ContentContainer>
          {!lineFlag ? (
            // videoData?.length > 0 ? (
            videoFetch?.length > 0 ? (
              <>
                {/* <UploadText>Uploading</UploadText>
                <ContentTextSecond>Do not close window or Refresh this page</ContentTextSecond> */}
              </>
            ) : (
              <></>
              // <ContentText>To add content click down below</ContentText>
            )
          ) : bottomLineFlag ? (
            <LineBottom />
          ) : (
            ''
          )}
        </ContentContainer>
      </ContainerOuter>
      <StickyButton
        facebookAuthentication={facebookAuthentication}
        youtubeAuthentication={youtubeAuthentication}
        uploadVideo={uploadVideo}
      />
    </>
  );
}
