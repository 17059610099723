import { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';

import Budget from './budget';
import Button from './button';

const BudgetContainer = styled.div`
  margin: 8px 0px;
  text-align: center;
  background: ${(props) => props.theme.colors.light};
  padding: 15px;
  /* @media ${(props) => props.theme.devices.md} {
    margin: 12px 0px;
  }
  @media ${(props) => props.theme.devices.lg} {
    margin: 20px 0px;
  } */
`;

const SubscriptionText = styled.h5`
  margin: 20px 0px;
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: center;
  padding: 0px 15px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`;

const StopCampContainer = styled.div`
  margin: 8px 0px;
  text-align: center;
  background: #ffffff;
  padding: 15px;
`;

const StopCampTitle = styled.p`
  color: #12141d;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`;

export default function BottomPicker({
  kind,
  visible,
  budgetAmount,
  onDismiss,
  buttonLoader,
  onSubmit,
  onSubmitTop,
  subscriptionMessage,
  doneButton,
  onDoneButton,
  stopPromoteLoader,
}) {
  const [budgetValue, setBudgetValue] = useState();
  useEffect(() => {
    setBudgetValue(budgetAmount);
  }, [budgetAmount]);

  return (
    <>
      <BottomSheet open={visible} onDismiss={onDismiss} className="bottom-modal">
        {kind === 'Increase' ? (
          <>
            {subscriptionMessage?.length > 0 && (
              <SubscriptionText>{`Your updated subscription will be starting from ${subscriptionMessage}`}</SubscriptionText>
            )}
            <BudgetContainer>
              <Budget
                budgetValue={budgetValue}
                zipBudget={budgetAmount}
                clickPlus={() => setBudgetValue(budgetValue + 5)}
                clickMinus={() => setBudgetValue(budgetValue - 5)}
              />
              <br />
              <Button
                buttonLoader={buttonLoader}
                disabled={buttonLoader}
                buttonText={doneButton ? 'Done' : 'Update Payment'}
                onClick={() => {
                  if (doneButton === true) {
                    onDoneButton(false);
                  } else {
                    onSubmit(budgetValue);
                  }
                }}
              />
            </BudgetContainer>
          </>
        ) : (
          <>
            <StopCampContainer>
              <StopCampTitle>Are you sure to stop this campaign?</StopCampTitle>
              <Button
                buttonLoader={buttonLoader}
                disabled={buttonLoader}
                buttonText={'Stop Campaign'}
                onClick={() => {
                  if (doneButton === true) {
                    onDoneButton(false);
                  } else {
                    onSubmitTop(budgetValue);
                  }
                }}
              />
              <br />
              <Button
                kind={'border'}
                buttonText={'Cancel'}
                onClick={() => {
                  onDoneButton(false);
                }}
              />
            </StopCampContainer>
          </>
        )}
      </BottomSheet>
    </>
  );
}
