import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
      <path
        fill="#A2A2A2"
        fillRule="evenodd"
        d="M8.923 5.172c0 1.993-1.667 3.667-3.795 3.667S1.333 7.165 1.333 5.172c0-1.994 1.667-3.667 3.795-3.667s3.795 1.673 3.795 3.667zm-.487 3.82a5.186 5.186 0 01-3.308 1.18c-2.832 0-5.128-2.239-5.128-5 0-2.762 2.296-5 5.128-5 2.832 0 5.128 2.238 5.128 5a4.891 4.891 0 01-.91 2.844l3.732 2.634c.296.221.35.634.124.923a.687.687 0 01-.947.12L8.477 9.026a.683.683 0 01-.041-.033z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
