import styled from 'styled-components';

const SearchBusinessContainer = styled.div`
  display: flex;
  padding: 8px 8px;
  border-radius: 6px;
  align-items: center;
  &:hover {
    background-color: rgba(240, 244, 248, 1);
    cursor: pointer;
  }
`;

const Name = styled.h3`
  font-size: 14px;
  color: #000;
  font-weight: 400;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  flex: 2;
  display: flex;
`;

export default function SearchShow(props) {
  const { name, onCloseExpand, onSetInnerText, keyIndex, onSetIndex } = props;

  return (
    <SearchBusinessContainer
      id={keyIndex}
      onClick={(e) => {
        onCloseExpand();
        onSetInnerText(e.target.innerText);
        onSetIndex(keyIndex);
      }}
    >
      <Name>{name}</Name>
    </SearchBusinessContainer>
  );
}
