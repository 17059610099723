import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper';

import slide1 from '../assets/images/slide11.jpg';
import slide2 from '../assets/images/slide22.jpg';
import slide3 from '../assets/images/slide33.jpg';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);

const Herobanner = () => {
  return (
    <>
      <div className="banner__wrap">
        <div className="banner__images">
          <Swiper
            modules={[Pagination]}
            effect={'fade'}
            spaceBetween={10}
            slidesPerView={'auto'}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
            }}
          >
            <SwiperSlide className="slide1">
              <img src={slide1} alt="" className="w-100" />
              <div className="callbox">
                <h5 className="callbox-title">Call For Restaurant Now!</h5>
                <h3 className="callbox-number">8XX-XXX-XXX</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide2">
              <img src={slide2} alt="" className="w-100" />
              <div className="callbox">
                <h5 className="callbox-title">Call For Nail Spa Now!</h5>
                <h3 className="callbox-number">8XX-XXX-XXX</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide3">
              <img src={slide3} alt="" className="w-100" />
              <div className="callbox">
                <h5 className="callbox-title">Call For Professional Services Now!</h5>
                <h3 className="callbox-number">8XX-XXX-XXX</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Herobanner;
