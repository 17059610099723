import { Col, setConfiguration } from 'react-grid-system';
import styled from 'styled-components';

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f7fa;
  border-radius: 0.25rem;
  width: 100%;
  padding: 10px;
  text-align: center;
  @media ${(props) => props.theme.devices.lg} {
    padding: 15px;
  }
  @media ${(props) => props.theme.devices.xl} {
    padding: 10px;
  }
  @media ${(props) => props.theme.devices.xxl} {
    padding: 15px;
  }
`;

const NumberText = styled.h4`
  margin: 0px;
  word-break: break-all;
  font-size: ${(props) => props.theme.fonts.sm.h5};
  @media ${(props) => props.theme.devices.md} {
    font-size: 1.3rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.h5};
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 1.125rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: ${(props) => props.theme.fonts.xxl.p};
  }
`;

const NumberSpanText = styled.span`
  font-size: 0.7rem;
  @media ${(props) => props.theme.devices.md} {
    font-size: 0.8rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.5rem;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.87rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 1.125rem;
  }
`;

const DescriptionText = styled.h5`
  margin: 0px 0px 6px 0px;
  font-size: 0.625rem;
  font-weight: 400;
  color: #4e5d78;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: 0.9rem;
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: 1.2rem;
  }
  @media ${(props) => props.theme.devices.xl} {
    font-size: 0.8rem;
  }
  @media ${(props) => props.theme.devices.xxl} {
    font-size: 0.938rem;
  } */
`;

export default function NumberBox({ uniqueKey, numberText, numberSpanText, descriptionText }) {
  setConfiguration({
    breakpoints: [300, 400, 500, 1024, 1280, 1350, 2048],
  });

  return (
    <>
      <NumberContainer key={uniqueKey}>
        <Col
          xs={12}
          sm={12}
          md={12}
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
          }}
        >
          <DescriptionText>{descriptionText}</DescriptionText>
          <NumberText>
            {numberText}
            {numberSpanText && <NumberSpanText>{numberSpanText}</NumberSpanText>}
          </NumberText>
          {/* <DescriptionText>{descriptionText}</DescriptionText> */}
        </Col>
      </NumberContainer>
    </>
  );
}
