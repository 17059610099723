import FacebookMiniIcon from './facebook';
import YoutubeMiniIcon from './youtube';
import PlayIcon from './play-icon';
import UploadDeviceIcon from './upload';
import ClosePromoteIcon from './close-promote';
import HomeContentImage from '../images/home-content';
import SearchIcon from './search-input';
import AccordianDownIcon from './accordian-down';
import SlideIcon1 from './slide-1';
import SlideIcon2 from './slide-2';
import SlideIcon3 from './slide-3';
import SlideIcon4 from './slide-4';
import SlideIcon5 from './slide-5';
import SlideIcon6 from './slide-6';
import SlideIcon7 from './slide-7';
import PayPalIcon from './paypal';
import PayPalIconFocus from './paypal-focus';
import CreditCardIcon from './credit-card';
import CreditCardIconFocus from './credit-card-focus';
import Logo from './logo-header';
import RequireIcon from './require-icon';
import PromoteIcon from './promote-button';
import TooltipIcon from './tooltip';
import CloseVideoIcon from './close-video';
import RePromoteIcon from './re-promote';
import StopPromoteIcon from './stop-promote';
import IncreasePromoteIcon from './increase-promote';
import SettingIcon from './settings';
import LogoutIcon from './logout';
import CloseIcon from './close-label';
import DropdownIcon from './dropdown-indicator';
import UploadIcon from './home-upload';
import YoutubeIcon from './home-youtube';
import FacebookIcon from './home-facebook';
import PlusIcon from './home-plus';
import PromotedIcon from './promoted';
import PendingIcon from './pending';

export const iconPath = {
  FacebookMiniIcon,
  YoutubeMiniIcon,
  PlayIcon,
  UploadDeviceIcon,
  ClosePromoteIcon,
  HomeContentImage,
  SearchIcon,
  AccordianDownIcon,
  SlideIcon1,
  SlideIcon2,
  SlideIcon3,
  SlideIcon4,
  SlideIcon5,
  SlideIcon6,
  SlideIcon7,
  PayPalIcon,
  PayPalIconFocus,
  CreditCardIcon,
  CreditCardIconFocus,
  Logo,
  RequireIcon,
  PromoteIcon,
  TooltipIcon,
  CloseVideoIcon,
  RePromoteIcon,
  StopPromoteIcon,
  IncreasePromoteIcon,
  SettingIcon,
  LogoutIcon,
  CloseIcon,
  DropdownIcon,
  UploadIcon,
  YoutubeIcon,
  FacebookIcon,
  PlusIcon,
  PromotedIcon,
  PendingIcon,
};
